<template>
  <div
    v-show="[0, 5].includes(status_type)"
    class="order-comment-reward"
  >
    <p
      class="order-comment-reward__text"
      v-html="rewardTips"
    ></p>
    <s-button
      v-tap="{
        id: '1-20-1-83'
      }"
      :type="['primary', 'H48PX']"
      class="order-comment-reward__btn"
      @click="toReview"
    >
      {{ language.SHEIN_KEY_PWA_15162 }}
    </s-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'
daEventCenter.addSubscriber({ modulecode: '1-20-1' })

export default {
  name: 'CommentReward',
  data() {
    return {
      analysisStatus: []
    }
  },
  computed: {
    ...mapState('orderList', ['language', 'status_type', 'reviewRewards']),
    rewardTips() {
      if (!this.reviewRewards) {
        return ''
      }
      return template(this.reviewRewards, this.language.SHEIN_KEY_PWA_26564)
    },
  },
  watch: {
    status_type() {
      this.exposeCommentReward()
    }
  },
  created () {
    this.exposeCommentReward()
  },
  methods: {
    toReview() {
      this.$router.push(`${gbCommonInfo.langPath}/user/review_center`)
    },
    exposeCommentReward() {
      if (
        this.rewardTips &&
        [0, 5].includes(this.status_type) &&
        !this.analysisStatus.includes(this.status_type)
      ) {
        this.analysisStatus.push(this.status_type)
        daEventCenter.triggerNotice({
          daId: '1-20-1-82',
          extraData: {
            tabType: this.status_type === 0 ? 1 : 2
          }
        })
      }
    }
  },
}
</script>

<style lang="less" scoped>
.order-comment-reward {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 1.1733rem;
  z-index: 1;
  padding: 20/75rem 24/75rem;
  text-align: left;
  background: @sui_color_welfare_bg;

  &__text {
    flex: auto;
    font-size: 12px;
    word-break: break-all;
    color: @sui_color_gray_dark1;
  }

  &__btn {
    flex-shrink: 0;
    margin-left: 24/75rem;
  }
}
</style>
