<template>
  <div>
    <div @click="jumpReturnRefundListEvt(order)">
      <div
        class="list-title list-title_new"
        :class="{ 'fsp-element': index < 3 }"
      >
        <em>
          <i :class="'order-status-' + returnStatusClass[order.returnStatus]"></i>
          <i class="status_text">{{ returnStatusText[order.returnStatus] }}</i>
        </em>
        <em
          v-if="!!order.combined_return_msg"
          class="combine_return_icon"
          @click.stop="showCombineReturnTips(order)"
        >
          {{ language.SHEIN_KEY_PWA_23067 }}
          <i class="suiiconfont sui_icon_doubt_16px_2"></i>
        </em>
      </div>
      <div
        class="list-title list-item-info"
        :class="{ 'fsp-element': index < 3 }"
      >
        <span>{{ language.SHEIN_KEY_PWA_16822 }} {{ order.billno }}</span>
      </div>
      <ReturnNotice
        :language="language"
        :order="order"
      />
      <!-- 巴西平台化迁移 -->
      <platFormItem
        :class="{ 'fsp-element': index < 3 }"
        :order="order"
        :language="language"
      />
    </div>
    <orderListApplication
      v-if="order.reverse_track_info"
      :order="order"
      :language="language"
    />
    <div
      class="text-capitalize list-bot-price-item-total"
      :class="{ 'fsp-element': index < 3 }"
    >
      <span class="list-bot-price-item">
        {{ order.orderGoodsSum }}
        {{ order.orderGoodsSum == 1 ? language.SHEIN_KEY_PWA_14952 : language.SHEIN_KEY_PWA_15456 }}
      </span>
      <span
        v-if="order.refundAmount"
        class="list-bot-price-total"
      >
        {{ language.SHEIN_KEY_PWA_15231 }}:
        <span class="price">
          {{ order.refundAmount.amountWithSymbol }}
        </span>
      </span>
    </div>
    <div class="return-btns">
      <ClientOnly>
        <div class="btn-wrapper">
          <template v-for="btn in (order.operation || [])">
            <div
              v-if="btn.show"
              :key="btn.Key"
              class="return-btn"
            >
              <div>
                <button
                  v-expose="{ id: btn.exposeId || ''}"
                  class="order-return-item-btn-black order-return-item-btn-s"
                  @click="btn.click"
                >
                  {{ btn.name }}
                </button>
              </div>
            </div>
          </template>
        </div>
        <div
          v-if="order.moreOperation && order.moreOperation.length > 0"
          class="my-reviewed-comment"
          @click="showMoreBtnClick(order)"
        >
          <div
            v-show="order.showMoreBtn"
            class="btn-more-list"
          >
            <p
              v-for="(item, childIndex) in order.moreOperation"
              :key="childIndex"
              v-expose="{ id: item.exposeId || ''}"
              class="btn-more"
              @click="item.click"
              v-html="item.name"
            ></p>
          </div>
          <a>
            <span 
              v-if="abtInfos.moreStyle === 'text' && publicLocalData.lang != 'euqs'"
              class="more-text"
            >
              {{ language.SHEIN_KEY_PWA_23768 }}
            </span>
            <template v-else>
              <span class="point"></span>
              <span class="point"></span>
              <span class="point"></span>
            </template>
          </a>
        </div>
      </ClientOnly>
    </div>
  </div>
</template>

<script>
// 订单列表-退货分组
import { mapState, mapGetters } from 'vuex'
import platFormItem from './platFormItem.vue'
import orderListApplication from './orderListApplication.vue'
import ReturnNotice from './ReturnNotice.vue'
import { htmlDecode } from '@shein/common-function'
import ClientOnly from 'vue-client-only'
import { isSupportDistributionCountrySer } from './service'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const { langPath } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

daEventCenter.addSubscriber({ modulecode: '1-20-1' })
export default {
  name: 'OrderReturnItem',
  components: {
    platFormItem,
    orderListApplication,
    ReturnNotice,
    ClientOnly
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      returnStatusClass: {
        0: 'blue',
        1: 'blue',
        2: 'green',
        3: 'grey',
        4: 'green'
      },
    }
  },
  computed: {
    ...mapState('orderList', ['language', 'abtInfos', 'publicLocalData']),
    ...mapGetters('orderList', ['returnStatusText'])
  },
  mounted() {
    this.getOperation()
  },
  methods: {
    htmlDecode,
    showCombineReturnTips(order = {}) {
      this.$emit('showCombinedReturnEvt', order)
    },
    showMoreBtnClick(order) {
      this.$set(order, 'showMoreBtn', !order.showMoreBtn)
    },
    getOperation () {
      if(typeof window == 'undefined') return
      // 看看屏幕可以展示几个按钮 
      const windowWidh = window.screen?.width > 540 ? 540 : window.screen?.width
      const canShow = Math.floor(windowWidh / 230)
      const btns = this.returnBtns(this.order)
      this.order.operation = btns.splice(0, canShow)
      this.order.moreOperation = btns
      this.$set(this.order, 'showMoreBtn', false)
    },
    isShowModifyOrder(order) {
      return !!order?.order_button?.modify_return_button_tip
    },
    isShowCancelOrder(order) {
      return !!order?.order_button?.cancel_return_button_tip
    },
    modifyHandle(order){
      daEventCenter.triggerNotice({
        daId: '1-20-1-158'
      })
      this.jumpReturnRefundListEvt(order)
    },
    cancelHandle(order){
      daEventCenter.triggerNotice({
        daId: '1-20-1-160'
      })
      this.jumpReturnRefundListEvt(order)
    },
    returnBtns (order) {
      const btns = [
        {
          key: 'modifyOrder',
          name: order?.order_button?.modify_return_button_tip,
          show: this.isShowModifyOrder(order),
          click: () => {
            this.modifyHandle(order)
          },
          exposeId: '1-20-1-159',
          priority: 2
        },
        {
          key: 'Return&RefundRecord',
          name: this.language.SHEIN_KEY_PWA_17013,
          show: order.recordForReturnRefund,
          click: () => {
            this.jumpReturnRefundListEvt(order)
          },
          exposeId: '',
          priority: 1
        },
        {
          key: 'cancelOrder',
          name: order?.order_button?.cancel_return_button_tip,
          show: this.isShowCancelOrder(order),
          click: () => {
            this.cancelHandle(order)
          },
          exposeId: '1-20-1-161',
          priority: 2
        },
        {
          key: 'urgeReturn',
          name: this.language.SHEIN_KEY_PWA_31578,
          show: order?.order_button?.urgent_pick_up_info?.urge_pick_up == 1,
          click: () => {
            this.$parent?.urgeReturnEvt(order)
          },
          exposeId: '1-20-1-161',
          priority: 2
        }
      ]
      return btns.filter(i => i.show).sort((a, b)=> a.priority - b.priority)
    },
    async jumpReturnRefundListEvt (order = {}) {
      let { billno, shippingaddr_info = {} } = order || {}
      let { shipping_country_name, shipping_country_id } = shippingaddr_info || {} 

      let resInfo = await isSupportDistributionCountrySer(billno)
      if (resInfo?.info?.changeSite) {
        return this.$parent.jumpMask({
          url: `${langPath}/user/order_return/return_refund_list/${billno}`,
          result: 0,
          shipping_country_name,
          shipping_country_id
        })
      }

      this.$router.push(`${langPath}/user/order_return/return_refund_list/${billno}`)
    }
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.order-return-item-btn-black {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214s
  background: #FFF;
  .border-dpr(border, 2px, transparent);
  border-color: #222222;
  padding: 0 0.4rem;
  color: #000;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.2s, border-color, 0.2s, color 0.2s;
  height: 1.16rem;
  line-height: 1.16rem;
  .font-dpr(32px);

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background: fade(@color_brand, 60%);
    color: rgba(255, 255, 255, 0.8);
    cursor: not-allowed;
  }

  a& {
    text-decoration: none;
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}
.order-return-item-btn-s {
  height: 0.8rem;
  line-height: 0.8rem;
  .font-dpr(24px);
}
.macc-order-list {
  background: #fff;
  margin-top: 16/75rem;
  .combined-order {
    color: #666;
    .padding-l(4px);
    i {
      .font-dpr(16px);
    }
  }
  &.order-list-new {
    .list-title {
      font-size: 14px;
      border: 0;
      padding: 0.32rem 0.32rem 0;
      em {
        position: relative;
        display: flex;
        align-items: center;
        .status_text {
          font-weight: bold;
          display: inline-block;
          color: #222;
          .font-dpr(28px);
          margin-right: 0;
        }
        .status-pending {
          .font-dpr(24px);
          color: @sui_color_link;
          // transform: scale(.83);
          display: inline-block;
          // .margin-l(-.3rem);
        }
      }
    }
    .list-title_new{
      display: flex;
      align-items: center;
    }
    .list-item-info {
      .font-dpr(24px);
      color: #666666;
      padding: 8/75rem 0 24/75rem 0;
      margin: 0 0.32rem;
      border-bottom: 1px solid #e5e5e5;
    }
    .list-bot-price-item-total {
      padding: 0.32rem;
      color: #666666;
      .font-dpr(24px);
      .txt-r();
      .list-bot-price-total {
        .price {
          font-weight: 600;
          color: #222222;
        }
      }
    }
    .list-bot {
      padding: 0 0.32rem 0.32rem;
      font-size: 0;
      .direction-r();
      .list-bot-price {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        .list-bot-price-item {
          color: #666;
          margin-right: 10/42.4rem;
        }
        .list-bot-price-total {
          color: #999;
          > span {
            font-size: 14px;
            color: #333;
          }
        }
      }
      .list-bot-btn > button:nth-child(1), .list-bot-btn > div:nth-child(1) button {
        line-height: 1.15;
        background: @color_brand;
        color: #ffffff;
      }
      .list-bot-btn {
        .txt-r();
        position: relative;
        .padding-l(0.8rem);
        button {
          line-height: 1.15;
          &:first-child[disabled] {
            background: #999;
            color: #bbb;
            border: none;
            & when (@IS_RW) {
              background: #fec5d4;
              color: #ffeded;
            }
          }
        }
        > button {
          .margin-l(0.32rem);
        }
        > div + button {
          .margin-l(0.32rem);
        }
        .order-return-item {
          margin-top: 0.2rem;
          display: inline-block;
          vertical-align: middle;
          a {
            height: 26/42.4rem;
            line-height: 26/42.4rem;
            padding: 0 8/42.4rem;
            text-decoration: none;
            display: block;
            color: #222;
          }
        }
        .S-popover__main-content {
          white-space: nowrap;
          padding: 0.093rem 0.13rem;
          background: @sui_color_welfare;
        }
        .S-popover__main-dark::before {
          background: @sui_color_welfare;
        }
      }
    }
    .list-con {
      padding: 0.32rem 0.32rem 0 0.32rem;
      border: none;
      > li {
        display: block;
        white-space: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
        overflow-y: hidden;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        .list-con-img {
          display: inline-block;
          width: 2rem;
          margin-right: 8/75rem;
          img {
            max-width: 100%;
            height: 198/75rem;
          }
        }
      }
      &.last-list-con {
        > li {
          border-bottom: none;
        }
      }
    }
  }
  .list-title {
    padding: 0.2rem 0.4rem;
    .border-dpr(border-bottom,2px,#e5e5e5);
    > span {
      user-select: text;
      .font-dpr(24px);
    }
    > em {
      display: block;
      > i {
        width: auto;
        height: auto;
        .padding-l(8/75rem);
        display: inline-block;
        border-radius: 100%;
        &.order-status-processing,
        &.order-status-returned,
        &.order-status-verified,
        &.order-status-refunded,
        &.order-status-shipped,
        &.order-status-paid,
        &.order-status-green {
          background: @sui_color_safety;
        }
        &.order-status-canceled,
        &.order-status-expired,
        &.order-status-revoked,
        &.order-status-red {
          background: @sui_color_unusual;
        }
        &.order-status-waitting,
        &.order-status-verifty,
        &.order-status-blue {
          background: @sui_color_link;
        }
        &.order-status-delivered,
        &.order-status-grey {
          background: @sui_color_gray_light2;
        }
        &:first-child {
          vertical-align: middle;
          padding: 0.08rem;
        }
      }
      &.combine_return_icon {
        display: inline-flex;
        align-items: center;
        color: #666;
        .margin-l(0.1rem);
        .font-dpr(24px);
        i {
          .font-dpr(24px);
        }
      }
    }
  }

  .list-bot {
    padding: 0.2rem 0.4rem;
    .align-center();

    .text-uppercase {
      .discout-price {
        display: block;
        color: #ff596e;
        white-space: nowrap;
        line-height: 1.5;
      }
    }
  }
  .list-con-more {
    text-align: center;
    padding: 0.2rem 0.4rem;
    .border-dpr(border-bottom,2px,#e5e5e5);
    > i {
      .margin-l(0.2rem);
      display: inline-block;
      &.up {
        transform: rotateX(180deg);
        transition: all 0.3s ease;
      }
    }
  }

  .my-reviewed-comment {
    position: relative;
    top: 0;
    .left(0);
    a {
      display: inline-flex;
      align-items: center;
      height: 0.8rem;
      text-decoration: none;
      .font-dpr(20px);
      column-gap: 8/75rem;
      .more-text {
        color: @sui_color_link;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }
      .point {
        width: 0.1rem;
        height: 0.1rem;
        background: @sui_color_link;
        border-radius: 0.07rem;
        border: 2px solid @sui_color_link;
        display: inline-block;
      }
    }
    .btn-more-list {
      position: absolute;
      background: #fff;
      padding: 0.1rem 0.3rem;
      color: #000;
      text-transform: capitalize;
      top: 0.8rem;
      .left(-0.12rem);
      white-space: nowrap;
      z-index: 10; /* stylelint-disable-line declaration-property-value-blacklist */
      .font-dpr(24px);
      border: 1px solid #e5e5e5;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
      .txt-l();
      &::after {
        content: ' ';
        display: block;
        width: 0.3rem;
        height: 0.3rem;
        border: 1px solid #e5e5e5;
        position: absolute;
        .left(0.25rem);
        top: -0.15rem;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        background: #fff;
        border-bottom: #fff;
        border-left: #fff;
      }
      .btn-more {
        padding: 0.1rem 0;
      }
      &.opacity-item {
        opacity: 0.3;
        filter: alpha(opacity=30);
      }
    }
  }

  .return-btn {
    padding-left: .32rem;
  }
  .return-btns {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 .32rem .32rem .32rem;
  }
  .btn-wrapper {
    display: flex;
  }
  .btn-wrapper div:last-child div{
    .order-return-item-btn-black {
      background: @color_brand;
      color: #fff;
    }
  }
}
</style>
