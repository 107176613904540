<template>
  <div class="order-list-new__container">
    <div @click="order.isReadOnly != 1 ? navToDetail($event, order) : ''">
      <div :class="['list-title', { 'fsp-element': index < 3 }]">
        <em>
          <i :class="'order-status-' + ordersStatus.class[order.orderStatus]"></i>
          <template v-if="order.payment_type == 2 && [0, 12].includes(order.orderStatus)">
            <i class="status_text">{{ language.SHEIN_KEY_PWA_17214 }}</i>
            <span
              class="status-pending"
              :DA-view-type="fromPage == 'order_list' ? 'ordinary' : ''"
              :DA-sa-name="fromPage == 'order_list' ? 'orderlist_alreadypaid' : ''"
              :DA-sa-param="JSON.stringify({ paymethod: order.payment_method })"
              @click.stop="openTips(order.payment_method, 1)"
            >
              {{ language.SHEIN_KEY_PWA_17213 }}
            </span>
          </template>
          <template v-else>
            <i class="status_text">
              <span class="order-status-text">{{ showOrderStatusText }}</span>
            </i>
            <template v-if="order.orderStatus == 16">
              <span
                :DA-view-type="fromPage == 'order_list' ? 'ordinary' : ''"
                :DA-sa-name="fromPage == 'order_list' ? 'orderlist_whypending' : ''"
                :DA-sa-param="JSON.stringify({ paymethod: order.payment_method })"
                class="status-pending"
                @click.stop="openTips(order.payment_method)"
              >
                {{ language.SHEIN_KEY_PWA_17212 }}
              </span>
            </template>
            <template v-else-if="showOrderStatusTip">
              <i
                class="iconfont icon-pro"
                @click.stop="showOverB2B2CCondEvt"
              ></i>
            </template>
          </template>
        </em>
        <span
          v-if="splitParcelTip(order)"
          class="split-package"
          @click.stop="showSplitParcelTip = true"
        >
          {{ language.SHEIN_KEY_PWA_16963 }} 
          <i class="iconfont icon-pro"></i>
        </span>
        <span
          v-if="combinedTip"
          v-tap="{ id: '1-20-1-32' }"
          v-expose="{ id: '1-20-1-30', data: { billno: order.billno } }"
          class="combined-order"
          @click.stop="showIsCombined(order)"
        >
          {{ language.SHEIN_KEY_PWA_14586 }}
          <i class="iconfont icon-pro question_icon"></i>
        </span>
        <span
          v-if="order.merge_buy_tag == 2"
          class="one_click_pay-tag"
        >
          {{ language.SHEIN_KEY_PWA_23937 }}
        </span>
        <span
          v-if="showSFSTip" 
          class="sfs-tip"
        >
          {{ language.SHEIN_KEY_PWA_33962 }}
        </span>
      </div>
      <div :class="['list-title', 'list-item-info', { 'fsp-element': index < 3 }]">
        <span class="order-title">{{ language.SHEIN_KEY_PWA_16822 }} {{ order.billno }}</span>
        <s-label
          v-if="order.remark_user == 'free trial order' || order.marketing_type == 1"
          class="free-trial-tag"
          type="promo"
        >
          {{ language.SHEIN_KEY_PWA_16212 && language.SHEIN_KEY_PWA_16212.toLowerCase() }}
        </s-label>
        <span
          v-if="order.marketing_type == 7"
          class="brithday-gift"
        >
          <img
            class="brithday-gift_tag"
            :src="`${publicCdn}/pwa_dist/images/sheinvip2/birthday_gift-9e9986fff2.png`"
          />
          {{ language.SHEIN_KEY_PWA_17349 }}
        </span>
        <span
          v-if="order.marketing_type == 8"
          class="free-gift_tag"
        >
          {{ language.SHEIN_KEY_PWA_23666 }}
        </span>
      </div>
      <!-- 推荐地址入口 -->
      <s-alert
        v-if="dispalyRecomAddress"
        type="info"
        show-icon
        icon-class="suiiconfont sui_icon_location_15px"
        jumpable
        class="address-recommend"
        @click.stop.native="controlDialogShow"
      >
        {{ language.SHEIN_KEY_PWA_25863 }}
      </s-alert>
      <!-- cod审单 -->
      <div
        v-if="isShowCODCheckNotice"
        class="alert-con cod-check-notice"
      >
        <s-alert
          v-expose="{ id: '1-20-1-69', data: { order_no: order.billno } }" 
          v-tap="{ id: '1-20-1-70', data: { order_no: order.billno } }"
          type="warning" 
          jumpable
          show-icon
          icon-class="suiiconfont sui_icon_news_24px"
          @click.stop.native="openCodRiskOrderEvt"
        >
          {{ isShowCODCheckNoticeText }}
        </s-alert>

        <!-- COD风控审单弹框 -->
        <ClientOnly>
          <CodRiskOrderPop
            :show="showCodRiskOrderPop"
            :language="language"
            :codRiskOrderText="codRiskOrderText"
            :codRiskPreOrderList="codRiskPreOrderList"
            :curOrder="order"
            :page-from="'order_list'"
            @closeCodRiskOrderPop="closeCodRiskOrderEvt"
          />
        </ClientOnly>
      </div>
      <!-- 履约异常文案 -->
      <div
        v-else-if="isSpecialOutgoingOrderEvt(order.billno)"
        class="alert-con"
      >
        <s-alert
          type="warning"
          show-icon
          icon-class="suiiconfont sui_icon-reported_20px"
        >
          {{ specialOutgoingOrderText(order.billno) }}
        </s-alert>
      </div>
      <!-- 海关 -->
      <div
        v-else-if="isabnormalOrderExist"
        class="alert-con custom-notice-alert"
        DA-view-type="ordinary"
        DA-sa-name="orderlist_abnormalpackage"
        :DA-sa-param="JSON.stringify({ orderid: order.billno })"
        @click.stop="jumpToCustomsInterceptionEvt(order.billno)"
      >
        <swiper-container
          ref="customNoticeSwiper"
          init="false"
          autoplay-delay="5000"
          pagination="true"
          :loop="customAbnormalList.length == 1 ? false : true"
        >
          <swiper-slide
            v-for="(item, noticeIndex) in customAbnormalList"
            :key="noticeIndex"
          >
            <s-alert
              type="warning"
              jumpable
              show-icon
              icon-class="suiiconfont sui_icon_notice_16px"
            >
              {{ item }}
            </s-alert>
          </swiper-slide>
        </swiper-container>
      </div>
      <!-- CSP异常公告 -->
      <div
        v-else-if="isShowCspNotice"
        id="list-csp-notice"
        v-expose="{
          id: '1-20-1-65',
          data: { billno: order.billno, num: cspNoticeContent.length }
        }"
      >
        <swiper-container
          init="false"
          autoplay-delay="5000"
          pagination="true"
          :loop="cspNoticeContent.length == 1 ? false : true"
          :class="['csp-notice-container', `csp-notice-${order.billno}`]"
        >
          <swiper-slide
            v-for="(item, noticeIndex) in cspNoticeContent"
            :key="noticeIndex"
          >
            <div
              class="list-csp csp-notice-slide"
            >
              <div class="tip-container">
                <div class="tip-content">
                  <i class="suiiconfont sui_icon_notice_16px notice-icon"></i>
                  <span class="tip-text">
                    {{ item.notice_content }}
                  </span>
                </div>
              </div>
              <i
                v-tap="{
                  id: '1-20-1-66',
                  data: { billno: order.billno, num: cspNoticeContent.length, type: 'pop_up' }
                }"
                class="suiiconfont sui_icon_more_right_16px more-icon"
                @click.stop="openNoticeModal"
              >
              </i>
            </div>
          </swiper-slide>
          <ClientOnly>
            <LazyMount mount-prop-name="show">
              <CspNoticeCommon
                :show="isShowCspDrawer"
                :page-from="'order_list'"
                :billno="order.billno"
                :language="language"
                :csp-notice-list="cspNoticeListNew"
                :package-goods-list="packageGoodsList"
                :da-click-id="'1-20-1-67'"
                :da-expose-id="'1-20-1-68'"
                @close="() => (isShowCspDrawer = false)"
              />
            </LazyMount>
          </ClientOnly>
        </swiper-container>
      </div>
      <div 
        v-else-if="checkOrderInfo.show && abtInfos.isOpenRiskInfoByAbt"
        class="alert-con"
      >
        <s-alert
          class="order-notice-tip"
          type="info"
          :show-icon="true"
          jumpable
          @click.stop.native="toVerification"
        >
          {{ htmlDecode(checkOrderInfo.text) }}
        </s-alert>
      </div>
      <!-- cod拒绝 -->
      <div
        v-else-if="!!isShowRejectedReason"
        v-expose="{ id: '1-20-1-37' }"
        class="alert-con"
      >
        <s-alert
          type="warning"
          jumpable
          :show-icon="isShowRejectedReason == 1"
          icon-class="suiiconfont sui_icon-reported_20px"
          :class="{'view-rejection-reason': isShowRejectedReason != 1}"
          @click.stop.native="toRejectReason"
        >
          {{ isShowRejectedReason == 1 ? language.SHEIN_KEY_PWA_18864 : language.SHEIN_KEY_PWA_31510 }}
        </s-alert>
      </div>
      <!-- 巴西平台化迁移 -->
      <platFormItem
        :class="{ 'fsp-element': index < 3 }"
        :order="order"
        :isOpenShopUrl="abtInfos.isOpenShopUrl"
        :isNewStoreIcon="abtInfos.isNewStoreIcon"
        :language="language"
        :orderStatusTextList="orderStatusTextList"
      />
    </div>
    <order-list-track
      v-if="(showTrackCard || showTransportTime) && ![5, 6].includes(order.order_type)"
      :order="order"
      :language="language"
      :show-track-card="showTrackCard"
      :show-transport-time="showTransportTime"
      :ab-test-config="abTrackTestConfig"
      :is-can-refund-abt="isCanRefundAbt"
      :order-status-text-list="orderStatusTextList"
    />
    <orderListApplication
      v-if="order.returnStatusInfo"
      :order="order"
      :language="language"
    />
    <div :class="['text-capitalize', 'list-bot-price-item-total', { 'fsp-element': index < 3 }]">
      <span class="list-bot-price-item">
        {{ order.orderGoodsSum }}
        <template v-if="order.orderGoodsSum == 1">
          {{ language.SHEIN_KEY_PWA_14952 }}
        </template>
        <template v-else>
          {{ language.SHEIN_KEY_PWA_15456 }}
        </template>
      </span>
      <span class="list-bot-price-total">
        {{ language.SHEIN_KEY_PWA_24019 }}:
        <span 
          class="price"
          :class="{
            'promotion-two': showRedPrice
          }"
        >
          {{ order.totalPrice ? order.totalPrice.amountWithSymbol : '' }}
        </span>
      </span>
      <pay-now-tag
        v-if="showPromotionTestTwo"
        :language="language"
        :pay-now-label-list="payNowLabelList"
      />
    </div>
    <ClientOnly>
      <LazyMount>
        <s-dialog
          append-to-body
          :visible.sync="showSplitParcelTip"
        >
          <template slot="title">
            <div>
              {{ language.SHEIN_KEY_PWA_16964 }}
            </div>
          </template>
          <template slot="footer">
            <s-button
              :type="['primary']"
              :width="'100%'"
              @click="showSplitParcelTip = false"
            >
              {{ language.SHEIN_KEY_PWA_15312 }}
            </s-button>
          </template>
        </s-dialog>
      </LazyMount>
      <LazyMount>
        <s-dialog
          append-to-body
          :visible.sync="showOverB2B2CCondDialog"
        >
          <template slot="title">
            <div class="cond-text">
              {{ showOverB2B2CCondText }}
            </div>
          </template>
          <template slot="footer">
            <s-button
              :type="['primary']"
              :width="'100%'"
              @click="showOverB2B2CCondDialog = false"
            >
              {{ language.SHEIN_KEY_PWA_15312 }}
            </s-button>
          </template>
        </s-dialog>
      </LazyMount>
      <CustomerSyncToUserAddress
        v-if="controlDialog"
        :controlDialog="controlDialog"
        :orderData="order"
        :language="language"
        @controlDialogClose="controlDialogClose"
        @recomAddressClose="recomAddressClose"
      />
    </ClientOnly>
  </div>
</template>

<script>
/* globals saObj */
import Vue from 'vue'
import { mapState } from 'vuex'
import orderLogic from 'public/src/pages/common/orderLogic'
import platFormItem from './platFormItem.vue'
import OrderListTrack from './OrderListTrack.vue'
import orderListApplication from './orderListApplication.vue'
import PayNowTag from './components/PayNowTag.vue'
import { tipList } from '../common/tipConfig'
import { Label, LazyMount } from '@shein/sui-mobile'
import { waitingForUpdateTips } from '../utils'
import schttp from 'public/src/services/schttp'
import { queryCodFrontOrderInfoFun } from 'public/src/pages/user/child_pages/orders/utils'
import CspNoticeCommon from 'public/src/pages/common/orderLogic/cspNoticeCommon.vue'
import CodRiskOrderPop from 'public/src/pages/common/orderLogic/codRiskOrderPop.vue'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import CustomerSyncToUserAddress from 'public/src/pages/components/customerSyncToUserAddress/index.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { register } from 'swiper/element'
import { Pagination, Autoplay } from 'swiper/modules'
import ClientOnly from 'vue-client-only'
import { htmlDecode } from '@shein/common-function'
import { markPoint } from 'public/src/services/mark/index.js'

typeof window !== 'undefined' && register()
;[Label].forEach(c => Vue.use(c))
daEventCenter.addSubscriber({ modulecode: '2-29' })

const { IS_RW, langPath } = gbCommonInfo
export default {
  name: 'ListItem',
  directives: { tap, expose },
  components: {
    platFormItem,
    OrderListTrack,
    CspNoticeCommon,
    CustomerSyncToUserAddress,
    CodRiskOrderPop,
    orderListApplication,
    PayNowTag,
    LazyMount,
    ClientOnly,
  },
  props: {
    ordersStatus: {
      type: Object,
      default: function () {
        return {}
      }
    },
    order: {
      type: Object,
      default: function () {
        return {}
      }
    },
    language: {
      type: Object,
      default: function () {
        return {}
      }
    },
    showMaskTips: {
      type: Function,
      default: function () {
        return () => {}
      }
    },
    fromPage: {
      type: String,
      default: 'order_list'
    },
    isCanRefundAbt: {
      type: String,
      default: ''
    },
    orderStatusTextList: {
      type: Array,
      default: () => {
        return []
      }
    },
    abTrackTestConfig: {
      type: Object,
      default: () => {}
    },
    payNowLabelList: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      controlDialog: false,
      showRecomAddress: true,
      showSplitParcelTip: false,
      showOverB2B2CCondText: '',
      showOverB2B2CCondDialog: false,
      isShowCspDrawer: false,
      cspNoticeListNew: {},
      packageGoodsList: [],
      touchDiff: 0,
      swiperInstance: null,
      showCodRiskOrderPop: false,
      codRiskOrderText: '',
      codRiskPreOrderList: []
    }
  },
  computed: {
    ...mapState('orderList', [
      'publicLocalData', 'isSsr', 'abnormalOrderList', 'abnormalOrderListType',
      'specialOutgoingOrderList', 'abtInfos', 'status_type'
    ]),
    publicCdn () {
      if (typeof window !== 'undefined') {
        return gbCommonInfo.PUBLIC_CDN
      }
      return this.publicLocalData?.PUBLIC_CDN || ''
    },
    showSFSTip () {
      let { SFSTipShow = {} } = this.abtInfos || {}
      let { shipping_country_id = '', stock_mode = '', business_model = '', sales_area = '', orderStatus = '' } = this.order || {}
      let abtOpenAbt = SFSTipShow?.param?.[`${shipping_country_id}_sfs`] == 'on'

      return abtOpenAbt && ![0, 12, 13].includes(+orderStatus) && business_model == 1 && stock_mode == 2 &&  sales_area == 1
    },
    showPromotionTestTwo() {
      if (!this.abtInfos?.unpaidOrderTestAbt || !Array.isArray(this.payNowLabelList)) return false
      return this.abtInfos.unpaidOrderTestAbt?.OrderlistPromotionTest == 'on2' && this.payNowLabelList.length
    },
    showRedPrice() {
      return this.showPromotionTestTwo && this.payNowLabelList?.[0]?.inducementType == '1'
    },
    dispalyRecomAddress() {
      const flag =
        this.abtInfos?.customerItemSyncToUserAddressAbt?.Order_List_Switch === 'on' &&
        this.order?.notify_user === 1 &&
        this.order?.address_id &&
        this.showRecomAddress
      if (flag) {
        daEventCenter.triggerNotice({
          daId: '2-29-1',
          extraData: {
            name: 'expose_customer_sync_to_address'
          }
        })
      }
      return flag
    },
    checkOrderInfo() {
      // orderStatus为1, 4状态时显示入口
      const riskinfo = this.order?.riskInfo || []
      let firstItem = riskinfo.find(item =>(item.sensitive_status === 'no_submit' && item.show_flag == 1))
      if(!firstItem) firstItem = riskinfo.find(item=>item.sensitive_status === 'review_fail' )
      const info = firstItem || riskinfo[0] || {}
      let { sensitive_status: sensitiveStatus, show_flag: showFlag, scene_type: sceneType } = info || {}
      // "no_submit": 未提交 reviewing: 审核中 review_fail: 审核失败
      let noSubmitCond = ['no_submit'].includes(sensitiveStatus) && showFlag == 1
      let reviewingCond = ['reviewing'].includes(sensitiveStatus)
      let reviewFailCond = ['review_fail'].includes(sensitiveStatus) && [3, 4]?.includes(+sceneType) // to do 待确认
      const handler = {
        sensitiveStatus,
        sceneType,
        show: false,
        text: '',
        info: {},
      }
      if (noSubmitCond || reviewingCond || reviewFailCond) {
        if (reviewingCond) {
          handler.text = this.language?.SHEIN_KEY_PWA_29140 || ''
        } else if (reviewFailCond) {
          handler.text = this.language?.SHEIN_KEY_PWA_29139 || ''
        } else {
          let textMap = {
            3: this.language.SHEIN_KEY_PWA_29137,
            other: this.language.SHEIN_KEY_PWA_29138,
          }
          handler.text = textMap[sceneType] || textMap['other'] || ''
        }
        handler.show = true
        daEventCenter.triggerNotice({
          daId: '1-19-1-60',
          extraData: {
            status: sensitiveStatus == 'no_submit' ? 0 : 1,
          }
        })
      }
      return handler
    },
    combinedTip () {
      if ([5, 7, 10, 18].indexOf(this.order.orderStatus) == -1) {
        return false
      } else {
        return this.order?.combined_shipped_flag == 1 || false
      }
    },
    isShowRejectedReason() {
      return orderLogic.getRejectedReason(this.abtInfos.isRejectedReasonOn, this.order)
    },
    showOrderStatusText() {
      let { orderStatus, order_detail_status = '' } = this.order || {}
      let { text = {} } = this.ordersStatus || {}
      let cccText = ''
      if (orderLogic.orderListGetCccTextCond(this.order, this.isCanRefundAbt)) {
        let matchObj = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
          ...this.order,
          order_or_package_status: order_detail_status
        })
        cccText = matchObj?.statusText
      }
      return cccText || text[orderStatus] || ''
    },
    showOrderStatusTip() {
      let { order_detail_status = '' } = this.order || {}
      let frontCond = orderLogic.orderListGetCccTextCond(this.order, this.isCanRefundAbt)
      let matchObj = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
        ...this.order,
        order_or_package_status: order_detail_status
      })
      return frontCond && matchObj
    },
    // 显示物流轨迹卡片
    showTrackCard() {
      return this.abTrackTestConfig?.['OrderListTrack'] == 'on' && !!this.order.track_info
    },
    // 显示运输时效
    showTransportTime() {
      const mallItem = this.order.mall_list?.find(item => !!item.max_transport_time_detail)
      const maxTransportTime = mallItem?.max_transport_time_detail
      return (
        this.abTrackTestConfig?.['OrderListTime'] == 'on' &&
        ![3, 5, 7, 8, 9, 11, 12, 13, 14, 16, 17, 15].includes(this.order.orderStatus) &&
        !!maxTransportTime && !!maxTransportTime.time
      )
    },
    cspNoticeContent() {
      return this.order?.cspNoticeContent?.slice?.(0, 3) || []
    },
    isShowCspNotice() {
      return (
        this.cspNoticeContent.length &&
        ![0, 12, 13, 16, 11, 9, 8, 3, 5, 14, 17].includes(this.order.orderStatus)
      )
    },
    isShowCODCheckNotice() {
      let { showCodRiskOrder } = orderLogic.checkCodRiskOrder({
        order: this.order || {},
        language: this.language || {},
        codRiskOrderAbt: this.abtInfos.codRiskOrderAbt
      })
      return showCodRiskOrder
    },
    isShowCODCheckNoticeText() {
      let { showCodRiskOrderText } = orderLogic.checkCodRiskOrder({
        order: this.order || {},
        language: this.language || {},
        codRiskOrderAbt: this.abtInfos.codRiskOrderAbt
      })
      return showCodRiskOrderText
    },
    isabnormalOrderExist() {
      const { billno = '' } = this.order
      // 旧回参billnoList:	string[],新回参confirmInfoList: object[],{billno,confirmStatus,displayStatus}
      if (this.abnormalOrderListType == 'old') return this.abnormalOrderList.includes(billno)
      let abnormalOrder =
        this.abnormalOrderList.filter(
          item => item?.displayStatus == 1 && [1, 2, 3, 4, 7, 8, 9, 10, 16].includes(item?.confirmStatus)
        ) || []
      let billNoList = abnormalOrder.map(item => item.billno)
      return billNoList.includes(billno)
    },
    customAbnormalList() {
      const { billno = '' } = this.order
      const uniqueStatusSet = new Set()
      let hasReturnAbnormal = false, // 逆向异常
          hasOrderAbnormal = false  // 正向异常

      for (const item of this.abnormalOrderList) {
        if (item?.displayStatus === 1 && item.billno === billno) {
          uniqueStatusSet.add(item.confirmStatus)
          if (!item.unionReturnNo) {
            hasOrderAbnormal = true
          }

          if (item.confirmStatus == 1) {
            // 待处理的退货异常，有退货单号
            if (item.unionReturnNo) {
              hasReturnAbnormal = true
            }
          }
        }
      }

      const uniqueStatusArrayLength = uniqueStatusSet.size

      if (uniqueStatusArrayLength > 0) {
        // 正向文案优先展示
        let result = []
        if (hasOrderAbnormal) {
          result.push(this.language.SHEIN_KEY_PWA_22142)
        }
        if (hasReturnAbnormal) {
          result.push(this.language.SHEIN_KEY_PWA_34583)
        }

        return result
      } else {
        return []
      }
    }
  },
  watch: {
    isShowCspNotice: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.initSwiper()
        }
      }
    }
  },
  mounted() {
    this.initCustomSwiper()
  },
  methods: {
    htmlDecode: text => htmlDecode({ text }),
    /** 跳转风控页面 */
    toVerification() {
      daEventCenter.triggerNotice({
        daId: '1-19-1-59',
        extraData: {
          status: this.checkOrderInfo?.sensitiveStatus == 'no_submit' ? '0' : '1'
        }
      })
      this.$router.push(`${ langPath }/user/verification?billNo=${ this.order.billno }&sceneType=${this.checkOrderInfo?.sceneType}&pageFrom=orderList`)
    },
    /** 地址推荐入口关闭 */
    recomAddressClose() {
      this.showRecomAddress = false
      this.controlDialog = false
    },
    /** 地址推荐弹窗展示 */
    controlDialogShow() {
      this.controlDialog = true
      daEventCenter.triggerNotice({
        daId: '2-29-1',
        extraData: {
          name: 'click_customer_sync_to_address'
        }
      })
    },

    /** 地址推荐弹窗关闭 */
    controlDialogClose() {
      this.controlDialog = false
    },
    isSpecialOutgoingOrderEvt(billno) {
      let billNoList = this.specialOutgoingOrderList.map(item => item.billNo)
      return billNoList.includes(billno)
    },
    // 履约异常文案
    specialOutgoingOrderText(billno) {
      let { orderType } = this.specialOutgoingOrderList.find(item => item.billNo == billno) || {}
      let languageKey = tipList[orderType] || tipList.default
      return this.language[languageKey]
    },
    toRejectReason() {
      daEventCenter.triggerNotice({
        daId: '1-20-1-38'
      })
      this.$router.push(`${langPath}/user/orders/rejectReason/${this.order.billno}`)
    },
    async showIsCombined(order = {}) {
      // 接口调用失败，展示合单状态为混发的文案
      const params = {
        billno: order.billno
      }
      let status = true
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/checkOrderMerge/get',
        data: params
      })
      if (res && res.code == 0) {
        // 0不混单,1是混单
        status = res.info?.is_mixture_shipping == 1
      }
      this.$emit('showCombinedStatus', {
        billno: order.billno,
        merge_buy_billno: order.merge_buy_billno,
        status: status
      })
    },
    async prefetchOrderDetailPageData ({ billno, isOldOrder }) {
      const res = await schttp({
        url: '/api/orders/base/orderDetail/query',
        params: {
          billno,
          withI18n: true,
          orderType: isOldOrder ? 'oldorder' : '',
          enterType: '',
        },
        schttp: {
          needLogin: true
        }
      })
      return res 
    },
    navToDetail(e, order) {
      let { billno, order_type, isOldOrder } = order
      if ($(e.target).hasClass('j-order-btn') || $(e.target).hasClass('j-return-btn')) return
      markPoint('toNextPageClick', 'public')
      saObj?.enterDetail({ orderId: billno, status_type: this.status_type })
      window.orderDetailPrefetch = this.prefetchOrderDetailPageData({ billno, isOldOrder })
      if (isOldOrder) {
        this.$router.push({
          path: `${langPath}/user/orders/detail/${billno}?orderType=oldorder${
            this.fromPage == 'deleted_order_list' ? '&fromRecycle=recycleOrderList' : ''
          }`
        })
      } else {
        // 超省卡单独购及随单购已支付拆单|者付费会员
        this.$router.push({
          path: `${langPath}/user/orders/${
            [5, 6]?.includes(+order_type) ? 'virtualDetail' : 'detail'
          }/${billno}${
            this.fromPage == 'deleted_order_list' ? '?fromRecycle=recycleOrderList' : ''
          }`
        })
      }
    },
    openTips: function (type, info) {
      if (this.fromPage == 'order_list') {
        if (info) {
          sa(
            'send',
            $.extend(SaPageInfo, {
              activity_name: 'click_orderlist_alreadypaid',
              activity_param: {
                paymethod: type
              }
            })
          )
        } else {
          sa(
            'send',
            $.extend(SaPageInfo, {
              activity_name: 'click_orderlist_whypending',
              activity_param: {
                paymethod: type
              }
            })
          )
        }
      }

      this.showMaskTips(waitingForUpdateTips(this.language, type, IS_RW))
    },
    showOverB2B2CCondEvt() {
      let { order_detail_status } = this.order || {}
      let text = ''
      if (orderLogic.orderListGetCccTextCond(this.order, this.isCanRefundAbt)) {
        let matchObj = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
          ...this.order,
          order_or_package_status: order_detail_status
        })
        text = matchObj?.virtualTraceText
      }
      this.showOverB2B2CCondText = text || ''
      this.showOverB2B2CCondDialog = true
    },
    jumpToCustomsInterceptionEvt: function (billno) {
      sa(
        'send',
        {
          activity_name: 'click_orderlist_abnormalpackage',
          activity_param: {
            orderid: billno
          }
        },
        { beacon: 1 }
      )
      const onlyReturn = this.customAbnormalList.length == 1 && this.customAbnormalList[0] == this.language.SHEIN_KEY_PWA_34583
      this.$router.push({
        path: `${langPath}/user/orders/customsInterception/${billno}`,
        query: {
          source: 'orderlist',
          customType: onlyReturn ? 2 : 1
        }
      })
    },
    splitParcelTip: function (order) {
      if ([10, 18].indexOf(order.orderStatus) == -1 || this.combinedTip) {
        return false
      }
      const splitParcel = {}
      order.orderGoodsList.forEach(item => {
        if (item.reference_number) {
          splitParcel[item.reference_number] = item.reference_number
        }
      })
      return Object.keys(splitParcel).length > 1 ? true : false
    },
    async openNoticeModal() {
      this.isShowCspDrawer = true
      const conditionList = [
        {
          is_history_notice: [1]
        },
        {
          is_history_notice: [0],
          notice_status_list: [1]
        }
      ]
      this.isShowCspDrawer = true
      if (!Object.keys(this.cspNoticeListNew).length || !this.packageGoodsList.length) {
        const [noticeList] = await Promise.all([
          this.fetchAbnormalNotice(conditionList),
          this.fetchTrack()
        ])
        this.cspNoticeListNew = noticeList
      }
    },
    initSwiper() {
      this.$nextTick(() => {
        const swiperEl = document.querySelector(`.csp-notice-${this.order.billno}`)
        const params = {
          modules: [Pagination, Autoplay],
          injectStylesUrls: [
            this.publicCdn + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'
          ],
          injectStyles: [`
            .swiper-pagination-bullet {
              width: 6px;
              height: 6px;
              opacity: 1;
              background: #cccccc;
              vertical-align: middle;
              display: inline-block;
              border-radius: 6px;
              margin: 0 2px !important;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullet-active {
              width: 6px;
              height: 6px;
              background: #222;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullets {
              line-height: 1;
              bottom: 5px !important;
            }
          `],
          on: {
            touchEnd: (swiper) => {
              if (swiper?.touches?.diff != this.touchDiff) {
                daEventCenter.triggerNotice({
                  daId: '1-20-1-66',
                  extraData: {
                    billno: this.order.billno,
                    num: this.cspNoticeContent.length,
                    type: 'roll'
                  }
                })
              }
              this.touchDiff = swiper?.touches?.diff
            }
          }
        }

        if (swiperEl) {
          Object.assign(swiperEl, params)
          swiperEl.initialize()
        }
      })
    },
    initCustomSwiper() {
      this.$nextTick(() => {
        const swiperEl = this.$refs.customNoticeSwiper
        const params = {
          modules: [Pagination, Autoplay],
          injectStylesUrls: [
            this.publicCdn + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'
          ],
          injectStyles: [`
            .swiper-pagination-bullet {
              width: 4px;
              height: 4px;
              opacity: 1;
              background: #cccccc;
              vertical-align: middle;
              display: inline-block;
              border-radius: 10px;
              margin: 0 2px !important;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullet-active {
              width: 4px;
              height: 4px;
              background: #222;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullets {
              line-height: 1;
              bottom: 4px !important;
            }
          `],
        }

        if (swiperEl) {
          Object.assign(swiperEl, params)
          swiperEl.initialize()
        }
      })
    },
    async fetchAbnormalNotice(conditionList = [{ notice_status_list: [1] }]) {
      let data = await schttp({
        url: '/api/orders/track/abnormalNotice/query',
        method: 'POST',
        data: {
          bill_no_list: [this.order.billno],
          page_code_list: ['track', 'order_list', 'order_detail'],
          condition_list: conditionList
        }
      })
      if (data.code == '0') {
        return data?.info?.notice_list[0]
      }
    },
    async fetchTrack() {
      let data = await schttp({
        url: '/api/orders/track/orderTrack/get',
        method: 'GET',
        params: {
          billno: this.order.billno
        }
      })
      if (data?.orderInfo) {
        // 剔除包裹号为空的
        this.packageGoodsList = data.orderInfo?.packageGoodsList || []
      } else {
        return
      }
    },
    async openCodRiskOrderEvt() {
      let res = await queryCodFrontOrderInfoFun(this.order?.billno)
      if (res?.code == '0') {
        this.codRiskOrderText = res?.info?.risk_desc || ''
        this.codRiskPreOrderList = JSON.parse(JSON.stringify(res?.info?.preorder_list || []))
        this.showCodRiskOrderPop = true

        daEventCenter.triggerNotice({
          daId: '1-20-1-71',
          extraData: {
            order_no: this.order?.billno || ''
          }
        })
      } else {
        this.$toast(this.language?.SHEIN_KEY_PWA_25888)
      }
    },
    closeCodRiskOrderEvt() {
      this.showCodRiskOrderPop = false
    }
  }
}
</script>

<style lang="less">
.custom-notice-alert {
  .S-alert__icon {
    color: #767676;
  }
}
</style>
<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.order-list-new__container {
  .address-recommend {
    margin: 0.32rem 0.32rem 0 0.32rem;
  }
  .alert-con {
    margin-top: 0.32rem;
    padding: 0 24/75rem;
    margin-top: 24/75rem;
    /deep/ [class*='sui_icon-reported'] {
      .font-dpr(28px);
      color: #bf4123;
    }

    /deep/ .S-alert {
      padding: 20/75rem 0 20/75rem 20/75rem;
    }

    /deep/ .S-alert__jumpbtn {
      font-size: 16px;
      margin-left: 8/75rem;
      margin-right: 20/75rem;
      color: #959595;
    }
    &.cod-check-notice {
      /deep/ .S-alert__icon {
        .font-dpr(28px);
      }
    }
  }

  .order-title {
    color: @sui_color_gray_dark2;
  }
  .one_click_pay-tag {
    color: #198055;
    background: #f5fcfb;
    padding: 0 8/75rem;
    .margin-l(8/75rem);
  }
  .sfs-tip{
    color: #198055;
    background: #ECFCF3;
    padding: 0 8/75rem;
    .margin-l(8/75rem);
  }

  /deep/ .view-rejection-reason {
    background-color: @sui_color_white;
    border: 1px solid @sui_color_gray_weak1;

    .S-alert__description {
      color: @sui_color_gray_dark3;
    }
  }
}
#list-csp-notice {
  height: 112/75rem;
  background: @sui_color_prompt_warning;
  margin: 0.32rem 0.32rem 0 .32rem;
  display: block;
  overflow: hidden;
}
.tip-container {
  .tip-content {
    display: flex;
    align-items: center;
  }
  .notice-icon {
    margin-right: 16/75rem;
    color: #767676;
  }
  .tip-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.list-csp {
  font-size: 24/75rem;
  padding: 24/75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .more-icon {
    color: #959595;
    margin-left: 24/75rem;
  }
}
.cond-text {
  word-break: break-word;
}
.order-status-text {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
}
.order-list-new__container {
  .custom-notice-alert {
    .S-alert {
      padding: 24/75rem;
      min-height: 120/75rem;
    }
  }
}
</style>
