/* globals gb_select_country_enabled */
import creator from 'public/src/pages/components/address/index_v2.js'
import storeAddressVue from 'public/src/pages/components/address/store_index.vue'
import { analysis } from '../detail/analysis/ga'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import orderLogic from 'public/src/pages/common/orderLogic'
import { htmlDecode, isFunction } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { getBffDataBySearchKeyword } from 'public/src/pages/user/child_pages/orders_bff/list/getBaseData'
import { getErrorHandleTipText, getModifyAddressInfoEvt } from 'public/src/pages/user/child_pages/orders_bff/common/orderAddressNewTools'
let { langPath } = typeof window != 'undefined' ? gbCommonInfo : {}

export default {
  data() {
    return {
      orderAddress: {
        type: 1, // 1:成功 2:失败
        show: false,
        text: ''
      },
      addressShow: false,
      editAddressType: 'shipping',
      disabledAddress: ['country'],
      extraAddress: ['isDefault', 'type', 'certificateTw'],
      storeCache: {},
      countryList: [],
      item_cates: [],
      isCodAddressEditEnable: true,
      batchModifyAddressData: {},
      batchModifyAddressParams: {},
      showEditAddressConfirmDialog: false,
      batchAddressMessage: '', // 地址编辑成功弹窗文案
      showBatchAddressMessage: false, // 显示地址编辑成功弹窗
      addressEditFlag: false, // 地址编辑标识
      editSuccessFlag: false, // 地址编辑成功标识
    }
  },
  components: {
    addressVue: creator,
    storeAddressVue
  },
  async mounted() {
    await this.getCountryListInfo()
  },
  computed: {
    isNewEditAddressFlag () {
      let { shipped_status_info = {}, shippingaddr_info = {} } = this.orderInfo || {}

      return shipped_status_info?.new_edit_address == 1 && shippingaddr_info.isOrderShippingAddressEditable == 1
    },
    isOldEditAddressFlag () {
      let { shippingaddr_info = {}, payment_method } = this.orderInfo || {}
      let { orderListAbtInfo = [], isCodAddressEditEnable } = this.abtInfos || {}
      let showAbtValue = payment_method == 'cod' ? isCodAddressEditEnable : !orderListAbtInfo?.includes('address=off')

      return shippingaddr_info.isOrderShippingAddressEditable == 1 && showAbtValue
    }
  },
  methods: {
    htmlDecode: text => htmlDecode({ text }),
    // 地址编辑成功弹窗确认
    closeBatchAddressDialog() {
      if (this.addressEditFlag) {
        this.confirmDialogHandle()
      }
    },
    clickEditAddress () {
      var self = this
      var info = this.orderInfo
      var now_address = {
        fname: info.shipping_firstname,
        lname: info.shipping_lastname,
        fatherName: info.shipping_father_name,
        englishName: info.subsidiary.english_name,
        countryId: info.shipping_country_id,
        state: info.shipping_province,
        city: info.shipping_city,
        district: info.shipping_district,
        street: info.shipping_street,
        address1: info.shipping_address_1,
        address2: info.shipping_address_2,
        postcode: info.shipping_postcode,
        taxNumber: info.tax_number,
        nationalId: info.nationalId,
        tel: info.shipping_telephone,
        new_tel: info.shipping_telephone,
        standbyTel: info.backupShippingTelephone,
        birthday: info.birthday,
        isDefault: '',
        type: info.type,
        middleName: info.subsidiary.middle_name,
        stateId: info.shipping_state_id,
        cityId: info.shipping_city_id,
        districtId: info.shipping_district_id,
        passportNumber: info.subsidiary.passport_number,
        passportIssuePlace: info.subsidiary.issue_place,
        passportIssueDate: info.subsidiary.issue_date,
        lat: info.shipping_lat,
        lng: info.shipping_lng,
        calendar_type: info.calendar_type,
        id_expire_date: info.id_expire_date
      }
      self.editAddressType = 'shipping'
      self.extraAddress =
        info.shipping_country_id == 209
          ? ['isDefault', 'certificateTw']
          : ['isDefault', 'type', 'certificateTw']
      if (
        self.orderInfo?.payment_method === 'cod' &&
        [6, 4].includes(+self.orderInfo?.orderStatus)
      ) {
        self.disabledAddress = [...self.disabledAddress, 'tel', 'standbyTel']
      }
      if (this.isNewEditAddressFlag || this.isOldEditAddressFlag) {
        let { subsidiary, orderStatus, country_id } = info || {}
        let { transport_type } = subsidiary || {}
        let disabledAddressArr = this.getDisabledConfig({ country_id, transport_type, orderStatus })
        self.disabledAddress = [...new Set([...self.disabledAddress, ...disabledAddressArr])]
      }

      const needAssociatedAddress =
        self.disabledAddress.length &&
        self.disabledAddress.some(item => !['country', 'tel', 'standbyTel'].includes(item))
      if (needAssociatedAddress) {
        self.extraAddress.push('associated_address')
      }
      if (TRANSPORT_TYPE_SHOP.includes(info.subsidiary.transport_type)) {
        now_address.storeType = info.subsidiary.store_type
        now_address.storeId = info.subsidiary.store_id
        now_address.storeName = info.subsidiary.store_name
        now_address.editAddressFlag = this.isNewEditAddressFlag || this.isOldEditAddressFlag
        const extraData = orderLogic.getStoreAddressExtraData({ order: this.orderInfo }) || {}
        this.$nextTick(() => {
          const pageSourceLists = {
            page_order_detail: 'orderDetails',
            page_order_list: 'orderlist',
            page_cancel_order: 'refund'
          }
          this.$refs.storeAddress.updateUI({
            type: 'edit',
            addrData: now_address,
            url: `/api/checkout/editShipping/update?billno=${this.orderInfo.billno}`,
            ajaxType: 'post',
            countryId: now_address.countryId,
            extra: ['certificateTw'],
            disabled: self.disabledAddress,
            pageName: '订单详情页',
            extraParams: this.batchModifyAddressParams,
            extraData: {
              ...extraData,
              billno: this.orderInfo.billno,
              pageSource: pageSourceLists?.[SaPageInfo?.page_name] || 'orderDetails',
              riskData: {
                order_no: this.orderInfo?.billno,
                is_paid: [0, 12, 13].includes(+this.orderInfo?.orderStatus) ? false : true,
                modify_type: this.batchModifyAddressParams?.modify_type,
                cross_type: this.batchModifyAddressParams?.cross_type,
                pageType: this.moduleName === 'orderList' ? 'orderlist' : 'orderDetails'
              }
            }
          })
        })
      } else if (this.$refs.addressVue) {
        analysis.clickAdressEdit()
        this.addressShow = true
        this.now_address = now_address
        this.$nextTick(() => {
          this.$refs.addressVue.updateUI('edit', {
            ...(now_address || {}),
            otherData: {
              riskData: {
                order_no: this.orderInfo?.billno,
                is_paid: [0, 12, 13].includes(+this.orderInfo?.orderStatus) ? false : true,
                modify_type: this.batchModifyAddressParams?.modify_type,
                cross_type: this.batchModifyAddressParams?.cross_type
              }
            }
          })
          this.sendAddressAnalysis(
            {
              page_type: 'edit',
              activity_from: 'checkout_active'
            },
            { type: 'expose' }
          )
        })
      }
      if (typeof window !== 'undefined' && typeof gb_select_country_enabled !== 'undefined') {
        window.gb_select_country_enabled = false // 国家不可更改
      }
    },
    getDisabledConfig({ country_id, orderStatus, transport_type }) {
      let orderStatusCond = [10].includes(+orderStatus)
      // 均不为processing || out_of_stock状态
      let processOrStockPackage = this.orderInfo?.subOrderStatus?.filter(item =>
        ['processing', 'out_of_stock'].includes(item.packageState)
      )
      let unShipCanDelivery = orderLogic.unShipCanDelivery(
        this.orderInfo,
        this.isInfoFrontAbt,
        processOrStockPackage
      )
      let shipCanDelivery = orderLogic.shipCanDelivery(
        this.orderInfo,
        this.isCanRefundAbt,
        processOrStockPackage
      )
      // 未发货包裹条件
      let unShipCond = unShipCanDelivery && shipCanDelivery
      // 可以修改地址条件1
      let unShipPackageCond =
        orderStatusCond && !processOrStockPackage?.length && (this.isNewEditAddressFlag || this.isOldEditAddressFlag)
      // 可以修改地址条件2
      let shippedPackageCond =
        processOrStockPackage?.length && !unShipCond && (this.isNewEditAddressFlag || this.isOldEditAddressFlag)
      if (unShipPackageCond || shippedPackageCond) {
        let disabledAddressList = this.updateShippedAddress || {}
        if (!TRANSPORT_TYPE_SHOP.includes(transport_type)) {
          let shippedAddressArr = disabledAddressList['other'][country_id]
          if (shippedAddressArr) {
            let shippedAddressList = this.isObjectEvt(shippedAddressArr)
              ? shippedAddressArr[transport_type]
              : shippedAddressArr
            return shippedAddressList || []
          } else {
            return this.disabledAddress
          }
        } else {
          return disabledAddressList['shop_transit'] || []
        }
      } else {
        return this.disabledAddress
      }
    },
    clickEditBillAddress() {
      if (!this.$refs.addressVue) {
        return
      }
      this.editAddressType = 'billing'
      this.disabledAddress = ['country']
      this.extraAddress = [
        'street',
        'nationalId',
        'fatherName',
        'englishName',
        'district',
        'middleName',
        'standbyTel',
        'taxNumber',
        'type',
        'isDefault',
        'certificateTw',
        'certificatePe'
      ]
      const address = {
        fname: this.orderInfo.firstname,
        lname: this.orderInfo.lastname,
        countryName: this.orderInfo.country_name,
        countryId: this.orderInfo.country_id,
        state: this.orderInfo.province,
        street: this.orderInfo.street,
        city: this.orderInfo.city,
        district: this.orderInfo.district,
        address1: this.orderInfo.address_1,
        address2: this.orderInfo.address_2,
        tel: this.orderInfo.telephone,
        postcode: this.orderInfo.postcode,
        storeType: (this.orderInfo.subsidiary && this.orderInfo.subsidiary.store_type) || ''
      }
      this.addressShow = true
      this.$nextTick(() => {
        this.$refs.addressVue.updateUI('edit', address)
      })
      analysis.clickEditBillingAddress(this.orderInfo.billno)
    },
    addressEdit(data) {
      if (this.editAddressType == 'shipping') {
        this.newAddressShippingEdit(data)
      } else if (this.editAddressType == 'billing') {
        this.addressBillingEdit(data)
      }
    },
    async addressShippingEditCommonEvt (data = {}, canUpdateFlag) {
      let { isPaid, billno } = this.orderInfo || {}
      let url = `/api/checkout/editShipping/update?billno=${billno}`
      let obj = {
        is_paid: isPaid ? 1 : 0,
        ...data,
        ...this.batchModifyAddressParams
      }

      // 判断地址是否有改动 比较一些比较常用的字段
      if (canUpdateFlag && !orderLogic.hasDiffParamsValue(this.now_address, data)) {
        this.addressShow = false
        return {
          isContinue: false
        }
      }

      // [start] 增加一个修改地址字段的打点
      getModifyAddressInfoEvt({
        oldAddressInfo: this.now_address,
        newAddressInfo: data,
        disabledAddressInfo: this.disabledAddress,
        options: {
          billno: billno,
          scene: this.moduleName?.toUpperCase(),
          tag: `PWA_${this.moduleName?.toUpperCase()}`,
          message: `pwa_${this.moduleName}_modify_disabled_address`
        }
      })
      // [end] 增加一个修改地址字段的打点

      this.$refs.addressVue.loading(true)
      let res = await schttp({
        method: 'POST',
        url: url,
        data: obj
      })
      this.$refs.addressVue.loading(false)
      return {
        isContinue: true,
        obj,
        res
      }
    },
    async newAddressShippingEdit (data) {
      let { isContinue, res } = await this.addressShippingEditCommonEvt(data, this.isNewEditAddressFlag)
      if (!isContinue) {
        return
      }

      analysis.editAddress(res?.code, res?.msg, data?.tag, this.orderInfo?.payment_method === 'cod')
      daEventCenter.triggerNotice({
        daId: this.moduleName === 'orderList' ? '1-20-1-1' : '1-19-1-1',
        extraData: {
          address_type: typeof data.tag !== 'undefined' ? data.tag : '',
          is_success: res.code == '0'
        }
      })

      if (res.code == '0') {
        let addressDiff = orderLogic.isDiffShippToEdit(this.now_address, data)
        let flag = this.orderInfo?.addressSyncInfo?.enableAddressSyncPopup == 1 && addressDiff
        this.addressShow = false
        this.addressEditFlag = flag
        this.editSuccessFlag = true

        let successText = this.orderInfo?.shipped_status_info?.edit_success_tip
        if (successText) {
          this.batchAddressMessage = successText
          this.showBatchAddressMessage = true
        } else {
          flag && this.confirmDialogHandle()
        }
        let res = await getBffDataBySearchKeyword({ page: 1, keyword: this.orderInfo.billno })
        if (res?.order_list?.length) {
          this.updateSomeOrderInfo({ orderInfo: {
            ...(this.orderInfo || {}),
            shipped_status_info: res?.order_list?.[0]?.shipped_status_info || {},
            shippingaddr_info: res?.order_list?.[0]?.shippingaddr_info || {}
          } })
        }
      } else {
        if (res.code == 401970) {
          this.$refs.addressVue.check.blacklist('postcode', 4)
        } else if (res.code == 401959) {
          this.$refs.addressVue.check.blacklist('state')
        } else if (res.code == 401960) {
          this.$refs.addressVue.check.blacklist('city')
        } else if (res.code == 300399) {
          this.$toast(this.language.SHEIN_KEY_PWA_14909)
        } else if (res.code == 301107) {
          this.$toast(data.is_paid ? this.language.SHEIN_KEY_PWA_16240 : this.language.SHEIN_KEY_PWA_16239)
          analysis.popUpsCheckAddress()
        } else if (res.code == 666666 || res.code == 777777) {
          this.$toast(this.language.SHEIN_KEY_PWA_17115)
        } else if (res.code == 300510) {
          this.$refs.addressVue.check.blacklist('city', 7)
        } else if ([401971, 401986, 401984, 401996, 402000, 401997, 401998, 401999, 402002, 402003, 402004, 402005].includes(Number(res.code))) {
          this.$refs.addressVue.check?.addressCheckError?.({ code: Number(res.code) })
        } else {
          let editAddressNewConfigOptions = this.editAddressNewConfigOptionsEvt(this.orderInfo)
          let errorText = getErrorHandleTipText(editAddressNewConfigOptions, this.language) || res.tips || this.language?.SHEIN_KEY_PWA_16088
          this.addressEditFlag = false
          this.editSuccessFlag = false
          this.batchAddressMessage = errorText
          this.showBatchAddressMessage = true
        }
      }
    },
    showEditAddressDialogEvt (argParams = {}) {
      console.log('showEditAddressDialogEvt', argParams)
      let {
        isShop,
        addressInfo,
        orderInfo,
        options
      } = argParams || {}
      this.editAddressType = 'shipping'
      this.batchModifyAddressParams = options.extraParams || {}
      if (this.moduleName === 'orderList') {
        this.orderInfo = orderInfo
      } else if (isFunction(this.assignState)) {
        this.assignState({ orderInfo })
      }
      if (isShop) {
        options = {
          ...options,
          pageName: '订单详情页',
          extraData: {
            ...options.extraData,
            pageSource: 'orderDetails',
            riskData: {
              order_no: this.orderInfo?.billno,
              is_paid: [0, 12, 13].includes(+this.orderInfo?.orderStatus) ? false : true,
              modify_type: this.batchModifyAddressParams?.modify_type,
              cross_type: this.batchModifyAddressParams?.cross_type,
              pageType: this.moduleName === 'orderList' ? 'orderlist' : 'orderDetails'
            }
          }
        }
        this.$nextTick(() => {
          this.$refs.storeAddress.updateUI(options)
        })
      } else if (!isShop && this.$refs.addressVue) {
        let { disabled = [], extra = [] } = options || {}
        this.addressShow = true
        this.disabledAddress = disabled
        this.extraAddress = extra
        this.now_address = addressInfo

        this.$nextTick(() => {
          this.$refs.addressVue.updateUI('edit', {
            ...(addressInfo || {}),
            otherData: {
              riskData: {
                order_no: this.orderInfo?.billno,
                is_paid: [0, 12, 13].includes(+this.orderInfo?.orderStatus) ? false : true,
                modify_type: this.batchModifyAddressParams?.modify_type,
                cross_type: this.batchModifyAddressParams?.cross_type
              }
            }
          })
        })
      }
    },
    sendAddressAnalysis(extraData = {}, { type = 'save' } = {}) {
      if (this.moduleName !== 'orderDetail') {
        return
      }
      const isCod = this.orderInfo?.payment_method === 'cod'
      daEventCenter.triggerNotice({
        daId: type === 'save' ? '1-19-1-78' : '1-19-1-79',
        extraData: {
          ...extraData,
          address_type: 1,
          order_type: isCod ? 1 : 2
        }
      })
    },
    // 确认批量修改地址回调
    onConfirmModifyAddress(crossType) {
      this.batchModifyAddressParams = {
        cross_type: crossType,
        modify_type: this.batchModifyAddressData.modify_type
      }
      this.clickEditAddress()
    },
    // @todo BFF账单地址后续调整
    addressBillingEdit(data) {
      analysis.clickSaveBillingAddress(this.orderInfo.billno)
      this.$refs.addressVue.loading(true)
      var orderInfo = {
        firstname: data.fname,
        lastname: data.lname,
        address_1: data.address1,
        address_2: data.address2,
        city: data.city,
        // street: data.street,
        country_id: data.country_id,
        country_name: data.country_name,
        postcode: data.postcode,
        province: data.state,
        telephone: data.tel
      }
      Object.assign(orderInfo, this.batchModifyAddressParams)
      schttp({
        method: 'POST',
        url: `/api/checkout/updateOrder/update?billno=${this.orderInfo.billno}`,
        data: orderInfo
      }).then(res => {
        this.$refs.addressVue.loading(false)
        if (res.code == 0) {
          // this.matchCompName 部分退组件第二步的判断 - public/src/pages/user/child_pages/orders/common/cancelItem
          if (
            SaPageInfo?.page_name == 'page_cancel_order' ||
            this.matchCompName === 'refund-item'
          ) {
            // 退款修改地址提示
            this.$toast(this.language.SHEIN_KEY_PWA_18403)
            setTimeout(() => {
              this.toJumpPage()
            }, 1500)
            return
          }

          this.orderInfo.firstname = res.info.order.firstname
          this.orderInfo.lastname = res.info.order.lastname
          this.orderInfo.province = res.info.order.province
          // this.orderInfo.street = res.info.order.street
          this.orderInfo.city = res.info.order.city
          this.orderInfo.address_1 = res.info.order.address_1
          this.orderInfo.address_2 = res.info.order.address_2
          this.orderInfo.telephone = res.info.order.telephone
          this.orderInfo.postcode = res.info.order.postcode
          this.addressShow = false
        } else if (res.code == 666666 || res.code == 777777) {
          this.$toast(this.language.SHEIN_KEY_PWA_17115)
        } else if (res.code == 300510) {
          this.$refs.addressVue.check.blacklist('city', 7)
        } else {
          this.addressEditFlag = false
          this.editSuccessFlag = false
          this.batchAddressMessage = res.tips
          this.showBatchAddressMessage = true
          // this.$refs.addressVue.universalToast(res.tips)
        }
        const addressType = res.info.order.tag
        daEventCenter.triggerNotice({
          daId: this.moduleName === 'orderList' ? '1-20-1-1' : '1-19-1-1',
          extraData: {
            address_type: typeof addressType !== 'undefined' ? addressType : '',
            is_success: res.code == 0
          }
        })
        analysis.saveBillingAddress(res.code, res.msg, this.orderInfo.billno)
      })
    },
    updateError() {
      if (this.editAddressType == 'shipping') {
        const isCod = this.orderInfo?.payment_method === 'cod'
        analysis.editAddress(2, 'Address check fail', 0, isCod)
      } else if (this.editAddressType == 'billing') {
        analysis.saveBillingAddress(2, 'Address check fail', this.orderInfo.billno)
      }
    },
    addressCancel() {
      this.addressShow = false
    },
    async newSaveStoreAddress (res) {
      if (res?.code == '0') {
        let formData = res?.formData || {}
        if (this.orderInfo?.shipping_country_id == '209' && this.orderInfo?.subsidiary?.store_id != formData.storeId) {
          this.updateStoreAddress(formData.storeId)
        }

        let successText = this.orderInfo?.shipped_status_info?.edit_success_tip || this.language.SHEIN_KEY_PWA_18403
        this.addressShow = false
        this.addressEditFlag = false
        this.editSuccessFlag = true
        this.batchAddressMessage = successText
        this.showBatchAddressMessage = true

        let res = await getBffDataBySearchKeyword({ page: 1, keyword: this.orderInfo.billno })
        if (res?.order_list?.length) {
          this.updateSomeOrderInfo({ orderInfo: {
            ...(this.orderInfo || {}),
            shipped_status_info: res?.order_list?.[0]?.shipped_status_info || {},
            shippingaddr_info: res?.order_list?.[0]?.shippingaddr_info || {}
          } })
        }
      } else {
        if (res.code == 666666 || res.code == 777777) {
          this.$toast(this.language.SHEIN_KEY_PWA_17115)
        } else {
          let editAddressNewConfigOptions = this.editAddressNewConfigOptionsEvt(this.orderInfo)
          let errorText = getErrorHandleTipText(editAddressNewConfigOptions, this.language) || res.tips || this.language?.SHEIN_KEY_PWA_16088
          this.addressEditFlag = false
          this.editSuccessFlag = false
          this.batchAddressMessage = errorText
          this.showBatchAddressMessage = true
        }
      }
    },
    updateStoreAddress(storeId) {
      if (this.storeCache[storeId]) {
        this.orderInfo.shipping_address_1 = this.storeCache[storeId].address1
      } else {
        schttp({
          url: '/api/user/addressbook/storeInfoById/get?storeId=' + storeId
        }).then(res => {
          if (res.code == 0) {
            this.storeCache[storeId] = res.info.res
            this.orderInfo.shipping_address_1 = res.info.res.address1
          }
        })
      }
    },
    async getCountryListInfo() {
      let res = await schttp({
        url: '/api/user/addressbook/universalCountryList/get'
      })
      if (res.code == '0') {
        const countryLists = res?.info?.res?.item_country || []
        this.countryList = countryLists.map(item => Number(item.id))
        this.item_cates = countryLists
      } else {
        this.countryList = []
      }
    },
    toJumpPage() {
      const routeName = this.$route?.name
      if (routeName === 'UserOrdersList') {
        location.href = `${langPath}/user/orders/list`
      } else {
        location.href = `${langPath}/user/orders/detail/${this.orderInfo.billno}`
      }
    },
    isObjectEvt(obj) {
      return Object.prototype.toString.call(obj) === '[object Object]'
    }
  }
}
