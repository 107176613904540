<template>
  <div class="return-order-list__container">
    <!-- 海关拦截绿色通道 -->
    <div
      v-if="customAbnormalList.length"
      v-expose="{ id: '1-20-1-154', data: { orderid: order.billno } }" 
      class="alert-con custom-notice-alert"
    >
      <swiper-container
        ref="customNoticeSwiper"
        init="false"
        autoplay-delay="5000"
        pagination="true"
        :loop="customAbnormalList.length == 1 ? false : true"
      >
        <swiper-slide
          v-for="(item, noticeIndex) in customAbnormalList"
          :key="noticeIndex"
        >
          <s-alert
            type="warning"
            jumpable
            show-icon
            icon-class="suiiconfont sui_icon_notice_16px"
            @click.stop.native="jumpToCustomsInterceptionEvt(item.customs_package_link)"
          >
            {{ item.customs_tip }}
          </s-alert>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</template>

<script>
import { register } from 'swiper/element'
import { Pagination, Autoplay } from 'swiper/modules'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { expose } from 'public/src/pages/common/analysis/directive.js'

daEventCenter.addSubscriber({ modulecode: '2-29' })

typeof window !== 'undefined' && register()

export default {
  name: 'ReturnNotice',
  directives: { expose },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    publicCdn () {
      if (typeof window !== 'undefined') {
        return gbCommonInfo.PUBLIC_CDN
      }
      return this.publicLocalData?.PUBLIC_CDN || ''
    },
    customAbnormalList() {
      return this.order?.customs_info_list || []
    }
  },
  mounted() {
    this.initCustomSwiper()
  },
  methods: {
    initCustomSwiper() {
      this.$nextTick(() => {
        const swiperEl = this.$refs.customNoticeSwiper
        const params = {
          modules: [Pagination, Autoplay],
          injectStylesUrls: [
            this.publicCdn + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'
          ],
          injectStyles: [`
            .swiper-pagination-bullet {
              width: 4px;
              height: 4px;
              opacity: 1;
              background: #cccccc;
              vertical-align: middle;
              display: inline-block;
              border-radius: 10px;
              margin: 0 2px !important;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullet-active {
              width: 4px;
              height: 4px;
              background: #222;
              border: none;
            }
          `,
          `
            .swiper-pagination-bullets {
              line-height: 1;
              bottom: 4px !important;
            }
          `],
        }

        if (swiperEl) {
          Object.assign(swiperEl, params)
          swiperEl.initialize()
        }
      })
    },
    jumpToCustomsInterceptionEvt (customLink) {
      const { langPath } = gbCommonInfo

      daEventCenter.triggerNotice({
        daId: '1-20-1-153',
        extraData: {
          orderid: this.order.billno || ''
        }
      })
      const urlObj = new URL(customLink)
      const query = urlObj.search

      this.$router.push({
        path: `${langPath}/user/orders/customsInterception/${this.order.billno}${query}`,
      })
    },
  }
}
</script>

<style lang="less">
.custom-notice-alert {
  .S-alert__icon {
    color: #767676;
  }
}
</style>
<style lang="less" scoped>
.return-order-list__container {
  .alert-con {
    margin-top: 0.32rem;
    padding: 0 24/75rem;
    margin-top: 24/75rem;

    /deep/ .S-alert__jumpbtn {
      font-size: 16px;
      margin-left: 8/75rem;
      margin-right: 20/75rem;
      color: #959595;
    }
  }
}
.return-order-list__container {
  .custom-notice-alert {
    .S-alert {
      padding: 24/75rem;
      min-height: 120/75rem;
    }
  }
}
</style>
