<template>
  <div>
    <!-- used vue -->
    <section class="fixed-common-header order-list-z-header">
      <s-title-nav
        :title="language.SHEIN_KEY_PWA_16117"
        class="order-list-header"
      >
        <template slot="preblock">
          <sui_icon_nav_back_24px
            size="24px"
            :is-rotate="publicLocalData.GB_cssRight"
            @click.native="goBack"
          />
          <sui_icon_nav_search_24px
            size="24px"
            :is-rotate="publicLocalData.GB_cssRight"
            DA-sa-name="order_search"
            DA-type="syncClick"
            @click.native="orderSearchOpen"
          />
        </template>
        <template slot="endblock">
          <ClientOnly>
            <router-link
              v-if="robotShow"
              :to="jumpToRobot()"
              DA-sa-name="support"
              DA-type="syncClick"
              :DA-sa-param="JSON.stringify({ type: 'customer_service' })"
            >
              <SupportIcon
                :un-read-chat-num="unReadChatNum"
              />
            </router-link>
          </ClientOnly>
          <s-popover
            v-model="showDeleteHistoryPopover"
            class="delete-popover"
            trigger-type="user"
            :append-to-body="false"
            :content="language.SHEIN_KEY_PWA_18260"
            :prop-style="popoverStyle"
            placemen="bottom-end"
          >
            <span
              slot="reference"
              da-event-click="1-20-1-20"
              DA-sa-name="orderd_delete_history"
              DA-view-type="ordinary"
              @click="toRecycle"
            >
              <sui_icon_nav_delete_24px size="24px" />
            </span>
          </s-popover>
        </template>
      </s-title-nav>
      <div
        v-show="orderSearchMsg.show"
        class="macc-order-search"
      >
        <div class="search-title">
          <div class="search-input">
            <input
              v-model="orderSearchMsg.content"
              type="text"
              :placeholder="language.SHEIN_KEY_PWA_16581"
              maxlength="100"
              autocomplete="off"
              class="order-search-input"
              @keyup.enter="orderSearch"
            />
          </div>
          <a
            class="search-cancel"
            @click="orderSearchCancel"
          >
            {{ language.SHEIN_KEY_PWA_15729 }}
          </a>
        </div>
      </div>
    </section>
    <div
      v-cloak
      class="order-list-top-pad"
      :class="{ 'oreder-list-top-pad': orderSearchMsg.show }"
    >
      <!-- 页面loading -->
      <ClientOnly>
        <LazyMount mount-prop-name="show">
          <s-loading
            :show="pageLoading"
            type="newpage"
            :container-cover-style="{ position: 'fixed', height: '100%', width: '100%', background: '#fff' }"
          />
        </LazyMount>
      </ClientOnly>
      <list-tips v-if="!pageLoading && orderListNotice" />
      <div
        v-show="!pageLoading"
        v-infinite-scroll="loadMore"
        class="j-scroll-container"
        infinite-scroll-disabled="status.loading"
        infinite-scroll-nodata="noMoreData"
        infinite-scroll-distance="300"
      >
        <SNetworkErrorAlert
          class="network-error"
          :description="language.SHEIN_KEY_PWA_31743"
          @online-change="onlineChange"
        />
        <s-tab
          v-if="!pageLoading && !orderSearchMsg.show"
          :value="status_type"
          :type="'auto'"
          @input="handleTabChange"
        >
          <s-tab-item
            v-for="tabItem in orderStatusTabs"
            :id="tabItem.id"
            :key="tabItem.orderStatus"
            DA-type="syncClick"
            DA-sa-name="order_status"
            :DA-sa-param="JSON.stringify(tabItem.saParam || { order_status: tabItem.orderStatus.toLocaleLowerCase() })"
          >
            {{ language[tabItem.title] }}
          </s-tab-item>
        </s-tab>
        <router-link
          v-if="shouldGiftCardShow && !status_type"
          :to="publicLangPath + '/user/orders/giftcardlist'"
          style="text-decoration: none"
          DA-sa-name="gift_card_orders"
          DA-type="syncClick"
        >
          <div class="order-list-flexbetween go-gift-card-list">
            <span>{{ language.SHEIN_KEY_PWA_16931 }} </span>
            <em> <sui_icon_more_right_16px
              size="16px"
              :is-rotate="publicLocalData.GB_cssRight"
            /> </em>
          </div>
        </router-link>
        <WaitSendReturnNotice
          v-if="waitSendTotal && status_type == 4"
          class="wait-send-return"
          :language="language"
          :return-total="waitSendTotal"
          da-expose-id="1-20-1-131"
          da-click-id="1-20-1-132"
        />
        <a
          v-if="abtInfos.isShowOrderResellEntrance && !status_type"
          da-event-click="1-20-1-55"
          :href="exchangeLink"
          style="text-decoration: none"
        >
          <div class="order-list-flexbetween go-gift-card-list">
            <span>{{ language.SHEIN_KEY_PWA_21443 }} </span>
            <em> <sui_icon_more_right_16px
              size="16px"
              :is-rotate="publicLocalData.GB_cssRight"
            /> </em>
          </div>
        </a>
        <comment-reward v-if="reviewRewards"/>
        <empty-state
          v-if="orders.length == 0 && status.historyExtra"
          :configs="{
            content: `${language.SHEIN_KEY_PWA_15043} :-(`
          }"
        />
        <template v-else-if="status_type == 4">
          <order-return-item
            v-for="(order, index) in renderOrders"
            :key="order.billno"
            class="macc-order-list order-list-new"
            :data-datetime="order.addTime"
            :data-address="order.shipping_country_name"
            :data-payment_method="order.payment_method"
            :index="index"
            :order="order"
          />
        </template>
        <template v-else>
          <order-list-item
            v-for="(order, index) in renderOrders"
            :key="order.billno"
            class="macc-order-list order-list-new"
            :data-datetime="order.addTime"
            :data-address="order.shipping_country_name"
            :data-payment_method="order.payment_method"
            :order="order"
          >
            <template #default="{ payNowLabelList, repurchaseAnalysisData, orderBtnMore }">
              <list-item
                :language="language"
                :order="order"
                :index="index"
                :orders-status="ordersStatus"
                :show-mask-tips="showMaskTips"
                :abTrackTestConfig="abtInfos.abTrackTestConfig"
                :orderStatusTextList="orderStatusTextList"
                :isCanRefundAbt="abtInfos.isCanRefundAbt"
                :pay-now-label-list="payNowLabelList"
                @showCombinedStatus="showCombinedStatus"
              />
              <ClientOnly>
                <div class="list-bot not-fsp-element">
                  <div
                    v-if="order.isReadOnly != 1 && !order.isOldOrder"
                    :id="'list-btn-' + order.billno"
                    ref="listContent"
                    class="list-bot-btn"
                    :data-invoices="
                      JSON.stringify({
                        orderStatus: order.orderStatus,
                        is_have_invoice: order.is_have_invoice
                      })
                    "
                    :data-upload="
                      order.uploadTryStatus && order.uploadTryStatus.status
                        ? order.uploadTryStatus.status
                        : ''
                    "
                  >
                    <template v-for="name in opeButtonSort(order)">
                      <!-- one click pay -->
                      <s-button
                        v-if="name === 'one_click_pay'"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-58', data: { order_no: order.merge_buy_billno } }"
                        v-tap="{
                          id: '1-20-1-59',
                          data: { order_no: order.merge_buy_billno, type: 'anchor' }
                        }"
                        :type="['H56PX']"
                        class="one_click_pay-list order-list-bot__btn"
                        @click="toDetailRecommendEvt(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_23667 }}
                        <div class="free_tips">
                          <div class="free_text">
                            <i class="suiiconfont sui_icon_free_shipping_12px icon"></i>
                            {{ language.SHEIN_KEY_PWA_23668 }}
                          </div>
                        </div>
                      </s-button>

                      <!-- 催派 -->
                      <s-button
                        v-if="name == 'urge_delivery'"
                        :key="name"
                        v-expose="{ id: '1-20-1-86', data: { order_no: order.billno } }"
                        v-tap="{ id: '1-20-1-87', data: { order_no: order.billno } }"
                        :type="['H56PX']"
                        class="order-list-bot__btn"
                        @click="showUrgeDelivery(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_26632 }}
                      </s-button>

                      <s-button
                        v-if="name == 'urgent_pickup'"
                        :key="name"
                        :type="['H56PX']"
                        class="order-list-bot__btn"
                        @click="urgeReturnEvt(order, orderBtnMore)"
                      >
                        {{ language.SHEIN_KEY_PWA_31578 }}
                      </s-button>

                      <!-- view invoices -->
                      <s-button
                        v-if="name === 'view_invoices'"
                        :key="`oper_button_${name}`"
                        :type="['H56PX']"
                        class="order-list-bot__btn"
                        @click="navToViewInvoices(order.billno)"
                      >
                        {{ language.SHEIN_KEY_PWA_18679 }}
                      </s-button>

                      <!-- upload report -->
                      <s-button
                        v-if="'upload_report' == name"
                        :key="name"
                        :type="['H56PX']"
                        :class="{ 'report-disabled': order.uploadTryStatus.status == 2 }"
                        class="order-list-bot__btn"
                        @click="toEditReport(order.uploadTryStatus, 'button', order)"
                      >
                        {{ language.SHEIN_KEY_PWA_16228 }}
                      </s-button>

                      <!-- confirm delivery -->
                      <s-button
                        v-if="'confirm_delivery' == name"
                        :key="name"
                        :type="['H56PX']"
                        :class="['order-list-bot__btn', { 'over-time': getConfirmOverTime(order) } ]"
                        DA-view-type="ordinary"
                        DA-view-no-param="1"
                        DA-type="syncClick"
                        DA-sa-name="confirm_delivery"
                        :disabled="getConfirmOver(order)"
                        :DA-sa-param="JSON.stringify({ order_id: order.billno })"
                        @click="confirmDeliverAlterFun(order, order.confirmDeliveryBonusPoints)"
                      >
                        {{ language.SHEIN_KEY_PWA_14854 }}
                      </s-button>

                      <div
                        v-if="'review' == name"
                        :key="name"
                        class="comment-btn-op"
                      >
                        <div
                          :ref="`${order.billno}-points-tip`"
                          class="points-tip-bottom"
                          :class="{'hide-points-tip': riskCommentArr.includes(order.billno)}"
                          @click.stop="handlePointsTipShow(`${order.billno}-points-tip`)"
                        >
                          <i class="iconfont icon-close"></i>
                          {{ language.SHEIN_KEY_PWA_16291 }}
                        </div>
                        <s-button
                          :type="['H56PX']"
                          DA-type="syncClick"
                          DA-view-type="ordinary"
                          DA-sa-name="review"
                          name="review"
                          class="order-list-bot__btn"
                          @click="navToReview(order)"
                        >
                          {{ language.SHEIN_KEY_PWA_15162 }}
                        </s-button>
                        <span
                          :ref="`${order.billno}-points-tip-arrow`"
                          class="points-tip-arrow"
                        ></span>
                      </div>

                      <!-- 物流轨迹 -->
                      <s-button
                        v-if="'track' == name"
                        :key="name"
                        :type="['H56PX']"
                        DA-view-type="ordinary"
                        DA-view-no-param="1"
                        DA-type="syncClick"
                        DA-sa-name="track"
                        :DA-sa-param="
                          JSON.stringify({ order_id: order.billno, order_status: order.orderStatus })
                        "
                        class="order-list-bot__btn"
                        @click="handelTrackBtnClick(order)"
                      >
                        <span v-html="language.SHEIN_KEY_PWA_15232"></span>
                      </s-button>

                      <!-- 已付款未发货取消订单申请退款 -->
                      <s-button
                        v-if="'cancel_order' == name"
                        :key="name"
                        :type="['H56PX']"
                        :class="['order-list-bot__btn', {
                          'opacity-item':
                            overFrontCondition(order) > 0 ||
                            !!overB2B2CCondition(order) ||
                            disabledCancelButton(order)
                        }]"
                        DA-view-type="ordinary"
                        DA-view-no-param="1"
                        DA-type="syncClick"
                        DA-sa-name="unshipped_cancel_order"
                        :DA-sa-param="JSON.stringify({ order_id: order.billno })"
                        @click="orderRefund(order, ordersStatus.text[order.orderStatus])"
                      >
                        {{ showRefundNew ? language.SHEIN_KEY_PWA_15498 : language.SHEIN_KEY_PWA_15437 }}
                      </s-button>

                      <!-- in站不能在ar站短信验证 -->
                      <s-button
                        v-if="'verify_now' == name"
                        :key="name"
                        DA-view-type="ordinary"
                        DA-type="syncClick"
                        DA-sa-name="orderlist_verify"
                        :type="['H56PX']"
                        class="j-pay-now-list order-list-bot__btn"
                        @click="payNowCommonEvt(order, { payType: 2, confirmPayType: 1 })"
                      >
                        {{ language.SHEIN_KEY_PWA_15370 }}
                        <div
                          v-if="abtInfos.orderListAbtInfo.indexOf('paytime=on') > -1"
                          class="j-pay-now-warp"
                        >
                          <order-expire-time-down :order="order" />
                        </div>
                      </s-button>

                      <!-- pay now -->
                      <template v-if="'pay_now' == name">
                        <PayNowBtn 
                          v-if="showOrderListPromotionTest"
                          :key="name"
                          class="order-list-bot__btn"
                          DA-view-type="ordinary"
                          DA-type="syncClick"
                          DA-sa-name="orderlist_paynow"
                          :DA-sa-param="
                            JSON.stringify({
                              order_id: order.billno,
                              bussiness_tp: orderBusiModel(order),
                              buy_tp: orderBuyTpInfo(order),
                              inducement_type: orderInducementType(payNowLabelList)
                            })
                          "
                          :order="order"
                          :language="language"
                          :pay-now-label-list="payNowLabelList"
                          @update-free-refund="updateFreeRefund"
                          @click="payNowCommonEvt(order, { payType: 2, confirmPayType: 1 })"
                        >
                          {{ isBarcode(order) ? language.SHEIN_KEY_PWA_24198 : language.SHEIN_KEY_PWA_15655 }}
                        </PayNowBtn>
                        <template v-else>
                          <s-button
                            v-if="isBarcode(order)"
                            :key="name"
                            :type="['H56PX']"
                            class="j-pay-now-list order-list-bot__btn"
                            @click.stop="payNowCommonEvt(order, { payType: 1, jumpType: 1 })"
                          >
                            {{ language.SHEIN_KEY_PWA_24198 }}
                            <div
                              v-if="showExpireCountDown(order)"
                              class="j-pay-now-warp"
                            >
                              <order-expire-time-down :order="order" />
                            </div>
                          </s-button>
                          <s-button
                            v-else
                            :key="name"
                            DA-view-type="ordinary"
                            DA-type="syncClick"
                            DA-sa-name="orderlist_paynow"
                            :DA-sa-param="
                              JSON.stringify({
                                order_id: order.billno,
                                bussiness_tp: orderBusiModel(order),
                                buy_tp: orderBuyTpInfo(order)
                              })
                            "
                            :type="['H56PX']"
                            class="j-pay-now-list order-list-bot__btn"
                            @click="payNowCommonEvt(order, { payType: 2, confirmPayType: 1 })"
                          >
                            {{ language.SHEIN_KEY_PWA_15655 }}
                            <div
                              v-if="showExpireCountDown(order)"
                              class="j-pay-now-warp"
                            >
                              <order-expire-time-down :order="order" />
                            </div>
                          </s-button>
                          <!-- Other Payment Method -->
                          <s-button
                            v-if="isBarcode(order)"
                            :key="`oper_button_${name}`"
                            :type="['H56PX']"
                            class="order-list-bot__btn"
                            @click.stop="payNowCommonEvt(order, { payType: 2, confirmPayType: 2 })"
                          >
                            {{ language.SHEIN_KEY_PWA_24378 }}
                          </s-button>
                        </template>
                      </template>
                      <s-button
                        v-if="'urge_shipment' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{ id: '1-20-1-84', data: { order_no: order.billno } }"
                        :type="['default', 'H56PX']"
                        class="order-list-bot__btn"
                        @click="urgeshipment(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_26377 }}
                      </s-button>
                      <s-button
                        v-if="'exchange' == name"
                        :key="`oper_button_${name}`"
                        v-expose="{
                          id: '1-20-1-97',
                          data: { order_no: order.billno, style: getSelfExchangeStatus(order, orderBtnMore) == 2 ? 'gray' : 'normal' }
                        }"
                        v-tap="{
                          id: '1-20-1-98',
                          data: { order_no: order.billno, style: getSelfExchangeStatus(order, orderBtnMore) == 2 ? 'gray' : 'normal' }
                        }"
                        :class="['order-list-bot__btn', { 'opacity-item': getSelfExchangeStatus(order, orderBtnMore) == 2 }]"
                        :type="['default', 'H56PX']"
                        name="exchange"
                        DA-sa-id="1-20-1-97"
                        :DA-sa-param="
                          JSON.stringify({ order_no: order.billno, style: getSelfExchangeStatus(order, orderBtnMore) == 2 ? 'gray' : 'normal' })
                        "
                        @click="handleExchangeOperation(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_29069 }}
                      </s-button>

                      <!-- 编辑地址按钮新 -->
                      <template v-if="'edit_address' == name">
                        <EditAddressBtnNew
                          v-if="abtInfos.showEditOrderAddress"
                          :key="name"
                          :language="language"
                          :order="order"
                          :newEditAddressOptions="newEditAddressOptions"
                          :editAddressNewConfigList="order.editAddressNewConfigList"
                          :class="{'edit-address-link': showEditAddressLink(order)}"
                          @showEditAddressDialog="showEditAddressDialogEvt"
                        />
                        <!-- 编辑地址按钮旧 -->
                        <s-button
                          v-else-if="!abtInfos.showEditOrderAddress && isShowEditAddressBtn(order)"
                          :key="name"
                          :class="['order-list-bot__btn', {
                            'edit-address-link': showEditAddressLink(order),
                            'opacity-item':
                              overFrontCondition(order) > 0 ||
                              overB2B2CEditAddress(order) ||
                              disabledEditAddressBtn(order)
                          }]"
                          DA-type="syncClick"
                          DA-view-type="ordinary"
                          DA-sa-name="orderlist_editaddress"
                          da-event-click="1-20-1-2"
                          :type="['H56PX']"
                          @click="validUpdateAddress(order)"
                        >
                          {{ language.SHEIN_KEY_PWA_16969 }}
                        </s-button>
                      </template>  

                      <s-button
                        v-if="'revoke' == name"
                        :key="name"
                        :type="['H56PX']"
                        :class="['order-list-bot__btn', {
                          'opacity-item':
                            overFrontCondition(order) > 0 ||
                            !!overB2B2CCondition(order) ||
                            disabledRevokeByUser(order)
                        }]"
                        @click="toRevokeOrder(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_15498 }}
                      </s-button>
                      <s-button
                        v-if="'repurchase' == name"
                        :key="name"
                        v-expose="{ id: '1-20-1-143', data:repurchaseAnalysisData }"
                        da-sa-id="1-20-1-143"
                        :da-sa-param="JSON.stringify(repurchaseAnalysisData)"
                        :type="['H56PX']"
                        class="order-list-bot__btn repurchase-btn"
                        @click="toRepurchaseEvt(order, repurchaseAnalysisData)"
                      >
                        <RepurchaseLabel 
                          v-if="showNewRepurchase && order.repurchase_lure_info && order.repurchase_lure_info.tip"
                          :locals="publicLocalData"
                          :repurchase-info="order.repurchase_lure_info"
                        />
                        {{ language.SHEIN_KEY_PWA_16211 }}
                      </s-button>
                      <s-button
                        v-if="'my_review' == name"
                        :key="name"
                        DA-type="syncClick"
                        DA-sa-name="my_review"
                        DA-view-type="ordinary"
                        :type="['H56PX']"
                        class="order-list-bot__btn"
                        @click="navToMyReview(order.billno)"
                      >
                        {{ language.SHEIN_KEY_PWA_16290 }}
                      </s-button>
                      <!--自主退货-->
                      <s-button
                        v-if="'return_item' == name"
                        :key="name"
                        class="j-return-btn ga-return-item order-list-bot__btn"
                        :data-status="ordersStatus.text[order.orderStatus]"
                        DA-type="syncClick"
                        DA-sa-name="orderlist_returnitem"
                        DA-view-type="ordinary"
                        :type="['H56PX']"
                        @click="returnItemEvt(order)"
                      >
                        {{ language.SHEIN_KEY_PWA_33481 }}
                      </s-button>
                    </template>
                    <div
                      v-if="
                        order.order_type != 5 &&
                          (orderBtnMore.isShowBtnMore || (order.isCanComment && order.isCanComment == 3))
                      "
                      class="my-reviewed-comment"
                    >
                      <div
                        :ref="`${order.billno}-more-list`"
                        class="btn-more-list"
                        style="display: none"
                      >
                        <p
                          v-for="(item, childIndex) in orderBtnMore.btnMoreList"
                          :key="childIndex"
                          v-expose="getBtnMoreAnalysis(item)"
                          class="btn-more"
                          :class="item.addClass"
                          @click="handleBtnMoreItem(item)"
                          v-html="item.text"
                        ></p>
                        <div
                          v-if="order.isCanComment && order.isCanComment == 3"
                          :ref="`${order.billno}-my-reviewed`"
                          class="my-review-btn btn-more"
                          DA-type="syncClick"
                          DA-sa-name="my_review"
                          @click="navToMyReview(order.billno)"
                        >
                          {{ language.SHEIN_KEY_PWA_16290 }}
                        </div>
                      </div>
                      <a
                        v-tap="{ id: '1-20-1-134', data: { order_status: order.orderStatus } }"
                        DA-type="syncClick"
                        DA-sa-name="more"
                        :DA-sa-param="JSON.stringify({ order_id: order.billno })"
                        @click="handleMyReviewShow(`${order.billno}-more-list`, order)"
                      >
                        <span 
                          v-if="abtInfos.moreStyle === 'text' && publicLocalData.lang != 'euqs'"
                          class="more-text"
                        >
                          {{ language.SHEIN_KEY_PWA_23768 }}
                        </span>
                        <template v-else>
                          <span class="point"></span>
                          <span class="point"></span>
                          <span class="point"></span>
                        </template>
                      </a>
                    </div>
                  </div>
                </div>
              </ClientOnly>
            </template>
          </order-list-item>
        </template>
        <div
          v-if="
            status.histtoryStatus &&
              orders.length > 0 &&
              !orderSearchMsg.isSearchList &&
              status.showHistory
          "
          class="order-list-text-center order-list-history"
        >
          <a
            href="javascript:;"
            @click="historyListFun(2)"
          >
            <span v-html="language.SHEIN_KEY_PWA_16184"></span>
            <i class="iconfont icon-down"></i>
          </a>
        </div>
        <div
          v-if="!status.loading && orders.length == 0 && !status.historyExtra"
          style="background: #FFF"
        >
          <!-- 空状态组件 -->
          <empty-state
            :configs="emptyConfig"
            @on-button-click="historyListFun(1)"
          />
        </div>

        <FindOrder v-if="isShowFindOrder" />

        <div
          v-if="isDemotion && !status.loading && orders.length"
          class="demotion"
        >
          {{ language.SHEIN_KEY_PWA_17808 }}
        </div>
        <ClientOnly>
          <!-- tab切换loading -->
          <LazyMount mount-prop-name="show">
            <s-loading
              type="curpage"
              :show="status.loading"
              :mask-transparent="false"
              :container-cover-style="{ position: 'fixed', height: '100%', width: '100%', top: '1.17rem' }"
            />
          </LazyMount>

          <!-- 确认收货触发发积分场景事前校验 -->
          <LazyMount>
            <commonSelectiveTipsDialog
              class="confirm-deliver-alter-modal"
              :visible.sync="orderDeliveryPointCheckTips.showModal"
              :title="htmlDecode(orderDeliveryPointCheckTips.text)"
              :primaryText="language.SHEIN_KEY_PWA_15252"
              :secondaryText="language.SHEIN_KEY_PWA_15253"
              :close-on-click-modal="false"
              hor
              :num="2"
              @primary="confirmDeliver(orderTmp)"
              @secondary="cancelPreCheckConfirmDeliver"
            />
          </LazyMount>

          <!-- confirmDeliver alter tips -->
          <LazyMount>
            <commonTipsDialog
              class="confirm-deliver-alter-modal"
              :visible.sync="confirmDeliverMessage"
              append-to-body
              :title="confirmDeliverMessageText"
              :btnType="['primary', 'H80PX']"
              :btnText="
                orderDeliveryForm.isCanComment &&
                  (orderDeliveryForm.isCanComment === 1 || orderDeliveryForm.isCanComment === 3) &&
                  !orderDeliveryForm.isFreeTrial
                  ? `${language.SHEIN_KEY_PWA_17939}`
                  : `${language.SHEIN_KEY_PWA_15146}`
              "
              @ok="confirmDeliverMessageEvt"
            />
          </LazyMount>

          <!-- 试用订单确认收货成功且没有命中积分熔断校验，confirmDeliver alter tips -->
          <LazyMount>
            <commonSelectiveTipsDialog
              class="confirm-deliver-alter-modal confirm-deliver-upload-modal"
              :visible.sync="confirmDeliverUpload"
              :title="language.SHEIN_KEY_PWA_18258"
              :primaryText="language.SHEIN_KEY_PWA_18266"
              :secondaryText="language.SHEIN_KEY_PWA_16092"
              @close="closeConfirmDeliverUpload"
              @primary="toEditReport(orderDeliveryForm.uploadTryStatus, 'popup')"
              @secondary="closeConfirmDeliverUpload('button')"
            />
          </LazyMount>

          <!-- codOrderIsNotSupportReceipt alter -->
          <LazyMount>
            <commonTipsDialog
              :visible.sync="codOrderIsNotSupportReceipt"
              :title="codOrderIsNotSupportReceiptTip"
              :btnText="language.SHEIN_KEY_PWA_15146"
              :btnType="['primary', 'H80PX']"
              :close-on-click-modal="false"
            />
          </LazyMount>

          <!-- 地址接口成功与错误弹窗 -->
          <LazyMount>
            <editAddressTipsDialog
              :visible.sync="orderAddress.show"
              :language="language"
              :type="orderAddress.type"
              :text="orderAddress.text"
            />
          </LazyMount>

          <!-- 地址 -->
          <address-vue
            ref="addressVue"
            :show="addressShow"
            :page-name="'orderlist'"
            :page-type="editAddressType"
            :extra="extraAddress"
            :disabled="disabledAddress"
            @edit="addressEdit"
            @cancel="addressCancel"
            @error="updateError"
          />

          <store-address-vue
            v-if="
              orderInfo.subsidiary &&
                TRANSPORT_TYPE_SHOP.includes(orderInfo.subsidiary.transport_type)
            "
            ref="storeAddress"
            :page-type="'order'"
            @save="saveStoreAddress"
          />
          <!-- 接入推荐组件 -->
          <Recommend v-if="noMoreData" />
        </ClientOnly>
      </div>
    </div>
    <ClientOnly>
      <!-- 我的包裹悬浮球 -->
      <MyPackageEntrance
        v-if="showMyPackage"
        ref="myPackageEntranceRef"
        v-expose="{ id: '1-20-1-100' }"
        v-tap="{ id: '1-20-1-101' }"
      />
      <LazyMount mount-prop-name="show">
        <s-loading
          type="curpage"
          :mask-transparent="true"
          :show="status.msgLoading"
        />
      </LazyMount>
      <!-- refund mask -->
      <LazyMount>
        <commonSelectiveTipsDialog
          :visible.sync="refundMask"
          :title="language.SHEIN_KEY_PWA_15440"
          :primaryText="language.SHEIN_KEY_PWA_15312"
          :secondaryText="language.SHEIN_KEY_PWA_16092"
          hor
          :num="2"
          @primary="orderRefundMask"
          @secondary="refundMask = false"
        />
      </LazyMount>

      <LazyMount>
        <commonSelectiveTipsDialog
          :visible.sync="refundNotCodMask"
          :title="language.SHEIN_KEY_PWA_15438"
          :primaryText="language.SHEIN_KEY_PWA_15312"
          :secondaryText="language.SHEIN_KEY_PWA_16092"
          :is-loading="refundNotCodMaskLoading"
          @primary="orderRefundMask"
          @secondary="refundNotCodMask = false"
          @close="refundNotCodMask = false"
        />
      </LazyMount>

      <!-- return mask -->
      <LazyMount>
        <commonSelectiveTipsDialog
          :visible.sync="inReturnItem.mask"
          title="Have you received your package? Please confirm if you have, then you can make return
            request."
          :primaryText="language.SHEIN_KEY_PWA_15252"
          :secondaryText="language.SHEIN_KEY_PWA_15253"
          :close-on-click-modal="false"
          hor
          :num="2"
          @primary="returnItemMask"
          @secondary="inReturnItem.mask = false"
        />
      </LazyMount>

      <!-- 信息提示 -->
      <LazyMount>
        <msgBoxDialog
          :visible.sync="status.msg"
          :type="msgBox.type"
          :info="msgBox.info"
        />
      </LazyMount>

      <!-- 挽留订单 -->
      <LazyMount>
        <commonSelectiveTipsDialog
          :visible.sync="retainOrder.mask"
          :title="getRetainTips(retainOrder.order.payment_method)"
          :primaryText="language.SHEIN_KEY_PWA_16825"
          :secondaryText="language.SHEIN_KEY_PWA_16826"
          :close-on-click-modal="false"
          reverse
          @primary="toggleRetainOrder('cancel')"
          @secondary="
            () => {
              toggleRetainOrder('ok')
              handelCacncelBtnClick(retainOrder.order)
            }
          "
        />
      </LazyMount>

      <cancel-mask
        :cancel-form="cancelForm"
        cancel-type="list"
        :orders-all="orders"
        @showmsg="showMsg"
      />

      <!-- 确认收货成功时提示信息 -->
      <LazyMount>
        <commonTipsDialog
          :visible.sync="orderDeliverTipsBool"
          :title="orderDeliverTips"
          :btnText="language.SHEIN_KEY_PWA_15146"
          :btnType="['primary', 'H80PX']"
        />
      </LazyMount>

      <!-- 超过时效提示 -->
      <LazyMount>
        <commonTipsDialog
          :visible="isShowOverTimeLimit"
          :title="htmlDecode(language.SHEIN_KEY_PWA_16451)"
          :btnText="language.SHEIN_KEY_PWA_15312"
          @close-from-mask="isShowOverTimeLimit = false"
          @ok="toggleShowOverTimeLimit(true)"
        />
      </LazyMount>

      <!--支付方式-->
      <chooce-payments-panel
        :paypal-btn-show="paypalBtnShow"
        :paypal-bnpl-btn-show="paypalBnplBtnShow"
        :payments="payments"
        :module-name="moduleName"
        :order-info="orderInfo"
        :need-pay-inline-payment-methods="needPayInlinePaymentMethods"
        :banks-config="banksConfig"
        :signup-drawer-config="signupDrawerConfig"
        :mbway-info="mbwayInfo"
        :ppgv-account-info="ppgvAccountInfo"
        :after-pay-account-info="afterPayAccountInfo"
        :signup-account-info="signupAccountInfo"
        :paypal-ga-vault="paypalGaVault"
        :after-pay-vault="afterPayVault"
        :signup-pay-vault="signupPayVault"
        :paypal-venmo-btn-show="paypalVenmoBtnShow"
        :matched-bin-discount-info="matchedBinDiscountInfo"
        :handle-start-select-bank="handleStartSelectBank"
        :show-widget-form="showWidgetForm"
        :hack-lock-scroll="true"
        :is-show-bin-random-discount-abt="abtInfos.isShowBinRandomDiscountABT"
        :show-applepay-dropoff-optimization="abtInfos.showApplepayDropoffOptimization"
        :apple-pay-code-list-apollo="applePayCodeList"
        :total-price-amount="totalPriceAmount"
        @confirmMbwayPhone="confirmMbwayPhone"
        @loadWidget="loadWidget"
        @payment-options-oper="handlePaymentOptionsOper"
        @show-paypalga-type="showPaypalGaType"
        @confirm-ppgv-type="confirmPPGVType"
        @confirm-afterpay-type="confirmAfterPayType"
        @confirm-signup-pay-type="confirmSignupPayType"
        @confirm-signup-one-time-pay="confirmSignupOneTimePay"
        @click-signup-one-time-arrow="handleClickSignupOneTimeArrow"
        @mbwayInputFocus="mbwayInputFocus"
        @showMbwayDialog="showMbwayDialog"
        @paynow="handlePaynow"
      />

      <!-- 银行列表选择 -->
      <LazyMount>
        <ChooseBankDrawer
          :visible.sync="bankDrawerConfig.visible"
          :language="language"
          :bank-list="bankDrawerConfig.bankList"
          :default-code="bankDrawerConfig.defaultCode"
          @select="handleBankSelectInVm"
        />
      </LazyMount>
      <!-- 南非Capitec银行卡弹框 -->
      <LazyMount>
        <bankCapitecDialog 
          :visible.sync="capitecDialog.visible"
          :language="language"
          :capitecDialog="capitecDialog"
          @event="handleCapitecDialogEvent"
        />
      </LazyMount>

      <!-- 谷歌pay弹窗 -->
      <googlepay-dialog :visible.sync="googlePay.show" />

      <!--qiwi-->
      <LazyMount>
        <qiwiPhoneDialog 
          :visible.sync="qiwi.phoneShow"
          :language="language"
          :phone.sync="qiwiPayPhone"
          :err="qiwi.phoneError"
          @submit="clickQiwiPay"
          @close="clickQiwiPay"
        />
      </LazyMount>
      <!--boleto-->
      <LazyMount>
        <boletoEmailDialog 
          :visible.sync="emailTips.show"
          :language="language"
          :email.sync="emailTips.val"
          :err="emailTips.err"
          @submit="checkEmail"
        />
      </LazyMount>
      <!-- APPLE pay弹窗 -->
      <LazyMount>
        <applePayDialog
          :visible="applePayShow"
          @close-from-icon="closeApplePayModel"
          @pay="toApplePay"
        />
      </LazyMount>

      <!-- 疫情影响提示  -->
      <LazyMount>
        <commonTipsDialog
          :visible.sync="isShowReturnUnavailableModal"
          :title="!IS_RW ? language.SHEIN_KEY_PWA_17097 : language.SHEIN_KEY_PWA_17113"
          :btnText="language.SHEIN_KEY_PWA_15312"
          append-to-body
          is-title-left
          @close-from-icon="isShowReturnUnavailableModal = false"
        />
      </LazyMount>
      <!-- abtest关闭功能提示 -->
      <LazyMount>
        <commonTipsDialog
          :visible.sync="unavailableModal"
          :title="htmlDecode(language.SHEIN_KEY_PWA_17115)"
          :btnText="language.SHEIN_KEY_PWA_15312"
          append-to-body
          is-title-left
          @close-from-icon="toggleUnavailableModal"
        />
      </LazyMount>
      <!-- 订单取消，重新加回购物车 -->
      <LazyMount>
        <addCartDialog
          :visible.sync="status.showAddCart"
          :language="language"
          @add-cart="handleCancelAddCartModal"
        />
      </LazyMount>

      <LazyMount mount-prop-name="show">
        <expired-modal
          :show="expiredModalConfig.show"
          :language="language"
          @toggle="toggleOrderExpiredModal"
        />
      </LazyMount>
      <repurchase-confirm-dialog
        :billno="repurchaseOrderBillno"
        :language="language"
        :show="repurchaseModel"
        @handleSave="handleRepurchase"
      />
      <!-- 添加购物车成功提示 -->
      <LazyMount>
        <AddCartSuccessDialog
          :visible.sync="addCartStatus.showDrawer"
          :language="language"
          :info="addCartStatus.cartInfo"
        />
      </LazyMount>

      <LazyMount mount-prop-name="show">
        <successPanel
          :show.sync="successPanelData.show"
          :billno="successPanelData.billno"
          from="orderList"
        />
      </LazyMount>

      <!-- 判断合单状态是否为混发 -->
      <LazyMount>
        <combinedOrderDialog
          :visible.sync="combinedOrderModal.show"
          :tips="combinedOrderModal.tips"
          :mergeBuyBillno="combinedOrderModal.merge_buy_billno"
          :language="language"
          @to-detail="navToDetail(combinedOrderModal.billno)"
        />
      </LazyMount>

      <LazyMount mount-prop-name="show">
        <pay-result-tips
          :show="showPayResult"
          :language="language"
        />
      </LazyMount>

      <!-- cod取消商品时的提示 -->
      <LazyMount>
        <cancel-item-tip
          :visible.sync="codCancelItemTipVisible"
          :language="language"
          @confirm="handleShowCodCancelItemSelect"
          @cancel="codCancelItemTipVisible = false"
        />
      </LazyMount>

      <!-- 多主体站点跳转 -->
      <orderCheckJumpMask
        ref="orderCheckJumpMask"
        :type="'list'"
        :language="language"
        @showRefundOrderEvt="showRefundOrderEvt"
      />

      <!-- 已支付未发货cod订单单件退 -->
      <cancel-item-comp v-if="isOrderList" />
      <LazyMount>
        <commonTipsDialog
          :content="timeTips"
          isContentCenter
          :btnText="language.SHEIN_KEY_PWA_15146"
          :btnType="['primary', 'H72PX']"
          :visible.sync="isShowTimeTipsDialog"
        />
      </LazyMount>
      <LazyMount>
        <commonTipsDialog
          :content="showFrontConditionText"
          :btnText="language.SHEIN_KEY_PWA_15146"
          :visible.sync="showFrontConditionDialog"
        />
      </LazyMount>
      <LazyMount>
        <commonTipsDialog
          :content="htmlDecode(showBlackTips.text)"
          :btnText="language.SHEIN_KEY_PWA_15312"
          :visible.sync="showBlackTips.show"
        />
      </LazyMount>
      <LazyMount>
        <commonTipsDialog
          :title="urgeshipmentText.title"
          :content="urgeshipmentText.content"
          :btnText="language.SHEIN_KEY_PWA_26382"
          :show-close="true"
          :visible.sync="isShowUrgeShipmentDialog"
        />
      </LazyMount>
      <!-- 整单退关联商品一起退 -->
      <cancelRelationGoodItem
        :show="showRelationDialog"
        :relationGoodsList="relationGoodsList"
        :relationOptions="relationOptions"
        :language="language"
        @confirmCancelSubmit="confirmCancelSubmit"
        @confirmCancelOrder="confirmCancelOrder"
        @updateShowValue="updateShowValue"
      />
      <!-- 确认收货新提示 -->
      <LazyMount mount-prop-name="show">
        <confirmDeliveryTips
          :show="showConfirmDeliveryTips"
          :language="language"
          :goodsList="confirmDeliveryGoodsInfo"
          @cancelConfirmDeliver="cancleConfirmOrder"
          @preCheckPointByConfirmOrder="preCheckPointByConfirmOrder"
        />
      </LazyMount>
      <!-- 卡bin优惠文章展示 -->
      <LazyMount>
        <CardBinDiscountArticle :visible.sync="visibleCardBinDiscountArticle" />
      </LazyMount>
      <LazyMount mount-prop-name="showConfirmDialog">
        <CustomerConfirmAddress
          :showConfirmDialog.sync="showConfirmDialog"
          :orderData="orderInfo"
          :language="language"
        />
      </LazyMount>
      <!-- 批量修改地址确认弹窗 -->
      <LazyMount>
        <edit-address-confirm-dialog
          :visible.sync="showEditAddressConfirmDialog"
          :language="language"
          :batch-modify-address="batchModifyAddressData"
          :billno="orderInfo.billno"
          @confirm="onConfirmModifyAddress"
        />
      </LazyMount>

      <!-- 催派业务 -->
      <UrgeDeliveryModal
        ref="urgeDeliveryModalRef"
        page-from="order_list"
        :order-info="orderInfo"
        :language="language"
      />

      <!-- 订单不可换货提示 -->
      <LazyMount>
        <commonTipsDialog
          :content="disableExchangeTips"
          :btnText="language.SHEIN_KEY_PWA_26382"
          :btnType="['primary', 'H72PX']"
          isContentCenter
          :visible.sync="showExchangeTipsPop"
        />
      </LazyMount>

      <!-- 留评风控提示 -->
      <LazyMount>
        <RiskCommentDialog
          :visible.sync="showRiskCommentTips"
          :language="language"
          @close-risk-comment-tips="closeRiskCommentTips"
        />
      </LazyMount>

      <!-- 地址编辑成功提示 -->
      <LazyMount>
        <commonTipsDialog
          :visible.sync="showBatchAddressMessage"
          :append-to-body="true"
          :close-on-click-modal="false"
          :show-close="false"
          :content="batchAddressMessage"
          :btn-type="['primary', 'H56PX']"
          :btn-text="language.SHEIN_KEY_PWA_15312"
          @ok="closeBatchAddressDialog"
        />
      </LazyMount>

      <UrgentPickUp
        ref="urgentPickUp"
        :language="language"
      />

      <!-- 签收提示 -->
      <LazyMount>
        <commonSelectiveTipsDialog
          :visible.sync="isShowOldSign"
          :title="htmlDecode(language.SHEIN_KEY_PWA_16447)"
          :primaryText="language.SHEIN_KEY_PWA_15252"
          :secondaryText="language.SHEIN_KEY_PWA_15253"
          @primary="toggleReturnItemSign('yes')"
          @secondary="
            type => {
              toggleReturnItemSign(type === 'btn' ? 'no' : 'close')
            }
          "
        />
      </LazyMount>

      <!-- 退货二次确认 -->
      <LazyMount>
        <ReturnConfirmPop
          :visible="isShowSign"
          :language="language"
          :order-info="orderInfo"
          :track-info="latestTrackInfo"
          :from="'order_list'"
          @close-confirm-pop="toggleReturnItemSign('close')"
          @confirm-return="toggleReturnItemSign('yes')"
        />
      </LazyMount>

      <!-- 退货时效过期 -->
      <LazyMount>
        <ReturnExpirePop
          :visible="showExpirePop"
          :language="language"
          :order-return-time="orderReturnTime"
          :order-info="orderInfo"
          @close-expire-pop="toggleShowOverTimeLimit(false)"
        />
      </LazyMount>

      <LazyMount>
        <commonTipsDialog
          :content="language.SHEIN_KEY_PWA_23073"
          isContentCenter
          :btnText="language.SHEIN_KEY_PWA_15312"
          :btnType="['primary']"
          :show-close="false"
          :visible.sync="showCombinedReturn"
        />
      </LazyMount>

      <OrderMaskDialog />
    </ClientOnly>
  </div>
</template>
<script>
/* globals saObj */
import Vue from 'vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { getQueryString, htmlDecode, setLocalStorage } from '@shein/common-function'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import paymentCbs from '../common/paymentCbs'
import './analysis/index'
import addressMixin from '../common/addressMixin'
import returnItemMixin from '../common/returnItemMixin'
import paymentMixin from '../common/paymentMixin'
import mixin from '../common/mixin'
import orderListStoreModule from '../../../../product_app/store/modules/user/order_list'
import orderDetailStoreModule from '../../../../product_app/store/modules/user/order_detail'
import cancelItemStoreModule from '../../../../product_app/store/modules/user/order-cancel-item'
import payInlineMixin from 'public/src/pages/checkout/mixins/payInlineMixin'
import checkoutMixin from 'public/src/pages/checkout/mixins/checkout-mixin'
import { isRelationBillno } from 'public/src/pages/pay_success/orderBillno'
import { getOrderDoublePoints, retainPayMethodLang, getOrderStatusText, getOrderButtonSorts } from './../utils'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { hasRobotEntrance } from 'public/src/pages/common/robotEntrance'
import orderLogic from 'public/src/pages/common/orderLogic'
import {
  Button,
  ButtonGroup,
  Dialog,
  ButtonGroupItem,
  Popover,
  Tab,
  TabItem,
  Feedback,
  TitleNav,
  TitleNavItem,
  NetworkErrorAlert
} from '@shein/sui-mobile'
import orderFactory from '../common/orderFactory.js'
import { webScrollExpose } from 'public/src/pages/common/analysis/ScrollExpose'
import schttp from 'public/src/services/schttp'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import { loadTdLibJs } from 'public/src/pages/common/RiskCommon/libs/BlackBox/index.js'
import { filterAttrValue, robotLinkCommon } from 'public/src/pages/common/utils/index.js'
import { markPoint } from 'public/src/services/mark/index.js'
import components from './components.js'
import { orderStatusTabs } from './constants.js'
import { userOrderDetailRouteComponent } from 'public/src/pages/product_app/router/user-order-router.js'

import { REPURCHASE_ORDER_STATUS } from 'public/src/pages/user/child_pages/orders/common/constants.js'
daEventCenter.addSubscriber({ modulecode: '1-20-1' })
daEventCenter.addSubscriber({ modulecode: '2-30' })
;[Button, Dialog, ButtonGroup, ButtonGroupItem, Popover, Tab, TabItem, Feedback, TitleNav, TitleNavItem, NetworkErrorAlert].forEach(c => Vue.use(c))

let initSsrDataPromise = null

let SaPageInfo = {
  page_id: 6,
  page_name: 'page_order_list',
  page_param: { order_count: '' },
  start_time: Date.now()
}

export default {
  name: 'UserOrdersList',
  components,
  directives: { tap, expose },
  mixins: [
    paymentCbs,
    addressMixin,
    returnItemMixin,
    paymentMixin,
    payInlineMixin,
    mixin,
    checkoutMixin,
  ],
  async asyncData({ store, context, route, from }) {
    if (!store.state['orderList']) {
      store.registerModule('orderList', orderListStoreModule)
    }
    if (!store.state['orderDetail']) {
      store.registerModule('orderDetail', orderDetailStoreModule)
    }
    if (!store.state['cancelItemModule']) {
      store.registerModule('cancelItemModule', cancelItemStoreModule)
    }
    // 从个人中心跳转
    if (from?.name === 'UserIndex') {
      store.commit('orderList/assignOrderListState', {
        orderSearchMsg: {
          content: '',
          show: false,
          isSearchList: false,
          page: 1
        }
      })
    }
    // reset
    store.commit('orderList/assignOrderListState', {
      orders: [],
      page: 1,
      status: {
        histtoryStatus: false,
        historyExtra: false,
        historyShow: false
      }
    })
    if (!store.state?.orderList?.orderSearchMsg?.isSearchList) {
      store.commit('orderList/assignOrderListState', {
        noMoreData: false
      })
    }
    // init status_type
    const tabMap = ['All', 'Unpaid', 'Processing', 'Shipped', 'Review', 'Returns']
    const tabParams = route?.params?.tab || ''
    // query
    const queryStatusType = Number(getQueryString({ key: 'status_type' }) || context?.status_type || 0)
    // params
    const paramStatueType = tabMap.indexOf(tabParams) > -1 ? tabMap.indexOf(tabParams) : 0

    // store sort
    const statusType = store.state.root.statusType || paramStatueType || queryStatusType || 0
    store.commit('root/assignRootState', {
      statusType
    })
    store.commit('root/updateIsErrorGuideOn', true)
    store.commit('orderList/assignOrderListState', {
      status_type: statusType
    })
    initSsrDataPromise = store.dispatch('orderList/initSsrData', {
      context,
      statusType,
      route,
      from
    })
    if (typeof window !== 'undefined') {
      try {
        sessionStorage.setItem('return_page_from', 'list')
      } catch (e) {
        // eslint-disable-next-line no-empty
      }
    }
  },
  data() {
    return {
      showFindOrderFlag: true,
      isShowUrgeShipmentDialog: false,
      showConfirmDialog: false,
      TRANSPORT_TYPE_SHOP,
      orderStatusTabs,
      timeTips: '',
      isShowTimeTipsDialog: false,
      addCartOrderNo: false,
      pageold: 1,
      refundMask: false,
      refundOrder: {},
      msgBox: {
        info: '',
        type: ''
      },
      inReturnItem: {
        billno: '',
        mask: false
      },

      cancelForm: {
        billno: '',
        payment: '',
        reason: '',
        type: 1,
        show: false,
        order: {},
        status: '',
        relatedOrders: []
      },
      pointsTipShow: true,
      orderSwiper: '',
      bothubEntryShow: false,
      bothubSubSuccTipShow: false,
      encodeMemberId: '',
      showConfirmDeliveryTips: false,
      confirmDeliveryGoodsInfo: [],
      orderDeliveryForm: {
        points: 0,
        isDoublePoints: false,
        confirmDeliveryPoints: 0,
        doublePoints: 0,
        billno: '',
        isCanComment: 0,
        siteFrom: '',
        isFreeTrial: false,
        uploadTryStatus: {}
      },
      confirmDeliverAlter: false,
      orderTmp: '',
      confirmDeliverAlterTips: '',
      confirmDeliverMessage: false,
      confirmDeliverUpload: false,
      confirmDeliverMessageText: '',
      orderDeliverTipsBool: false,
      orderDeliverTips: '',
      confirmDeliverMessagePoints: 0,
      isDoublePointsOrder: false,
      codOrderIsNotSupportReceipt: false, // cod订单是否不支持确认收货
      codOrderIsNotSupportReceiptTip: '',
      refundNotCodMask: false,
      refundNotCodMaskLoading: false,
      showMaskTipsbool: false,
      orderInfo: {},
      moduleName: 'orderList',
      module_name: 'OrderList', // 模块名称
      robotShow: false,
      orderDeliveryPointCheckTips: {
        showModal: false,
        text: '',
        points: 0
      },
      isPointCheckModle: false,
      showDeleteHistoryPopover: false,
      repurchaseModel: {
        show: false
      },
      repurchaseOrderBillno: '',
      successPanelData: {
        show: false,
        billno: ''
      },
      combinedOrderModal: {
        show: false,
        billno: '',
        merge_buy_billno: '',
        tips: ''
      },
      codCancelItemTipVisible: false,
      codCancelItemVisible: false,
      orderFullInfo: {},
      fullLanguage: {},
      cashPayMethod: ['adyen-konbini', 'adyen-multibanco'],
      showFrontConditionDialog: false,
      showFrontConditionText: '',
      showRelationDialog: false,
      relationGoodsList: [],
      relationOptions: {
        type: '',
        url: '',
        billno: '',
        cancelInfo: {},
        operatorType: 'full'
      },
      visibleCardBinDiscountArticle: false,
      showBlackTips: {
        show: false,
        text: ''
      },
      showUrgeDeliveryModal: false,
      showExchangeTipsPop: false,
      disableExchangeTips: '',
      now_address: {},
      urgeshipmentText: {
        content: '',
        title: ''
      },
      showRiskCommentTips: false,
      currentCommentOrder: '',
      riskCommentArr: [],
      loadingMore: false,
      hasMounted: false,
      addCartStatus: {
        showDrawer: false,
        cartInfo: {},
      },
      showCombinedReturn: false
    }
  },
  computed: {
    ...mapState(['unReadChatNum']),
    ...mapState('root', ['statusType']),
    ...mapState('orderList', [
      'language', 'orderSearchMsg', 'status_type', 'userOrderCounts', 'pageLoading', 'orderListNotice', 'relationAccount',
      'orderStatusTextList', 'shouldGiftCardShow', 'historyOrders', 'abtInfos', 'unionOrders', 'isDemotion', 'orders',
      'page', 'reviewRewards', 'status', 'noMoreData', 'extraInfo', 'apolloInfo', 'isSsr', 'publicLocalData'
    ]),
    ...mapGetters('orderList', ['ordersStatus']),
    ...mapState('orderDetail', [
      'originPaymentMethod',
      'paymentMethod',
      'payments',
      'pageStatus',
      'selectedIdealCode',
      'applePayShow',
      'paymentOption',
      'binDiscountInfo',
      'binDiscountList'
    ]),
    ...mapGetters('orderDetail', ['getCodOrderFrom']),
    ...mapState('orderList/bankSelect', ['bankDrawerConfig', 'banksConfig', 'capitecDialog']),
    // 订单列表回购显示利诱
    showNewRepurchase() {
      const { PromosPosition = '' } = this.abtInfos.orderRepurchaseParam || {}
      return Boolean(PromosPosition?.includes('list'))
    },
    // 订单列表回购成功显示新的成功页
    showNewRepurchaseSuccess() {
      const { NewRepurchase } = this.abtInfos.orderRepurchaseParam || {}
      return NewRepurchase === 'on'
    },
    renderOrders () {
      if (!this.orders?.length) {
        return []
      }
      return this.hasMounted ? this.orders : this.orders.slice(0, 3)
    },
    isShowFindOrder () {
      return ((this.abtInfos?.orderlistRelatedIconAbt == 'on' && this.relationAccount) || this.abtInfos?.findOrderAbt?.FindOrderSwitch == 'on') && this.showFindOrderFlag
    },
    publicLangPath () {
      return this.publicLocalData?.langPath || ''
    },
    IS_RW () {
      return this.publicLocalData?.WEB_CLIENT == 'romwe'
    },
    isOrderList() {
      return this.$route.name === 'UserOrdersList'
    },
    waitSendReturnOrder () {
      return this.extraInfo?.waitSendReturnOrder || []
    },
    // 订单列表开启利诱点信息实验
    showOrderListPromotionTest() {
      return ['on1', 'on2'].includes(this.abtInfos.unpaidOrderTestAbt.OrderlistPromotionTest)
    },
    popoverStyle() {
      return this.publicLocalData.GB_cssRight ? { left: '0.32rem', right: 'auto' } : { right: '0.32rem', left: 'auto' }
    },
    exchangeLink() {
      const { SiteUID, appLanguage } = this.publicLocalData || {}
      return (
        this.apolloInfo?.RESALE_SITE_LINK?.filter(item => item.siteUid === SiteUID)?.[0]?.url ||
        `${this.publicLangPath}/exchange/${appLanguage}/resale-list`
      )
    },
    matchedBinDiscountInfo() {
      if (!this.abtInfos.isShowCardBinDiscountABT && this.binDiscountList.length > 1) {
        return this.binDiscountList
      }
      if (!this.abtInfos.isShowCardBinDiscountABT || !this.binDiscountList[0]?.discount_type) return []
      return this.binDiscountList
    },
    showUrgeDeliveryBtn() {
      return order => {
        return order.orderStatus == 10 && this.abtInfos.isShowUrgeDeliveryAbt && order.isDisplayDelivery
      }
    },
    newEditAddressOptions () {
      return {
        batchModifyAddress: this.abtInfos.batchModifyAddressAbt,
        pageSource: 'OrderList',
        btnSource: 'Edit'
      }
    },
    showMyPackage() {
      const { OrderThresholdValue, MyPackageSwitch = '' } = this.abtInfos.myPackageAbt || {}
      const { after_sale, processing_order, waiting_receive } = this.userOrderCounts || {}
      const userOrderTotal = after_sale + processing_order + waiting_receive

      return MyPackageSwitch == 'on'
        && !!OrderThresholdValue
        && userOrderTotal <= OrderThresholdValue
    },
    showRefundNew() {
      return this.abtInfos.abTestConfig['refundNew'] !== 'off'
    },
    waitSendTotal() {
      if (!Array.isArray(this.waitSendReturnOrder)) return 0
      const uniqueList = new Set(this.waitSendReturnOrder.map(i => i?.union_no))
      return uniqueList.size
    },
    emptyConfig() {
      let iconName = this.orderSearchMsg.isSearchList ? 'sui_icon_empty_75px_1' : 'sui_icon_empty_list_75px_color'
      let title = ''
      let content = ''
      let buttonText = ''
      let buttonNumber = 0

      if (this.orderSearchMsg.isSearchList) {
        content = `${this.language.SHEIN_KEY_PWA_16115} :-(`
      } else if (this.status_type || !this.status.showHistory) {
        content = `${this.language.SHEIN_KEY_PWA_15043} :-(`
      }
    
      if (this.isDemotion) {
        title = content
        content = this.language.SHEIN_KEY_PWA_17808
      }

      if (!this.orderSearchMsg.isSearchList && !this.status_type && this.status.showHistory) {
        buttonNumber = 1
        buttonText = this.language?.SHEIN_KEY_PWA_16184?.toUpperCase?.()
      }

      return {
        title,
        content,
        buttonText,
        iconName,
        buttonNumber
      }
    },
    applePayCodeList () {
      return this.apolloInfo?.APPLE_PAY_CODE_LIST || []
    },
    latestTrackInfo() {
      const { trackList = [], order_package_info_list = [], orderGoodsList = [] } = this.orderInfo

      // 未签收的包裹号
      const unsignedPackage = order_package_info_list
        ?.filter(item => !item?.signed_time)
        ?.map(item => item?.packageNo) || []


      // 满足 未签收包裹 && 包裹中包含特殊商品状态
      let filteredTrack = trackList.filter(item =>
        unsignedPackage.includes(item.package_no) && this.existUnsignSpecialGoods(orderGoodsList, item.package_no)
      )

      // 找到时间戳最大的对象
      let latestNotReceived = filteredTrack.reduce((latest, current) => {
        if (!latest) return current
        return (current.timestamp > latest.timestamp) ? current : latest
      }, null)

      return latestNotReceived
    },
    returnTime() {
      return orderLogic.getReturnTimeInfo({
        language: this.language,
        orderInfo: this.orderInfo,
        localData: this.publicLocalData,
        from: 'list'
      })
    },
    orderReturnTime() {
      return this.returnTime?.orderReturnTime || {}
    }
  },
  created() {
    this.setNowCheckoutScene('OrderList')
  },
  mounted () {
    window.orderListDom = this
    setTimeout(() => {
      loadTdLibJs()
      userOrderDetailRouteComponent()
    }, 200)
    this.getCsChatMsgNumFirst()
    this.handleMethod()
    this.setReturnMixinAbt(this.abtInfos)
  },
  beforeRouteEnter(to, from, next) {
    if (typeof window === 'undefined') {
      return next(() => {})
    }

    window.SaPageInfo = { ...SaPageInfo, page_param: { ...(SaPageInfo.page_param || {}), order_count: '' } }
    appEventCenter.$emit('pageOnload')
    next(vm => {
      hasRobotEntrance('orderListPage', status => {
        vm.robotShow = status
      })
      vm.setStatusLoading(false)
      if (window.temproryHidePackagePop && vm.$refs.myPackageEntranceRef) {
        vm.$refs.myPackageEntranceRef?.temporaryShow()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    window.SaPageInfo = SaPageInfo
    $(`[el-viewed='true']`).each((i, element) => {
      $(element).attr('DA-view-type', 'ordinary')
    })
    if (window.temproryHidePackagePop && this.$refs.myPackageEntranceRef) {
      this.$refs.myPackageEntranceRef?.temporaryHide()
    }
    next()
  },
  updated() {
    this.$nextTick(() => {
      $(window).scroll() // 触发曝光埋点
    })
  },
  async activated() {
    // 只在客户端 再次进入时触发埋点
    this.hasMounted = true
    await Promise.resolve(initSsrDataPromise)
    this.$nextTick(() => {
      markPoint('setPageDataNextTick', 'setPageData')
    })
    const { order_count = '' } = SaPageInfo?.page_param || {}
    if (order_count) {
      window.SaPageInfo = {
        page_id: 6,
        page_name: 'page_order_list',
        page_param: { order_count: order_count },
        start_time: Date.now()
      }
      appEventCenter.$emit('pageOnload')
      $(window).scroll() // 触发曝光埋点
    }
    // 页面请求
    await this.getPageData()

    // 只在客户端
    if (this.abtInfos.isShowOrderResellEntrance) {
      daEventCenter.triggerNotice({
        daId: '1-20-1-56'
      })
    }
    this.showDeleteHistoryPopoverHandle()
    this.handleExposeAnalysis()
  },
  methods: {
    ...mapMutations('orderList', [
      'assignLanguage', 'assignOrderListState', 'updateByStatusOrderList', 'updateAllOrderList',
      'updateHistoryOrderList'
    ]),
    ...mapMutations('orderDetail', [
      'assignState',
      'handleEditPayment',
      'handleChangePayment',
      'setOriginPaymentMethod',
      'changeParamsStatus'
    ]),
    ...mapMutations(['changeRootStatus']),
    ...mapMutations('root', ['showMaskTips', 'assignRootState', 'changeUserRootSLoadingStatus', 'changeHeaderNav', 'updateHasGuide']),
    ...mapMutations('cancelItemModule', ['assignCancelItemState']),
    ...mapMutations('orderList/bankSelect', ['updateBanksConfig', 'updateBankDrawerConfig', 'updateCapitecDialog']),
    ...mapActions(['getCsChatMsgNumFirst']),
    ...mapActions('orderList/bankSelect', ['handleBankSelect', 'handleStartSelectBank', 'handleCapitecDialogEvent']),
    filterAttrValue,
    isReturnableInfo: orderLogic.isReturnableInfo,
    isShowRepurchaseBtn: orderLogic.isShowRepurchaseBtn,
    isViewInvoices: orderLogic.isViewInvoices,
    isCanRevokeByUser: orderLogic.isCanRevokeByUser,
    disabledRevokeByUser: orderLogic.disabledRevokeByUser,
    disabledEditAddressBtn: orderLogic.disabledEditAddressBtn,
    robotLinkCommon: robotLinkCommon,
    getQueryString,
    htmlDecode: text => htmlDecode({ text }),
    toRepurchaseEvt(order = {}, analysisData = {}) {
      this.orderInfo = order
      if (this.showNewRepurchaseSuccess) {
        this.handleRepurchase(order.billno)
      } else {
        this.repurchaseModel.show = true
        this.repurchaseOrderBillno = order.billno
      }
      daEventCenter.triggerNotice({
        daId: '1-20-1-142',
        extraData: analysisData
      })
    },
    toRecycle () {
      this.$router.push(this.publicLangPath + '/user/orders/recycle')
    },
    // 已支付非cod取消按钮是否显示
    isCommonShowCancelButton(order) {
      return orderLogic.isCommonShowCancelButton({
        type: 'cancel_order',
        order: order,
        showRefundNew: this.showRefundNew,
      })
    },
    // 已支付非cod取消按钮是否禁用
    disabledCancelButton(order) {
      return orderLogic.disabledCancelButton({
        type: 'cancel_order',
        order: order,
        showRefundNew: this.showRefundNew,
      })
    },
    // 已支付非cod取消按钮abt是否开启
    isEnableCancelAbt(){
      return orderLogic.isEnableCancelAbt({
        type: 'cancel_order',
        abTestConfig: this.abtInfos.abTestConfig,
        showRefundNew: this.showRefundNew,
      })
    },
    //关联订单确认取消
    confirmCancelOrder() {
      this.showRelationDialog = false
      this.handleShowCancelItemSelect()
    },
    // 进入cancel_item弹窗
    handleShowCancelItemSelect() {
      this.refundNotCodMask = false
      this.changeRootStatus({ loading: true })
      this.fetchOrderInfo()
    },
    jumpToRobot() {
      let billno = this.orders?.[0]?.billno || ''
      let paramsStr = billno ? `&billno=${billno}` : ''
      return this.robotLinkCommon('orderListPage', paramsStr)
    },
    isShowUrgeshipment(order) {
      const { orderStatus = '', isUrgeShipExpedite = false } = order || {}
      if (![1, 4, 6].includes(+orderStatus) || !this.abtInfos.isShowUrgeshipmentAbt) return false

      return !!isUrgeShipExpedite
    },
    showEditAddressLink(order = {}) {
      if (!this.showOrderListPromotionTest) return false
      return [0, 12].includes(+order.orderStatus)
    },
    isShowSelfExchange(order) {
      const { orderStatus = '', can_exchange } = order
      if (orderStatus != 4 || !this.abtInfos.isShowExchangeAbt) return false

      return can_exchange != 0
    },
    getSelfExchangeStatus(order, orderBtnMore) {
      const { can_exchange } = order
      const btnMoreList = orderBtnMore?.btnMoreList || []
      const exchangBtn = btnMoreList?.find(item => item?.el?.[0]?.name == 'exchange')

      if (btnMoreList.length > 0 && exchangBtn) {
        if (can_exchange == 2 && exchangBtn) {
          exchangBtn.addClass = 'opacity-item'
        }
      }

      return can_exchange
    },
    async urgeshipment(order) {
      const { billno = '', isExistUrgeShipWork } = order
      daEventCenter.triggerNotice({
        daId: '1-20-1-85',
        extraData: {
          order_no: billno
        }
      })

      try {
        const cccText = orderLogic.getOrderStatusTextByOrderList(this.orderStatusTextList, {
          ...order,
          order_or_package_status: order.order_detail_status
        })

        if (isExistUrgeShipWork) {
          this.urgeshipmentText.title = this.language.SHEIN_KEY_PWA_26380
          this.urgeshipmentText.content = cccText?.orderNotFirstDeliveryText || this.language.SHEIN_KEY_PWA_26381
        } else {
          this.setStatusLoading(true)

          const res = await schttp({
            method: 'POST',
            url: '/api/orders/base/submitUrgeShipInfo',
            data: {
              billno
            }
          })

          if (res?.code == 0) {
            Object.assign(order, { isExistUrgeShipWork: true })
            this.setStatusLoading(false)
          } else {
            this.setStatusLoading(false)
            this.$toast(this.language.SHEIN_KEY_PWA_26383)
            return
          }

          this.urgeshipmentText.title = this.language.SHEIN_KEY_PWA_26378
          this.urgeshipmentText.content = cccText?.orderFirstDeliveryText || this.language.SHEIN_KEY_PWA_26379
        }

        this.isShowUrgeShipmentDialog = true
      } catch (e) {
        console.error(e)
      }
    },
    handleExchangeOperation(order) {
      const { billno = '', can_exchange, exchange_code } = order

      if (can_exchange == 2) {
        // 换货提示弹窗
        this.disableExchangeTips = this.getDisableExchangeTips(order)
        this.showExchangeTipsPop = true
        daEventCenter.triggerNotice({
          daId: '1-20-1-99',
          extraData: {
            order_no: billno,
            reason: exchange_code
          }
        })
        return
      }
      this.$router.push({
        path: `${this.publicLangPath}/orders/selfExchange?billno=${billno}`
      })
    },
    getDisableExchangeTips(order) {
      const { exchange_code } = order
      /**
       * 0: 商品状态不满足（兜底态）
       * 3: 换货次数校验不符
       */
      const ReasonType = {
        DEFAULT: 0,
        MAX_LIMIT: 3,
      }
      const ResonLanguageMap = {
        [ReasonType.DEFAULT]: 'SHEIN_KEY_PWA_29071',
        [ReasonType.MAX_LIMIT]: 'SHEIN_KEY_PWA_29070',
      }
      const langKey = ResonLanguageMap[exchange_code] || ResonLanguageMap[ReasonType.DEFAULT]

      return this.language[langKey]
    },
    needConfirmDialog() {
      return (
        this.orderInfo?.address_id && this.abtInfos?.syncToUseraddressAbt?.is_sync_switch === 'on'
      )
    },
    confirmDialogHandle() {
      daEventCenter.triggerNotice({
        daId: '2-30-1',
        extraData: {
          name: 'expose_sync_to_useraddress_pop'
        }
      })
      this.showConfirmDialog = true
    },
    toDetailRecommendEvt(order = {}) {
      const { billno = '' } = order || {}
      window.location.href = `${this.publicLangPath}/user/orders/detail/${billno}?recommend=1`
    },
    // 判断是否展示barcode逻辑
    isBarcode(order) {
      const {
        _selfTime,
        order_expire_time,
        expireCountdown,
        orderStatus,
        pay_code_url,
        pay_url,
        payment_type,
      } = order
      const isOrderStatus = orderStatus == 0 || orderStatus == 12
      const isMathod = payment_type == 2
      const isPayUrl = !!pay_code_url || !!pay_url
      const barcodeAbtResult = this.abtInfos.orderListAbtInfo.includes('barcode=on') ? true : false
      const isTime = Number(_selfTime) < Number(order_expire_time || expireCountdown)
      const result = barcodeAbtResult && isOrderStatus && isMathod && isPayUrl && isTime
      return result
    },
    async getPageData () {
      this.getOrderStatusTextLists()
      // abt addressMixin 文件在使用
      this.isCodAddressEditEnable = this.abtInfos.isCodAddressEditEnable
      this.isInfoFrontAbt = this.abtInfos.isInfoFrontAbt
      this.isCanRefundAbt = this.abtInfos.isCanRefundAbt
      this.batchModifyAddressAbt = this.abtInfos.batchModifyAddressAbt
      this.showEditOrderAddress = this.abtInfos.showEditOrderAddress
      this.pageold = 1
      // 订单数据
      if (this.status_type) {
        this.updateByStatusOrderStatusEvt(this.unionOrders)
      } else {
        this.updateAllOrderStatusEvt(this.unionOrders)
      }
    },
    async getOrderStatusTextLists () {
      if (!this.orderStatusTextList?.length) {
        const orderStatusText = await getOrderStatusText()
        this.assignOrderListState({
          orderStatusTextList: orderStatusText?.length ? orderStatusText : [],
        })
      }
    },
    showExpireCountDown(order = {}) {
      const { orderStatus, order_expire_time, expireCountdown, _selfTime } = order || {}
      const isRangeTime = Number(_selfTime) < Number(order_expire_time || expireCountdown)
      return (
        [0, 12, 13].includes(orderStatus) &&
        this.abtInfos.orderListAbtInfo.includes('paytime=on') &&
        isRangeTime
      )
    },
    orderBusiModel(order = {}) {
      const tempGoodsList = order?.orderGoodsList?.flat(Infinity) || []
      const busiModelList = tempGoodsList?.map(list => +list.business_model)
      if (busiModelList.includes(1) && busiModelList.includes(0)) return 3
      if (busiModelList.includes(1)) return 2
      return 1
    },
    orderBuyTpInfo(order) {
      const tempGoodsList = order?.orderGoodsList?.flat(Infinity) || []
      return orderLogic.getBuyTpInfo(order, tempGoodsList)
    },
    orderInducementType(payNowLabelList) {
      if (!Array.isArray(payNowLabelList) || !payNowLabelList.length) return ''
      const labelMap = {
        1: 'save',
        2: 'freeShipping',
        3: 'freeReturn',
        4: 'priceGuarantee'
      }
      const labelList = payNowLabelList.map(item => {
        return labelMap[item.inducementType] || ''
      })
      return labelList.join(',') 
    },
    // 判断是否满足面单前置条件
    overFrontCondition(order = {}) {
      if (!this.abtInfos.isInfoFrontAbt || !orderLogic.isWillShipOrder(order)) return 0
      return orderLogic.existFrontFlag(order)
    },
    // 判断是否满足B2B2C模式订单
    overB2B2CCondition(order = {}) {
      const cond = orderLogic.orderListB2B2CCond(order, this.abtInfos.isCanRefundAbt)
      return !!cond
    },
    // 判断是否满足待发货订单支持修改地址
    overB2B2CEditAddress(order = {}) {
      return orderLogic.orderDetailEditAddressCond(order, this.abtInfos.isCanRefundAbt)
    },
    validUpdateAddress(order = {}) {
      this.orderInfo = order
      if (this.disabledEditAddressBtn(order)) {
        this.showFrontConditionText = this.htmlDecode(this.language.SHEIN_KEY_PWA_21411)
        this.showFrontConditionDialog = true
        return
      }
      if (this.overFrontCondition(order) > 0) {
        daEventCenter.triggerNotice({
          daId: '1-20-1-44',
          extraData: {
            operation_from: 3
          }
        })
        this.showFrontConditionText = this.htmlDecode(this.language.SHEIN_KEY_PWA_20123)
        this.showFrontConditionDialog = true
        return
      }
      if (this.overB2B2CEditAddress(order)) {
        this.showFrontConditionText = this.htmlDecode(this.language.SHEIN_KEY_PWA_21411)
        this.showFrontConditionDialog = true
        return
      }
      this.updateOrderInfo(order.billno)
    },
    navToDetail(billno) {
      daEventCenter.triggerNotice({
        daId: '1-20-1-31',
        extraData: {
          billno: billno
        }
      })
      this.combinedOrderModal.show = false
      this.$router.push(`${this.publicLangPath}/user/orders/detail/${billno}`)
    },
    paypalGaVaultBi(type, extraData = {}) {
      const ids = {
        expose_paypal_vaulting: '1-20-1-40',
        click_paypalvaulting_choose: '1-20-1-41',
        click_popup_vaulting_changeconfirm: '1-20-1-42'
      }
      if (!ids[type]) return
      daEventCenter.triggerNotice({
        daId: ids[type],
        extraData
      })
    },
    // 合单状态是否为混发弹窗
    showCombinedStatus({ billno, status, merge_buy_billno }) {
      this.combinedOrderModal.show = true
      this.combinedOrderModal.billno = billno
      this.combinedOrderModal.merge_buy_billno = merge_buy_billno
      this.combinedOrderModal.tips = status ? this.language.SHEIN_KEY_PWA_14588 : this.language.SHEIN_KEY_PWA_14587
    },
    getConfirmOverTime(item) {
      if (!this.abtInfos.timeCanDelivery) return ''
      const { delivery_time = 0, confirm_receipt_time = 0 } = item
      if (
        !delivery_time ||
        delivery_time == '0' ||
        !confirm_receipt_time ||
        confirm_receipt_time == '0'
      ) {
        return ''
      }
      // 获取当前秒时间戳
      const secondsSteps = parseInt(Date.parse(new Date()).toString().substr(0, 10))
      const confirmOverTime = (secondsSteps - delivery_time) / 86400 // 发货时长
      return confirmOverTime > confirm_receipt_time ? this.getTimeTips(confirm_receipt_time) : ''
    },
    // 超过确认收货时长
    getConfirmOver(item) {
      const { delivery_time = 0, confirm_receipt_time = 0 } = item
      if (delivery_time && confirm_receipt_time) {
        // 获取当前秒时间戳
        const nowTime = Math.floor(new Date().getTime() / 1000)
        // 发货时长
        const confirmOverTime = (nowTime - delivery_time) / 86400
        // 如果发货时长大于确认收货时长，漏出删除按钮
        return confirmOverTime > confirm_receipt_time
      }

      return false
    },
    // 获取超时文案
    getTimeTips(days) {
      const months = Math.floor(days / 30)
      if (months <= 12) {
        return this.language.SHEIN_KEY_PWA_19679.replace('{0}', months <= 1 ? 1 : months).replace(
          '{1}',
          months <= 1 ? this.language.SHEIN_KEY_PWA_19705 : this.language.SHEIN_KEY_PWA_19723
        )
      } else {
        const years = Math.floor(months / 12)
        return this.language.SHEIN_KEY_PWA_19679.replace('{0}', years <= 1 ? 1 : years).replace(
          '{1}',
          years <= 1 ? this.language.SHEIN_KEY_PWA_19700 : this.language.SHEIN_KEY_PWA_19701
        )
      }
    },
    /** End */
    // cod取消商品
    async toRevokeOrder(order) {
      if (this.disabledRevokeByUser(order)) {
        this.showFrontConditionText = this.language.SHEIN_KEY_PWA_20121
        this.showFrontConditionDialog = true
        return
      }
      if (this.overFrontCondition(order) > 0) {
        daEventCenter.triggerNotice({
          daId: '1-20-1-44',
          extraData: {
            operation_from: 0
          }
        })
        this.showFrontConditionText = this.htmlDecode(this.language.SHEIN_KEY_PWA_20122)
        this.showFrontConditionDialog = true
        return
      }
      if (this.overB2B2CCondition(order)) {
        let { order_detail_status = '' } = order || {}
        let textMap = {
          1: this.language.SHEIN_KEY_PWA_21408,
          2: this.language.SHEIN_KEY_PWA_21410,
          3: this.language.SHEIN_KEY_PWA_21409
        }
        this.showFrontConditionText = textMap[order_detail_status]
        this.showFrontConditionDialog = true
        return
      }
      daEventCenter.triggerNotice({
        daId: '1-20-1-27',
        extraData: {
          billno: order.billno
        }
      })
      if (this.abtInfos.abTestConfig['Revoke'] === 'on') {
        this.toggleUnavailableModal()
        return
      }

      // cod订单 - 如果ABT实验CodRefundNew=on，且订单状态为4-处理中，则跳转到COD部分退页面，执行COD部分退流程
      // 否则依然是执行整单退逻辑
      const useCodRefundNew = [4, 6].includes(Number(order?.orderStatus))
      if (!useCodRefundNew) {
        this.handelCacncelBtnClick(order, 2)
        return
      }

      // 现金支付
      const { shipping_country_name, shipping_country_id } = order || {}

      // 逆向操作限制主题站点
      const result = await this.checkSiteFn(order)
      if (result != 1) {
        return this.jumpMask({
          url: '/user/orders/list',
          result,
          shipping_country_name,
          shipping_country_id
        })
      }
      this.orderInfo = order
      this.codCancelItemTipVisible = true
    },
    isShowEditAddressBtn(order) {
      const isCod = order?.payment_method === 'cod'
      const status = this.editAddressFlag(order) == 1
      if (isCod) {
        return status
      }
      return status && this.abtInfos.orderListAbtInfo.indexOf('address=on') > -1
    },
    handleMethod: function () {
      this.handleSave = orderFactory.createOrderToCart(this, 'order_list')
    },
    handleRepurchase: async function (billno) {
      const successTips = false
      this.handleSave(
        billno,
        res => {
          if (this.showNewRepurchaseSuccess && res?.code == '0') {
            this.addCartStatus.showDrawer = true
            this.addCartStatus.cartInfo = {
              successInfoList: res.info?.success_info_list || [],
              falidInfoList: res.info?.falid_info_list || [],
              repurchaseInfo: this.orderInfo.repurchase_lure_info
            }
          } else if (res?.code == '0' && !successTips) {
            this.successPanelData.show = true
            this.successPanelData.billno = billno
          }
        },
        '',
        { successTips }
      )
    },
    // 封装公共的支付方法 start
    payNowCommonEvt(order = {}, options = {}) {
      let { payType, confirmPayType } = options || {}
      if (payType == 1) {
        this.jumpPayNowEvt(order)
      } else {
        this.confirmPayNowEvt(order, { confirmPayType })
      }
    },
    jumpPayNowEvt(order = {}) {
      let {
        pay_code_url,
        order_expire_time,
        expireCountdown,
        payment_type,
        payment_method,
        pay_url
      } = order || {}
      let expireTime = order_expire_time || expireCountdown
      let curTime = Date.parse(new Date()) / 1000
      if (+payment_type == 2 && curTime >= expireTime) {
        this.toggleOrderExpiredModal({ order })
        return
      }
      if (this.cashPayMethod.includes(payment_method)) {
        this.paymentCbs(order)[payment_method].cb()
        return
      }
      if(['routepay-konbini'].includes(payment_method)){
        this.getPayments(order, true)
        return
      }
      location.href = pay_code_url || pay_url
    },
    async confirmPayNowEvt(order = {}, options = {}) {
      let {
        pay_code_url,
        pay_code,
        payment_type,
        shipping_country_name,
        shipping_country_id,
        order_expire_time,
        expireCountdown,
        payment_method
      } = order || {}
      let { confirmPayType } = options || {}
      let isBarcodeOrder = this.isBarcode(order)

      if (isBarcodeOrder && confirmPayType == 1) {
        location.href = pay_code_url || pay_code || ''
        return
      }

      const result = await this.checkSiteFn(order)
      if (result != 1) {
        return this.jumpMask({
          url: '/user/orders/list',
          result,
          shipping_country_name,
          shipping_country_id
        })
      }

      if (+payment_type == 2) {
        let expireTime = order_expire_time || expireCountdown
        let curTime = Date.parse(new Date()) / 1000
        if (curTime >= expireTime) {
          this.toggleOrderExpiredModal({ order })
          return
        }
      }

      if (payment_method == 'cod') {
        this.paymentCbs(order)['cod'].cb()
      } else {
        this.getPayments(order, true)
      }
    },
    // 封装公共的支付方法 end
    showConfirmDeliverTips: async function (order) {
      let { confirmDeliveryBonusPoints: num } = order
      const orderTmp = await getOrderDoublePoints(order.billno)
      const { doublePoints = 0 } = orderTmp
      let isDoublePointsOrder = false
      if (doublePoints) {
        num = `${parseInt(num + doublePoints)}(${num}+${doublePoints})`
        isDoublePointsOrder = true
      }
      if (isDoublePointsOrder) {
        this.orderDeliverTips = this.language.SHEIN_KEY_PWA_17011.replace(
          '[num]',
          `<span class="red">${num}</span>`
        )
      } else {
        this.orderDeliverTips = this.language.SHEIN_KEY_PWA_17011.replace('[num]', num)
      }
      this.orderDeliverTipsBool = true
    },
    getReportInfo(order) {
      if (!order) {
        return {}
      }
      const [orderData = {}] = order?.orderGoodsList?.flat(Infinity) || []
      const info = {
        goods_img: orderData?.product?.goods_img,
        goods_name: orderData?.product?.goods_name,
        goods_sn: orderData?.product?.goods_sn,
        size_value: orderData?.goods_attr?.split(':')[1],
        attr_language_value: this.filterAttrValue(orderData?.sku_sale_attr, 'attr_value_name')
      }
      return info
    },
    async confirmDeliverAlterFun(order, num) {
      const timeTips = this.getConfirmOverTime(order)
      if (timeTips) {
        this.timeTips = timeTips
        this.isShowTimeTipsDialog = true
        daEventCenter.triggerNotice({
          daId: '1-20-1-43',
          extraData: {
            billno: order.billno
          }
        })
        return
      }

      if (this.abtInfos.abTestConfig['ConfirmDelivery'] === 'on') {
        this.toggleUnavailableModal()
        return
      }
      this.orderTmp = await getOrderDoublePoints(order.billno)
      const { doublePoints = 0 } = this.orderTmp
      this.orderDeliveryForm.points = num
      this.orderDeliveryForm.confirmDeliveryPoints = num
      this.orderDeliveryForm.doublePoints = doublePoints
      this.orderDeliveryForm.billno = order.billno
      this.orderDeliveryForm.siteFrom = order.site_from
      this.orderDeliveryForm.isFreeTrial = order.marketing_type == 1
      this.orderDeliveryForm.uploadTryStatus = order.uploadTryStatus
      if (doublePoints) {
        num = `${parseInt(num + doublePoints)}(${num}+${doublePoints})`
        this.orderDeliveryForm.isDoublePoints = true
      } else {
        this.orderDeliveryForm.isDoublePoints = false
      }
      if (order.payment_method == 'cod') {
        this.checkCodOrderIsSupportReceipt(order, num)
      } else {
        this.checkNoCodOrderIsSupportReceipt(order, num)
      }
    },
    async checkCommonEvt(order, num, type) {
      this.confirmDeliveryGoodsInfo = []
      this.showConfirmDeliveryTips = true
      this.orderDeliveryForm.points = num
      this.confirmDeliverAlterTips =
        type == 1 ? this.language.SHEIN_KEY_PWA_17008 : this.language.SHEIN_KEY_PWA_17009

      const orderDetailInfo = await schttp({
        url: `/user/orders/detail/${order.billno}`,
        schttp: { needLogin: true }
      })
      const allOrderGoodsList = orderDetailInfo?.info?.order?._allOrderGoodsList || []
      if (allOrderGoodsList.length) {
        this.confirmDeliveryGoodsInfo = allOrderGoodsList
      }
      daEventCenter.triggerNotice({
        daId: '1-20-1-12'
      })
    },
    // 检查非cod订单是否确认收货
    checkNoCodOrderIsSupportReceipt(order, num) {
      const subOrderStatus = order.subOrderStatus || []
      const excludeCodeList = [5, 7, 82, 75, 20, 126, 100, 127, 101, 18, 54]
      const notAllOrderHasDelivery = []
      subOrderStatus.forEach(item => {
        const goodsList = item.goodsList || []
        goodsList.forEach(good => {
          if (!excludeCodeList.includes(+good.goods_status)) {
            notAllOrderHasDelivery.push(good)
          }
        })
      })
      // 存在没有收货商品 提示文案有所不同
      const type = notAllOrderHasDelivery.length ? 2 : 1
      this.checkCommonEvt(order, num, type)
    },
    // 检查cod订单是否确认收货
    async checkCodOrderIsSupportReceipt(order, num) {
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/base/checkOrderIsSupportReceipt/get',
        data: { billno: order.billno },
        schttp: { needLogin: true }
      })
      if (res.code == 0 && res.info && res.info) {
        if (res.info.confirmable_receipt) {
          this.checkCommonEvt(order, num, 1)
        } else {
          this.codOrderIsNotSupportReceiptTip = !this.IS_RW
            ? this.language.SHEIN_KEY_PWA_16583.replace('[num]', num)
            : this.language.SHEIN_KEY_PWA_16929
          this.codOrderIsNotSupportReceipt = true
          sa('send', {
            activity_name: 'expose_popup_unconfirm',
            activity_param: {}
          })
        }
      } else {
        this.errorCommonEvt(res)
      }
    },
    errorCommonEvt: function (res) {
      this.showMsg({
        type: 'err',
        msg: res && res.code == 300542 ? res.tips : this.language.SHEIN_KEY_PWA_15948,
        timer: 2000
      })
      sa('send', {
        activity_name: 'expose_popup_confirm_error',
        activity_param: {}
      })
    },
    codOrderIsNotSupportReceiptBiGA: function () {
      this.codOrderIsNotSupportReceipt = false
      sa('send', {
        activity_name: 'click_popup_unconfirm_ok',
        activity_param: {}
      })
    },
    handelTrackBtnClick(order) {
      if (this.abtInfos.abTestConfig['Track'] === 'on') {
        this.toggleUnavailableModal()
        return
      }
      this.$router.push(`${this.publicLangPath}/user/orders/track/${order.billno}`)
    },
    async getOrdersListSer () {
      const { isSearchList, page: sPage, content: sKeyword } = this.orderSearchMsg || {}
      const languageKeys = Object?.keys(this.language || {})?.length ? 0 : 1
      const url = isSearchList ? '/api/orders/base/searchOrderGoods/query' : '/api/orders/base/list/get'
      const data = isSearchList ? { limit: 10, page: sPage, keyword: sKeyword } : { limit: 10, page: this.page, filter_type: 1, withI18n: languageKeys }
      const res = await schttp({
        url: url,
        params: {
          ...data,
          from: 'orderList',
        },
        schttp: { needLogin: true }
      })
      return res
    },
    updateFreeRefund(obj) {
      const item = this.orders.find(i => i.billno === obj.billno)
      if (item) {
        item.isFreeRefund = obj.isFreeRefund
      }
    },
    getOrdersListSerCb (res) {
      this.updateAllOrderList(res)
      this.updateAllOrderStatusEvt(res)
    },
    updateAllOrderStatusEvt (res) {
      if (res?.code == '0' && res?.info) {
        let orders = res.info.orders || []
        if (!this.orderSearchMsg.isSearchList) {
          SaPageInfo.page_param.order_count = res.info.sum
        }
        if (res?.currencySupport) {
          this.assignState({ currencySupport: res.currencySupport })
        }
        if (orders && res?.jump_pci_token) {
          this.setPCIToken({ jump_pci_token: res.jump_pci_token })
        }
      } else {
        this.showMsg({
          type: 'err',
          msg: res?.tips || this.language?.SHEIN_KEY_PWA_15330 || ''
        })
      }
    },
    async loadMore() {
      if (this.loadingMore) return
      this.loadingMore = true
      const { loading, historyShow } = this.status || {}
      if (this.status_type) {
        await this.getOrderListByStatus()
        if (window.navigator.onLine) {
          this.loadingMore = false
        }
        return
      }
      if (loading) return
      this.setStatusLoading(true)
      if (historyShow) {
        this.historyListFun(0)
        this.loadingMore = false
        return
      }
      const res = await this.getOrdersListSer()
      this.getOrdersListSerCb(res)
      this.loadingMore = false
    },
    async getHistoryListSer (type) {
      const data = await schttp({
        url:
          '/api/orders/base/orderFile/query?limit=10&page=' +
          (type !== 'showHistory' ? this.pageold++ : 1)
      })
      return data
    },

    getHistoryListSerCb ({ data = {}, type } = {}) {
      this.updateHistoryOrderList({ data, type })
      if (type == 1 || type == 2) {
        sa('send', {
          activity_name: type == 1 ? 'click_view_history_order' : 'click_view_more_history_order',
          activity_param: ''
        })
      }
    },
    async historyListFun(type) {
      if (type !== 'showHistory') {
        this.assignOrderListState({
          status: {
            historyExtra: false,
            histtoryStatus: false,
            historyShow: false
          }
        })
        this.setStatusLoading(true)
      }
      const data = await this.getHistoryListSer(type)
      this.getHistoryListSerCb({ data, type })
    },
    orderRefundMask() {
      const { billno } = this.refundOrder
      daEventCenter.triggerNotice({
        daId: '1-20-1-21',
        extraData: {
          order_id: billno
        }
      })
      if (this.showRefundNew) {
        this.handleShowCancelItemSelect()
      } else {
        this.jumpRefund(this.refundOrder)
      }
    },
    async orderRefund(order, status) {
      this.orderInfo = order
      const { billno, shipping_country_name, payment_method, shipping_country_id } = order || {}
      if (this.disabledCancelButton(order)) {
        this.showFrontConditionText = this.language.SHEIN_KEY_PWA_21408
        this.showFrontConditionDialog = true
        return
      }
      if (this.overFrontCondition(order) > 0) {
        daEventCenter.triggerNotice({
          daId: '1-20-1-44',
          extraData: {
            operation_from: 2
          }
        })
        this.showFrontConditionText = this.htmlDecode(this.language.SHEIN_KEY_PWA_20122)
        this.showFrontConditionDialog = true
        return
      }
      if (this.overB2B2CCondition(order)) {
        let { order_detail_status = '' } = order || {}
        let textMap = {
          1: this.language.SHEIN_KEY_PWA_21408,
          2: this.language.SHEIN_KEY_PWA_21410,
          3: this.language.SHEIN_KEY_PWA_21409
        }
        this.showFrontConditionText = textMap[order_detail_status]
        this.showFrontConditionDialog = true
        return
      }
      if (status && this.isEnableCancelAbt()) {
        this.toggleUnavailableModal()
        return
      }

      this.refundOrder = order
      this.assignOrderListState({
        status: {
          msgLoading: true
        }
      })

      const url = this.showRefundNew ? `${this.publicLangPath}/user/orders/list` : `${this.publicLangPath}/user/orders/refund/${billno}?paymentMethod=${payment_method}`
      const result = await this.checkSiteFn(order)

      if (this.showRefundNew) {
        if (result != 1) {
          this.assignOrderListState({
            status: {
              msgLoading: false
            }
          })
          return this.jumpMask({
            url,
            result,
            shipping_country_name,
            shipping_country_id
          })
        }
        this.checkOrderStatusEvt(order)
      } else {
        let refundGoodsInfo = await this.showRelationGoodsList(
          { billno, payment_method },
          { type: 3, url })
        if (result != 1) {
          let isShowRelationDialog = refundGoodsInfo.code == '0' && refundGoodsInfo.flag
          this.assignOrderListState({
            status: {
              msgLoading: false
            }
          })
          return this.jumpMask({
            url,
            result,
            shipping_country_name,
            shipping_country_id,
            options: { billno, payment_method, showRelationDialog: isShowRelationDialog }
          })
        }
        
        if (refundGoodsInfo.code == '0' && refundGoodsInfo.flag) {
          this.assignOrderListState({
            status: {
              msgLoading: false,
            }
          })
          this.exposeCombinedRefundTip('1-20-1-53')
          this.relationOptions = {
            ...this.relationOptions,
            url,
            billno,
            payment_method,
            type: 3
          }
          this.showRelationDialog = true
        } else if (!this.showRefundNew) {
          this.$toast(refundGoodsInfo?.tips || this.language.SHEIN_KEY_PWA_15948)
          this.assignOrderListState({
            status: {
              msgLoading: false
            }
          })
        }
      }
    },
    async checkOrderStatusEvt (order) {
      let { billno = '' } = order || {}
      this.assignOrderListState({
        status: {
          msgLoading: false
        }
      })

      let orderStatus = await schttp({
        method: 'POST',
        url: '/api/orders/base/checkStatus/get',
        data: { billno }
      })
      if (orderStatus?.info == '1') {
        this.refundNotCodMask = true
        saObj.expose_popup_unshipped_cancel_confirmation()
      } else if (orderStatus?.info == '2') {
        this.refundMask = true
      } else {
        let errorTextMap = {
          '0': this.language.SHEIN_KEY_PWA_15442,
          '300399': this.language.SHEIN_KEY_PWA_14909,
          'default': this.language.SHEIN_KEY_PWA_15409
        }
        this.$toast(errorTextMap[orderStatus?.code] || errorTextMap['default'])
      }
    },
    async confirmDeliver(order) {
      this.showConfirmDeliveryTips = false
      this.orderDeliveryPointCheckTips.showModal = false
      var language = this.language
      var num = this.orderDeliveryForm.points || 0
      var isDoublePointsOrder = this.orderDeliveryForm.isDoublePoints
      var confirmPoints = this.orderDeliveryPointCheckTips.points
      const isFreeTrial = this.orderDeliveryForm.isFreeTrial
      const uploadTryStatus = this.orderDeliveryForm.uploadTryStatus
      this.assignOrderListState({
        status: {
          msgLoading: true,
        }
      })
      this.orderDeliveryForm.billno = order.billno
      const orderTmp = this.orders.find(item => {
        return item.billno === order.billno
      })
      this.orderDeliveryForm.isCanComment = orderTmp.isCanComment
      const isCanComment = this.orderDeliveryForm.isCanComment || 0
      var self = this
      let json = await schttp({
        method: 'POST',
        url: '/api/orders/base/complete/update',
        data: { billno: order.billno }
      })
      this.assignOrderListState({
        status: {
          msgLoading: false,
        }
      })
      if (json.code == 0) {
        // 未触发积分熔断才能跳转新成功状态页面
        !self.isPointCheckModle && self.toDeliverySuccessPanel(order)
        if (!self.IS_RW) {
          if (self.orderDeliveryPointCheckTips.text != '') {
            const msg = confirmPoints
              ? self.language.SHEIN_KEY_PWA_18002.replace('{0}', confirmPoints)
              : self.language.SHEIN_KEY_PWA_17391
            self.$toast(msg)
          } else {
            if (isFreeTrial && uploadTryStatus && uploadTryStatus.status == '3') {
              self.confirmDeliverUpload = true
              daEventCenter.triggerNotice({
                daId: '1-20-1-24'
              })
            } else {
              if (num) {
                if (isCanComment && (isCanComment === 1 || isCanComment === 3) && !isFreeTrial) {
                  self.confirmDeliverMessageText = isDoublePointsOrder
                    ? self.language.SHEIN_KEY_PWA_17966.replace(
                      '{0}',
                      `<span class="red">${num}</span>`
                    )
                    : self.language.SHEIN_KEY_PWA_17966.replace('{0}', num)
                } else {
                  self.confirmDeliverMessageText = isDoublePointsOrder
                    ? self.language.SHEIN_KEY_PWA_17010.replace(
                      '[num]',
                      `<span class="red">${num}</span>`
                    )
                    : self.language.SHEIN_KEY_PWA_17010.replace('[num]', num)
                }
              } else {
                if (isCanComment && (isCanComment === 1 || isCanComment === 3) && !isFreeTrial) {
                  self.confirmDeliverMessageText = self.language.SHEIN_KEY_PWA_17938
                } else {
                  self.confirmDeliverMessageText = self.language.SHEIN_KEY_PWA_17391
                }
              }
              self.confirmDeliverMessage = true
            }
          }
        } else {
          if (isCanComment && (isCanComment === 1 || isCanComment === 3) && !isFreeTrial) {
            self.confirmDeliverMessage = true
            self.confirmDeliverMessageText = self.language.SHEIN_KEY_PWA_17938
          }
        }
        for (var i in self.orders) {
          if (self.orders[i].billno == order.billno) {
            self.orders[i].orderStatus = 5
            self.orders[i].isCanConfirmDelivery = true
          }
        }
      } else {
        self.showMsg({
          type: 'err',
          msg: json.code == 300399 ? language.SHEIN_KEY_PWA_14909 : language.SHEIN_KEY_PWA_15330
        })
      }
      daEventCenter.triggerNotice({
        daId: self.isPointCheckModle ? '1-20-1-10' : '1-20-1-13',
        extraData: {
          order_id: order.billno,
          result: json.code == 0 ? 1 : 2
        }
      })
    },
    toDeliverySuccessPanel(order) {
      this.$router.push({
        path: `${this.publicLangPath}/user/orders/state?type=delivery&billno=${order.billno}`
      })
    },
    showMsg (params) {
      var msg = params.msg
      var type = params.type
      var timer = params.timer || 1500
      var self = this
      self.assignOrderListState({
        status: {
          msg: true,
        }
      })
      this.msgBox.info = msg
      this.msgBox.type = type
      return new Promise(function (resolve) {
        setTimeout(function () {
          self.assignOrderListState({
            status: {
              msg: false,
            }
          })
          resolve()
        }, timer)
      })
    },
    async returnItemMask() {
      var self = this
      var billno = self.inReturnItem.billno
      self.inReturnItem.mask = false
      self.assignOrderListState({
        status: {
          msgLoading: true,
        }
      })
      const data = await schttp({
        method: 'POST',
        url: '/api/orders/base/complete/update',
        data: { billno: billno }
      })
      self.assignOrderListState({
        status: {
          msgLoading: false,
        }
      })
      if (data.code == 0) {
        self.$router.push({
          path: `${self.publicLangPath}/orders/return/${billno}`
        })
        // 创新渲染当前
      } else {
        self.showMsg({
          type: 'err',
          msg: data.code == 300399 ? this.language.SHEIN_KEY_PWA_14909 : this.language.SHEIN_KEY_PWA_15330
        })
      }
    },
    getRetainTips(methods) {
      return retainPayMethodLang(this.language, methods)
    },
    toggleRetainOrder(type) {
      const saActivityName = {
        popup: 'expose_popup_cancelorderretentionbox',
        ok: 'click_confirmcancel',
        cancel: 'click_donotcancelnow'
      }
      sa('send', {
        activity_name: saActivityName[type],
        activity_param: {}
      })
      this.retainOrder.mask = !this.retainOrder.mask
    },
    goBack() {
      this.setStatusLoading(false)
      markPoint('toNextPageClick', 'public')
      this.$router.push(this.publicLangPath + '/user/index')
    },
    orderSearchOpen() {
      this.orderSearchCancel()
      this.assignOrderListState({
        orderSearchMsg: {
          show: !this.orderSearchMsg.show
        }
      })
    },
    orderSearchCancel() {
      const { content = '', isSearchList } = this.orderSearchMsg
      if (!content) {
        this.assignOrderListState({
          orderSearchMsg: {
            show: false
          }
        })
      }
      this.assignOrderListState({
        orderSearchMsg: {
          content: ''
        }
      })
      if (isSearchList) {
        this.assignOrderListState({
          orderSearchMsg: {
            isSearchList: false
          },
          orders: [],
          page: 1,
          noMoreData: false,
          status: {
            histtoryStatus: false,
            historyExtra: false,
            historyShow: false
          }
        })
        this.loadMore()
      }
    },
    orderSearch: function () {
      if (/^\s*$/.test(this.orderSearchMsg.content)) return
      this.reloadFindOrderDom()
      this.assignOrderListState({
        noMoreData: true,
        orders: [],
        status_type: 0,
        orderSearchMsg: {
          isSearchList: true,
          page: 1
        }
      })
      this.loadMore()
    },
    handlePointsTipShow: function (points) {
      this.$refs[points][0].style.display = 'none'
      this.$refs[points + '-arrow'][0].style.display = 'none'
    },
    handleMyReviewShow: function (myReview, order) {
      this.$refs[myReview][0].style.display =
        this.$refs[myReview][0].style.display == 'block' ? 'none' : 'block'
      if (order.isCanComment && order.isCanComment == 3) {
        sa('send', {
          activity_name: 'expose_my_review',
          activity_param: {}
        })
      }
    },
    async navToReview(order) {
      this.currentCommentOrder = order?.billno
      if (this.abtInfos.abTestConfig['Review'] === 'on') {
        this.toggleUnavailableModal()
        return
      }
      const verifyData = await this.handleRiskVerify(order)
      const { risk_decision, risk_id = '' } = verifyData?.info || {}
      const code = verifyData?.code
      if (code == 0 && risk_decision == 3610) {
        this.showRiskCommentTips = true
        daEventCenter.triggerNotice({
          daId: '1-20-1-130',
          extraData: {
            order_no: order.billno,
          }
        })
        return
      }
      if(!this.IS_RW) {
        const riskData = {
          risk_id: code == 0 ? risk_id : '',
          billno: verifyData.billno,
          biz_id: verifyData.biz_id,
          sku_code_list: verifyData.sku_code_list,
          goods_id_list: verifyData.goods_id_list,
          goods_name_list: verifyData.goods_name_list,
          order_status: verifyData.order_status,
          blackbox: verifyData.blackbox,
          rateStatus: code == 0 && risk_decision == 3621 ? 0 : 1
        }
        setLocalStorage({ key: 'risk_review_data', value: riskData })
      }
      this.$router.push({ path: `${this.publicLangPath}/user/orders/order_review?billno=${order.billno}` })
    },
    handleRiskVerify(order = {}) {
      if(this.IS_RW) return {}
      const { billno = '', orderGoodsList = [], orderStatus = '' } = order
      const sku_code_list = orderGoodsList.map(v => v?.product?.goods_sn || '')
      const goods_id_list = orderGoodsList.map(v => v.goods_id)
      const goods_name_list = orderGoodsList.map(v => v?.product?.goods_name || '')
      const biz_id = `${new Date().getTime()}_${billno}`
      const blackbox = window._fmOpt?.__blackbox || ''
      return new Promise((resolve) => {
        schttp({
          method: 'POST',
          url: '/api/risk/getReviewRisk/get',
          data: {
            billno,
            sku_code_list,
            goods_id_list,
            goods_name_list,
            blackbox,
            biz_id,
            order_status: orderStatus
          }
        }).then(res => {
          if(res !== null && typeof res === 'object') {
            res.biz_id = biz_id
            res.sku_code_list = sku_code_list
            res.goods_id_list = goods_id_list
            res.goods_name_list = goods_name_list
            res.order_status = orderStatus
            res.billno = billno
            res.blackbox = blackbox
          }
          resolve(res)
        }).catch(err => {
          resolve({})
          console.log('review risk error', err)
        })
      })
    },
    navToMyReview: function (billno) {
      if (this.abtInfos.abTestConfig['MyReview'] === 'on') {
        this.toggleUnavailableModal()
        return
      }
      this.$router.push({ path: `${this.publicLangPath}/user/orders/review_already?billno=${billno}` })
    },
    closeRiskCommentTips() {
      if (!this.riskCommentArr.includes(this.currentCommentOrder)) {
        this.riskCommentArr.push(this.currentCommentOrder)
      }
    },
    handleBtnMoreItem(item) {
      item.el.click()
    },
    reloadFindOrderDom(){
      // 切换tab 刷新曝光埋点
      this.showFindOrderFlag = false
      this.$nextTick(()=>{
        this.showFindOrderFlag = true
      })
    },
    handleTabChange(val) {
      if (this.status_type != val) {
        this.reloadFindOrderDom()
        this.statusFilterClick(val)
      }
    },
    async statusFilterClick(type) {
      this.assignOrderListState({
        status: {
          histtoryStatus: false,
          historyExtra: false,
          historyShow: false
        },
        noMoreData: false,
        status_type: type,
        page: 1,
        orders: []
      })
      this.assignRootState({ statusType: type })
      if (this.status_type) {
        await this.getOrderListByStatus()
      } else {
        await this.loadMore()
      }
    },
    async getOrderListByStatusSer () {
      const languageKeys = Object?.keys(this.language || {})?.length ? 0 : 1
      const res = await schttp({
        url: '/api/orders/base/getOrderListByStatus/query',
        params: {
          limit: 10,
          page: this.page,
          statusType: this.status_type,
          withI18n: languageKeys,
          from: 'orderList'
        },
        schttp: { needLogin: true }
      }).catch(() => {
        this.setStatusLoading(false)
      })
      return res
    },
    getOrderListByStatusSerCb (res) {
      this.updateByStatusOrderList(res)
      this.updateByStatusOrderStatusEvt(res)
    },
    updateByStatusOrderStatusEvt (res) {
      if (res?.code == '0' && res?.info) {
        const orders = res?.info?.orders || []
        if (orders && res?.jump_pci_token) {
          this.setPCIToken({ jump_pci_token: res.jump_pci_token })
        }
        this.assignOrderListState({
          extraInfo: res?.info?.extraInfo || {}
        })
        SaPageInfo.page_param.order_count = res.info.sum
        SaPageInfo.start_time = Date.now()
        window.SaPageInfo = SaPageInfo
        appEventCenter.$emit('pageOnload')
      } else {
        if (window.Navigator.onLine) {
          this.showMsg({
            type: 'err',
            msg: res?.tips || this.language?.SHEIN_KEY_PWA_15330
          })
        }
      }
    },
    onlineChange(online) {
      if (online && this.orders?.length) {
        if (this.loadingMore) {
          this.loadingMore = false
        }
      }
    },
    async getOrderListByStatus() {
      const { loading } = this.status || {}
      if (loading) {
        return
      }
      this.setStatusLoading(true)
      const res = await this.getOrderListByStatusSer()
      this.getOrderListByStatusSerCb(res)
    },
    hideBothubEntry: function () {
      this.bothubEntryShow = false
      sa(
        'send',
        $.extend(SaPageInfo, {
          activity_name: 'click_subscription_close'
        })
      )
    },
    trackItemShow (order) {
      if (order.orderStatus == 4) {
        return true
      } 
      let hasPackage = []
      if (order.reference_number_list) {
        hasPackage = order.reference_number_list || []
      } else {
        hasPackage = order?.orderGoodsList?.filter(item => {
          return item.reference_number
        })
      }
      return !!(hasPackage && hasPackage.length)
    },
    /*
     *根据不同的订单状态操作按钮展示不同的顺序
     */
    opeButtonSort(order) {
      const buttons = getOrderButtonSorts(order)
      if (!buttons?.length) {
        return []
      }
      const btns = new Set(buttons)
      // one click pay
      if (!order.showOneClickPay) {
        btns.delete('one_click_pay')
      }
      // 催派
      if (!this.showUrgeDeliveryBtn(order)) {
        btns.delete('urge_delivery')
      }
      // view invoices
      const isShowViewInvoices = this.isViewInvoices(order, 'order-list') && this.abtInfos?.isOpenViewInvoicesByAbt
      if (!isShowViewInvoices) {
        btns.delete('view_invoices')
      }
      // upload report
      const isShowUploadReport = (order?.uploadTryStatus?.status == 2 || order?.uploadTryStatus?.status == 3) && this.abtInfos?.uploadReportShow
      if (!isShowUploadReport) {
        btns.delete('upload_report')
      }
      // confirm delivery
      if (!order.isCanConfirmDelivery) {
        btns.delete('confirm_delivery')
      }
      // review
      if (!orderLogic.isOrderReview({ order })) {
        btns.delete('review')
      }
      // 物流轨迹
      if (!this.trackItemShow(order)) {
        btns.delete('track')
      }
      // 已付款未发货取消订单申请退款
      if (!this.isCommonShowCancelButton(order)) {
        btns.delete('cancel_order')
      }
      // in站不能在ar站短信验证
      const isShowVerifyNow = order.orderStatus == 13 && this.abtInfos?.orderListAbtInfo?.indexOf('verify=on') > -1 && this.getCodOrderFrom(this.publicLocalData?.lang).indexOf(order.site_from) > -1
      if (!isShowVerifyNow) {
        btns.delete('verify_now')
      }
      // pay now && Other Payment Method
      const isShowPayNow = (order.orderStatus == 0 || order.orderStatus == 12) && this.abtInfos?.orderListAbtInfo.indexOf('pay=on') > -1
      if (!isShowPayNow) {
        btns.delete('pay_now')
      }
      // urge_shipment
      if (!this.isShowUrgeshipment(order)) {
        btns.delete('urge_shipment')
      }
      // exchange
      if (!this.isShowSelfExchange(order)) {
        btns.delete('exchange')
      }
      // 编辑地址按钮新旧
      const isShowEditAddress = this.abtInfos?.showEditOrderAddress || (!this.abtInfos?.showEditOrderAddress && this.isShowEditAddressBtn(order))
      if (!isShowEditAddress) {
        btns.delete('edit_address')
      }
      // revoke
      if (!this.isCanRevokeByUser(order)) {
        btns.delete('revoke')
      }
      // repurchase
      const isShowRepurchase = REPURCHASE_ORDER_STATUS.includes(+order.orderStatus) && 
        !orderLogic.isRelationBillno(order.billno) && //不是U单
        ![1, 7].includes(+order.marketing_type) && // 不是生日礼/试用订单
        this.isShowRepurchaseBtn(order)
      if (!isShowRepurchase) {
        btns.delete('repurchase')
      }
      // my_review
      if (!(order.isCanComment && order.isCanComment == 2)) {
        btns.delete('my_review')
      }
      // return_item
      const isShowReturnItem = this.isReturnableInfo(order) && this.abtInfos?.orderListAbtInfo?.indexOf('returnitem=on') > -1
      if (!isShowReturnItem) {
        btns.delete('return_item')
      }
      // 催取件 urgent_pickup
      if (!order.return_order_list?.length){
        btns.delete('urgent_pickup')
      }
      return [...btns]
    },
    openTips(type, info) {
      const textMap = {
        'ebanx-boleto': this.language.SHEIN_KEY_PWA_16016,
        'dlocal-boleto': this.language.SHEIN_KEY_PWA_16016,
        'ebanx-oxxo': this.language.SHEIN_KEY_PWA_15452,
        'PayPal-paypal': this.IS_RW ? this.language.SHEIN_KEY_PWA_17312 : this.language.SHEIN_KEY_PWA_17206
      }
      const text = textMap[type] || (this.IS_RW ? this.language.SHEIN_KEY_PWA_17314 : this.language.SHEIN_KEY_PWA_17207)
      sa(
        'send',
        $.extend(SaPageInfo, {
          activity_name: info ? 'click_orderlist_alreadypaid' : 'click_orderlist_whypending',
          activity_param: {
            paymethod: type
          }
        })
      )
      this.showMaskTips(text)
    },
    async showCancelAddCartModal(orders, billno) {
      // 巴西平台化, 要根据是否符合弹框条件弹框(关联订单， 多个子订单)
      let subBillnoListRes = []
      if (isRelationBillno(billno)) {
        let subBillnosInfo = await schttp({
          url: `/api/orders/base/getSubBillnos/get?relation_billno=${billno}`
        })
        subBillnoListRes = subBillnosInfo?.info?.result?.[0]?.sub_billno_list_res || []
      }
      let showAddCart = !(subBillnoListRes?.length > 1)
      if (!showAddCart) {
        this.$routerPush(`${this.publicLangPath}/user/orders/state?type=unpaid&status=1&billno=${billno}`)
        return
      }
      this.assignOrderListState({
        status: {
          showAddCart
        }
      })
      this.addCartOrderNo = subBillnoListRes?.length == 1 ? subBillnoListRes?.[0]?.billno : billno
      this.cancelForm.order = orders.filter(item => {
        return item.billno == billno
      })[0]
      daEventCenter.triggerNotice({
        daId: '1-20-1-17',
        extraData: this.cancelOrderData()
      })
    },
    cancelOrderData() {
      const order = this.cancelForm.order
      let goods_id = []
      order.orderGoodsList.forEach(item => {
        goods_id.push(item.goods_id)
      })
      goods_id = goods_id.join(',')
      return {
        order_id: order.billno,
        goods_id: goods_id,
        order_type: order.payment_method == 'cod' ? 1 : 2,
        order_status: [0, 12, 13].includes(order.initOrderStatus) ? 1 : 2,
        orderStatus: order.initOrderStatus
      }
    },
    handleCancelAddCartModal(result) {
      if (result) {
        this.handleSave(
          this.addCartOrderNo,
          res => {
            this.assignOrderListState({
              status: {
                showAddCart: false
              }
            })
            if (res.code == 0) {
              this.addCartOrderNo = ''
              this.$routerPush(`${this.publicLangPath}/cart`)
              this.$toast(this.language.SHEIN_KEY_PWA_15021)
            }
          },
          'cancel'
        )
        daEventCenter.triggerNotice({
          daId: '1-20-1-18',
          extraData: this.cancelOrderData()
        })
      } else {
        /* close */
        this.assignOrderListState({
          status: {
            showAddCart: false
          }
        })
        daEventCenter.triggerNotice({
          daId: '1-20-1-19',
          extraData: this.cancelOrderData()
        })
        // 确认取消订单（未支付订单取消成功页面）
        this.toCancelOrderSuccessPanel()
      }
    },
    toCancelOrderSuccessPanel() {
      this.$routerPush(`${this.publicLangPath}/user/orders/state?type=unpaid&billno=${this.addCartOrderNo}`)
    },
    async preCheckPointByConfirmOrder() {
      const { siteFrom, billno } = this.orderDeliveryForm || {}
      const res = await schttp({
        method: 'POST',
        url: '/api/orders/base/preCheckPointByConfirmReceive/query',
        data: {
          billno,
          site_from: siteFrom
        }
      })
      if (res.code == 0 && res.info) {
        daEventCenter.triggerNotice({
          daId: '1-20-1-13',
          extraData: {
            order_id: billno,
            result: ''
          }
        })
        if (!res.info.fuse_reason) {
          this.isPointCheckModle = false
          this.confirmDeliver(this.orderTmp)
        } else {
          const maxAvailablePoint = res.info.max_available_point
          if (maxAvailablePoint && maxAvailablePoint > 0) {
            this.orderDeliveryPointCheckTips.points = maxAvailablePoint
            this.orderDeliveryPointCheckTips.text = this.language.SHEIN_KEY_PWA_18000.replace(
              '{0}',
              this.orderDeliveryPointCheckTips.points
            )
          } else {
            this.orderDeliveryPointCheckTips.text = this.language.SHEIN_KEY_PWA_18001
          }
          this.showConfirmDeliveryTips = false
          this.orderDeliveryPointCheckTips.showModal = true
          this.isPointCheckModle = true
          daEventCenter.triggerNotice({
            daId: '1-20-1-9'
          })
        }
      } else {
        this.isPointCheckModle = false
        this.confirmDeliver(this.orderTmp)
      }
    },
    cancleConfirmOrder() {
      this.showConfirmDeliveryTips = false
      daEventCenter.triggerNotice({
        daId: '1-20-1-14',
        extraData: {
          order_id: this.orderDeliveryForm.billno
        }
      })
    },
    confirmDeliverMessageEvt() {
      this.confirmDeliverMessage = false
      const { isCanComment, isFreeTrial } = this.orderDeliveryForm || {}
      if (isCanComment && (isCanComment === 1 || isCanComment === 3) && !isFreeTrial) {
        const billno = this.orderDeliveryForm?.billno
        sa('send', {
          activity_name: 'click_go_to_write_a_review',
          activity_param: { order_id: billno }
        })
        this.$router.push({
          path: `${this.publicLangPath}/user/orders/order_review?billno=${billno}&isRealTime=1`
        })
      }
    },
    closeConfirmDeliver() {
      this.confirmDeliverMessage = false
      const orderDeliveryForm = this.orderDeliveryForm
      sa('send', {
        activity_name: 'click_close',
        activity_param: { order_id: orderDeliveryForm.billno }
      })
    },
    closeConfirmDeliverUpload(flag) {
      this.confirmDeliverUpload = false
      daEventCenter.triggerNotice({
        daId: flag == 'button' ? '1-20-1-22' : '1-20-1-23',
        extraData: {
          click: '2'
        }
      })
    },
    cancelPreCheckConfirmDeliver() {
      this.orderDeliveryPointCheckTips.showModal = false
      daEventCenter.triggerNotice({
        daId: '1-20-1-11'
      })
    },
    toEditReport(data, source, order) {
      if (source == 'popup') {
        daEventCenter.triggerNotice({
          daId: '1-20-1-22',
          extraData: {
            click: '1'
          }
        })
      } else if (source == 'button') {
        daEventCenter.triggerNotice({
          daId: '1-20-1-25'
        })
      }
      this.confirmDeliverUpload = false
      if (data.status == 2) {
        // 置灰
        this.$toast(this.language.SHEIN_KEY_PWA_18092)
      } else if (data.status == 3) {
        // 展示并跳转
        const reportInfo = this.getReportInfo(order)
        this.$routerPush({
          name: 'post-report',
          params: {
            goods: {
              detail: {
                goods_img: reportInfo?.goods_img,
                goods_name: reportInfo?.goods_name,
                goods_sn: reportInfo?.goods_sn
              },
              id: data.apply_id,
              size_value: reportInfo?.size_value,
              attr_language_value: reportInfo?.attr_language_value,
              goods_sn: reportInfo?.goods_sn,
              billno: data.billno
            }
          },
          query: {
            from: 'order_list'
          }
        })
      }
    },
    //回收站功能上线后首次删除订单则展示入口提示气泡
    showDeleteHistoryPopoverHandle() {
      try {
        const isFirstDeleteOrder = window.localStorage.getItem('firstDeleteOrder')
        if (isFirstDeleteOrder && isFirstDeleteOrder == 1) {
          this.showDeleteHistoryPopover = true
          const timer = setTimeout(() => {
            this.showDeleteHistoryPopover = false
            window.clearTimeout(timer)
            window.localStorage.setItem('firstDeleteOrder', 2)
          }, 3500)
        }
      } catch (e) {
        console.info(e)
      }
    },
    // 曝光埋点处理
    handleExposeAnalysis() {
      this.$nextTick(() => {
        webScrollExpose.subscribe({
          dom: this.$refs['listContent'],
          mergeExpose: true,
          callback: ({ container }) => {
            const { upload, invoices } = container?.[0]?.dataset || {}
            if (upload == 2 || upload == 3) {
              daEventCenter.triggerNotice({
                daId: '1-20-1-26',
                target: container,
                extraData: {
                  status: upload == 2 ? '1' : '2'
                }
              })
            }
            // 查看发票按钮曝光
            if (invoices && this.abtInfos.isOpenViewInvoicesByAbt) {
              try {
                const info = JSON.parse(invoices) || {}
                if (orderLogic.isViewInvoices(info, 'order-list')) {
                  daEventCenter.triggerNotice({
                    daId: '1-20-1-29',
                    target: container,
                    extraData: {
                      page_name: SaPageInfo.page_name
                    }
                  })
                }
              } catch (e) {
                console.log(e)
              }
            }
          }
        })
        // 移除监听订阅
        this.$once('hook:beforeDestroy', () => {
          webScrollExpose.removeSubscriber(this.$refs['listContent'])
        })
      })
    },
    /**
     * @description 跳转查看发票
     * @date 2021-07-06 14:37
     * @param {string} billno - 订单号
     */
    navToViewInvoices(billno) {
      daEventCenter.triggerNotice({
        daId: '1-20-1-28',
        extraData: {
          page_name: SaPageInfo.page_name
        }
      })
      billno && this.$router.push({ path: `${this.publicLangPath}/user/order_invoices/${billno}` })
    },
    handleShowCodCancelItemSelect() {
      this.changeRootStatus({ loading: true })
      this.fetchOrderInfo()
    },
    async fetchOrderInfo() {
      const { billno } = this.orderInfo || {}

      const data = await schttp({
        url: `/api/orders/base/get_order_info/get`,
        method: 'GET',
        params: {
          isRefund: true,
          billno
        },
      })
      this.changeRootStatus({ loading: false })
      const orderInfo = data?.code == 0 && data?.info?.order
      if (orderInfo) {
        this.codCancelItemTipVisible = false
        this.assignCancelItemState({
          visibleMainDrawer: true,
          orderInfo,
          language: { ...this.language, ...data.i18n }
        })
      } else {
        this.assignCancelItemState({
          orderInfo: {}
        })
      }
    },
    setStatusLoading(v) {
      // 只允许一种loading出现
      if (this.pageLoading) return
      this.assignOrderListState({
        status: {
          loading: v
        }
      })
    },
    updateShowValue() {
      this.showRelationDialog = false
    },
    confirmCancelSubmit({ type, payment, billno, status, refundGoodsList }) {
      this.showRelationDialog = false
      this.cancelForm = Object.assign({}, this.cancelForm, {
        type,
        payment,
        billno,
        status,
        show: true,
        refundGoodsList
      })
    },
    handlePaymentOptionsOper({ type } = { type: '', data: {} }) {
      if (type === 'bin-discount-article') {
        this.visibleCardBinDiscountArticle = true
      }
    },
    async showUrgeDelivery(order) {
      this.setStatusLoading(true)
      this.orderInfo = order
      this.$nextTick(() => {
        this.$refs.urgeDeliveryModalRef.showUrgeDelivery()
      })
      this.setStatusLoading(false)
    },
    getBtnMoreAnalysis(item) {
      let daId = item?.el?.[0]?.getAttribute('da-sa-id') || ''
      let reportData = item?.el?.[0]?.getAttribute('da-sa-param') || '{}'
      reportData = JSON.parse(reportData) ?? {}

      if (reportData.hasOwnProperty('is_promoshow')) {
        reportData.is_promoshow = 0
      }

      return {
        id: daId,
        data: reportData
      }
    },
    handleBankSelectInVm(item){
      this.updateCapitecDialog({
        phone: this.orderInfo.shipping_telephone,
        countryId: this.orderInfo.country_id
      })
      this.handleBankSelect(item)
    },
    urgeReturnEvt (order, orderBtnMore) {
      const buttonStatus = orderBtnMore?.btnMoreList?.some(item => item?.text.includes(this.language.SHEIN_KEY_PWA_31578))
      const reportInfo = {
        daId: '1-20-1-156',
        bindData: {
          order_no: order.billno,
          button_status: buttonStatus ? 2 : 1,
          result: ''
        }
      }
      this.$refs.urgentPickUp?.initUrgeEvt({
        urgentList: order.return_order_list,
        billno: order.billno,
        reportInfo
      })
    },
    returnItemEvt(order) {
      this.orderInfo = order
      this.handleReturnItem(order, 'OrderList')
    },
    existUnsignSpecialGoods(orderGoodsList, packageNo) {
      return orderGoodsList.some(item =>
        [23, 49, 16, 52, 76, 90, 91, 57, 94, 54, 155, 154, 156].includes(+item.status)
        && item.reference_number == packageNo
      )
    }
  }
}
</script>

<style lang="less">
// TODO 暂时忽略，待中台组件支持公共样式引入后进行替换
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  height: 100%;
  height: 100vh;
}

body {
  font-family: @font-family;
  .font-dpr(24px);
  color: #222;
  width: 10rem;
  height: 100%;
  line-height: 1.5;
  margin: 0 auto;
  background: #f6f6f6;
  -webkit-user-select: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(255,255,255,0);
  outline: 0;
  -webkit-overflow-scrolling: touch;
}


.fixed-common-header {
    position: fixed;
    left:0;
    top: 0;
    right: 0;
    width: 10rem;
    margin: 0 auto;
    transition: top .3s ease-out, transform .3s ease-out;
}

.mshe-bg-container {
  background: #fff;
}

/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
@w375:375 /100vw;

.edit-address-common() {
  border: none;
  font-weight: 400;
  padding: 0;
  color: @sui_color_link !important;
  background: transparent !important;
}

iframe.goog-te-banner-frame,
iframe.goog-te-menu-frame {
  z-index: 10000002; /* stylelint-disable-line declaration-property-value-blacklist */
  transform: translate3d(0, 0, 10000002px);
}
.order-list-z-header {
  .zindex-translatez(@zindex-header,translate3d(0,0,@tranlatez-header));
}
.order-list-hide {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}
.order-list-top-pad {
  padding-top: 1.17rem;

  .edit-address-link {
    &.address-edit_box {
      button {
        .edit-address-common()
      }
    }
    
    &.order-list-bot__btn {
      .edit-address-common()
    }
  } 
}
.order-list-flexbetween {
  .flexbox();
  .space-between();
}
.order-list-text-center {
  text-align: center;
}
.order-list-text-left {
  .txt-l();
}
.timeover-tips {
  text-align: center;
}
.macc-order-search {
  position: absolute;
  top: 1.17rem;
  width: 100%;
  padding: 0.32rem;
  box-sizing: border-box;
  background: #fff;
  // display: none;
  font-size: 14px;
  height: 1.45rem;
  border-bottom: 1px solid #e5e5e5;
  .search-title {
    position: relative;
    box-sizing: border-box;
    .flexbox();
    .space-between();
    .align-center();
    input {
      width: 290/42.4rem;
      height: 35/42.4rem;
      padding: 0.2rem;
      background: #f6f6f6;
      .font-dpr(24px);
      border: none;
      &::placeholder {
        color: #cccccc;
      }
    }
    a {
      text-decoration: none;
      color: #222;
      .margin-l(0.6rem);
    }
  }
}
.order-list-history {
  margin-top: 0.4rem;
  > a {
    text-decoration: none;
    color: #333;
    > i {
      .margin-l(0.2rem);
    }
  }
}
.macc-order-old-history {
  padding: 3rem 0.64rem;
  > i {
    .font-dpr(160px);
    display: block;
    & when (@IS_RW) {
      color: @sui_color_main;
      // &.icon-empty-history{
      // 	color:@color_brand
      // }
    }
  }
  p {
    .font-dpr(24px);
    color: #666666;
    &.demotion-tip {
      color: #999999;
    }
  }
}
.order-list-header {
  a {
    text-decoration: none;
    color: @sui_color_gray_dark1;
  }
  .S-title-nav__preblock {
    > span {
      margin-right: .32rem;
    }
  }
  .order-ticketnum {
    position: relative;
    > i {
      .font-dpr(32px);
    }
    > span {
      position: absolute;
      top: -0.08rem;
      min-width: 0.22rem;
      height: 0.36rem;
      line-height: 0.36rem;
      margin: 0 0.2rem;
      text-align: center;
      padding: 0 0.07rem;
      box-sizing: content-box;
      .font-dpr(20px);
      .border-dpr(border,2px,#e5e5e5);
      border-radius: 0.58rem;
      color: #999;
      right: -0.55rem;
    }
  }
}

.one_click_pay-list {
  position: relative;
  overflow: visible !important; /* stylelint-disable-line declaration-no-important */
  .free_tips {
    position: absolute;
    right: -7.5/75rem;
    top: -37.5/75rem;
    color: @sui_color_white;
    background: @sui_color_success;
    padding: 15/75rem;
    .flexbox();
    justify-content: flex-start;
    align-items: center;
    line-height: 1;
    transform: scale(.6);
    transform-origin: right;
    .free_text {
      height: 30/75rem;
    }
    .tips-text-scale {
      display: inline-block;
      .font-dpr(24px);
      line-height: 14/12;
    }
    .icon {
      .font-dpr(24px) !important;
    }
    &:after {
      content: ' ';
      position: absolute;
      right: 0;
      bottom: -9/75rem;
      width: 0;
      height: 0;
      border-top: 10/75rem solid #16533a;
      border-right: 10/75rem solid transparent;
    }
  }
}

.oreder-list-top-pad {
  padding-top: 2.22rem;
}
.demotion {
  padding: 48/75rem 24/75rem 0;
  .font-dpr(20px);
  color: #666666;
}
.go-gift-card-list {
  padding: 0.386667rem 0.32rem;
  margin: 16/75rem 0;
  background: #fff;
  color: #222;
  .font-dpr(28px);
}
.confirm-deliver-alter-modal {
  strong > span {
    font-weight: bold;
    color: #e64545;
  }
  .red {
    font-weight: bold;
    color: #e64545;
  }
}
.macc-order-list {
  background: #fff;
  margin-top: 16/75rem;
  .free-trial-tag {
    .font-dpr(20px);
    padding: 0.02666rem 0.05333rem;
    border-radius: 1px;
    text-transform: capitalize;
    .margin-l(0.106rem);
  }
  .brithday-gift {
    .font-dpr(20px);
    .margin-l(0.1rem);
    color: #bf9f53;
    .brithday-gift_tag {
      display: inline-block !important; /* stylelint-disable-line declaration-no-important */
      width: 0.28rem;
      height: 0.32rem;
      .margin-r(0.1rem);
      border: none;
      vertical-align: initial;
    }
  }
  .free-gift_tag {
    .margin-l(8/75rem);
    color: @sui_color_promo_dark;
    background: @sui_color_promo_bg;
    .font-dpr(24px);
    font-weight: 400;
    display: inline-block;
    padding: 2/75rem 8/75rem;
  }
  .combined-order {
    color: #666;
    .padding-l(4px);
    i {
      .font-dpr(16px);
    }
  }
  &.order-list-new {
    .list-title {
      font-size: 14px;
      border: 0;
      padding: 0.32rem 0.32rem 0;
      display: flex;
      align-items: center;
      em {
        position: relative;
        display: inline-flex;
        align-items: center;
        .status_text {
          font-weight: bold;
          display: inline-block;
          color: #222;
          .font-dpr(28px);
          margin-right: 0;
        }
        .status-pending {
          .font-dpr(24px);
          color: @sui_color_link;
          // transform: scale(.83);
          display: inline-block;
          // .margin-l(-.3rem);
        }
        .tips_con {
          display: inline-block;
          padding: 0.1rem;
          color: #999;
          border: 1px solid #e5e5e5;
          position: absolute;
          top: -0.1rem;
          .left(100%);
          background: #fff;
          width: 5rem;
          word-break: break-word;
          z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
          .font-dpr(22px);
          font-weight: normal;
        }
      }
      .question_icon {
        font-weight: normal;
        .font-dpr(24px);
        color: #666666;
      }
      .split-package {
        color: #666666;
        .font-dpr(24px);
      }
    }
    .list-tips {
      margin: 0.16rem 0.32rem 0 0.32rem;
      padding: 0.16rem 0;
      margin-bottom: 0.16rem;
      background-color: #fcf0d4;
      color: #222222;
      display: flex;
      align-items: center;
      .font-dpr(24px);
      span.text {
        flex: 1;
        .padding-l(0.16rem);
        &.no-btn {
          padding: 0 0.16rem;
        }
      }
      span.btn {
        padding: 0 0.16rem;
      }
    }
    .list-item-info {
      .font-dpr(24px);
      color: #666666;
      padding: 8/75rem 0 24/75rem 0;
      margin: 0 0.32rem;
      border-bottom: 1px solid #e5e5e5;
    }
    .list-bot-price-item-total {
      padding: 0 0.32rem 0.32rem 0.32rem;
      color: #666666;
      .font-dpr(24px);
      .txt-r();
      .list-bot-price-total {
        .price {
          font-weight: 600;
          color: #222222;

          &.promotion-two {
            color: @sui_color_discount;
          }
        }
      }
    }
    .list-bot {
      position: relative;
      padding: 0 0.32rem 0.32rem;
      font-size: 0;
      .direction-r();
      .j-pay-now-list {
        position: relative;
        overflow: initial !important; /* stylelint-disable-line declaration-no-important */
        .j-pay-now-warp {
          padding: 0.1rem 0;
          text-align: center;
          width: 180/75rem;
          position: absolute;
          top: 68/75rem;
          background: #fa6338;
          .font-dpr(24px);
          border-radius: 2px;
          .right(50%);
          .margin-r(-1.2rem);
          color: #fff;
          z-index: 3;
          &::before {
            position: absolute;
            content: '';
            width: 16/75rem;
            height: 16/75rem;
            background: #fa6338;
            transform: rotate(45deg);
            z-index: 2;
            top: -8/75rem;
            .left(50%);
            .margin-l(-8/75rem);
          }
        }
      }
      .list-bot-price {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        .list-bot-price-item {
          color: #666;
          margin-right: 10/42.4rem;
        }
        .list-bot-price-total {
          color: #999;
          > span {
            font-size: 14px;
            color: #333;
          }
        }
      }

      .list-bot-btn > button:nth-child(1), .list-bot-btn > div:nth-child(1) button {
        line-height: 1.15;
        background: @color_brand;
        color: #ffffff;
      }

      .list-bot-btn {
        .txt-r();
        position: relative;
        .padding-l(0.8rem);
        button:first-child[disabled] {
          background: #999;
          color: #bbb;
          border: none;
        }
        > button {
          .margin-l(0.32rem);
        }
        > div + button {
          .margin-l(0.32rem);
        }
        .report-disabled {
          background: #999 !important; /* stylelint-disable-line declaration-no-important */
          color: #bbb !important; /* stylelint-disable-line declaration-no-important */
          border: none !important; /* stylelint-disable-line declaration-no-important */
          & when (@IS_RW) {
            background: #fec5d4 !important; /* stylelint-disable-line declaration-no-important */
            color: #ffeded !important; /* stylelint-disable-line declaration-no-important */
          }
        }
        .over-time {
          background-color: rgba(
            34,
            34,
            34,
            0.4
          ) !important; /* stylelint-disable-line declaration-no-important */
          border-color: rgba(
            34,
            34,
            34,
            0.4
          ) !important; /* stylelint-disable-line declaration-no-important */
          & when (@IS_RW) {
            background: #fd8ca9 !important; /* stylelint-disable-line declaration-no-important */
            border-color: #fd8ca9 !important; /* stylelint-disable-line declaration-no-important */
          }
        }
        .opacity-item {
          opacity: 0.3;
          filter: alpha(opacity=30);
        }
        .order-return-item {
          margin-top: 0.2rem;
          display: inline-block;
          vertical-align: middle;
          a {
            height: 26/42.4rem;
            line-height: 26/42.4rem;
            padding: 0 8/42.4rem;
            text-decoration: none;
            display: block;
            color: #222;
          }
        }
        .comment-btn-op {
          position: relative;
          display: inline-block;
          .margin-l(0.32rem);
          .sui_icon_hourglass_12px_2 {
            .font-dpr(28px);
            vertical-align: middle;
          }
          .points-tip-bottom {
            display: table;
            position: absolute;
            background: #ffffff;
            box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.1);
            padding: 0.093rem 0.213rem;
            color: #666;
            text-transform: capitalize;
            top: 0.95rem;
            .left(50%);
            .right(initial);
            transform: translateX(-50%);
            white-space: nowrap;
            .zindex-translatez(8,translate3d(0,0,8px));
            .font-dpr(24px);
            border: 1px solid #fff;
            [class*='iconfont'] {
              position: absolute;
              .font-dpr(24px);
              transform: scale(0.4);
              .right(-0.05rem);
              top: -0.12rem;
            }
            & when (@IS_RW) {
              background: @sui_color_brand;
              border-color: transparent;
              border-radius: 0.053rem;
              height: 0.53rem;
              line-height: 100%;
              color: #ffffff;
              [class*='iconfont'] {
                top: -0.05rem;
              }
            }
            &.hide-points-tip {
              display: none;
            }
          }
          .points-tip-arrow {
            position: relative;
            display: block;
            width: 0.3rem;
            height: 0.3rem;
            border-bottom: 0.3rem solid #fff;
            border-right: 0.2rem solid transparent;
            border-left: 0.2rem solid transparent;
            border-top: none;
            margin: 0 auto;
            .zindex-translatez(8,translate3d(0,0,8px));
            & when (@IS_RW) {
              border-bottom: 0.3rem solid @sui_color_brand;
            }
          }
          .pay-down-tip-arrow {
            position: relative;
            display: block;
            width: 0.3rem;
            height: 0.3rem;
            border-right: 0.3rem solid transparent;
            border-left: 0.3rem solid transparent;
            border-top: none;
            margin: 0 auto;
            .zindex-translatez(8,translate3d(0,0,8px));
            border-bottom: 0.3rem solid @sui_color_welfare;
          }
        }
        .my-reviewed-comment {
          position: absolute;
          top: 0;
          .left(0);
          a {
            display: inline-flex;
            align-items: center;
            height: 0.8rem;
            text-decoration: none;
            .font-dpr(20px);
            column-gap: 8/75rem;
            .more-text {
              color: @sui_color_link;
              font-size: 14px;
              font-weight: 400;
              line-height: normal;
            }
            .point {
              width: 0.1rem;
              height: 0.1rem;
              background: @sui_color_link;
              border-radius: 0.07rem;
              border: 2px solid @sui_color_link;
              display: inline-block;
            }
          }
          .btn-more-list {
            position: absolute;
            background: #fff;
            padding: 0.1rem 0.3rem;
            color: #000;
            text-transform: capitalize;
            top: 0.8rem;
            .left(-0.12rem);
            white-space: nowrap;
            z-index: 10; /* stylelint-disable-line declaration-property-value-blacklist */
            .font-dpr(24px);
            border: 1px solid #e5e5e5;
            box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
            .txt-l();
            &::after {
              content: ' ';
              display: block;
              width: 0.3rem;
              height: 0.3rem;
              border: 1px solid #e5e5e5;
              position: absolute;
              .left(0.25rem);
              top: -0.15rem;
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
              background: #fff;
              border-bottom: #fff;
              border-left: #fff;
            }
            .btn-more {
              padding: 0.1rem 0;
              .repurchase-label {
                display: none;
              }
            }
            &.opacity-item {
              opacity: 0.3;
              filter: alpha(opacity=30);
            }
          }
        }
        .S-popover__main-content {
          white-space: nowrap;
          padding: 0.093rem 0.13rem;
          background: @sui_color_welfare;
        }
        .expire-count-down-info {
          .S-popover__main {
            display: none;
          }
        }
        .normal-expire-count-down {
          position: relative;
          .S-popover__main {
            margin-top: -24/75rem;
          }
        }
        .S-popover__main-dark::before {
          background: @sui_color_welfare;
        }
        .order-list-unpaid-wrap {
          .direction-l();
          .flexbox();
          position: absolute;
          background: @sui_color_welfare;
          padding: 0.093rem 0.13rem;
          color: #fff;
          text-transform: capitalize;
          top: 1.2rem;
          white-space: nowrap;
          .zindex-translatez(8,translate3d(0,0,8px));
          .font-dpr(24px);
          border-radius: 1px;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          text-align: center;
          [class*='iconfont'] {
            color: #ffffff;
          }
        }
      }
    }
    .list-bot-giftcard {
      display: flex;
      align-items: flex-start;
      .cancel-giftcard {
        .margin-r(24/75rem);
        font-weight: normal;
      }
    }
    .list-con {
      padding: 0.32rem 0.32rem 0 0.32rem;
      border: none;
      > li {
        display: block;
        white-space: nowrap;
        overflow-x: auto;
        scroll-behavior: smooth;
        overflow-y: hidden;
        &::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        .list-con-img {
          display: inline-block;
          width: 2rem;
          margin-right: 8/75rem;
          img {
            max-width: 100%;
            height: 198/75rem;
          }
        }
      }
      &.last-list-con {
        > li {
          border-bottom: none;
        }
      }
    }
    &.giftcard {
      .list-con {
        .list-con-img {
          display: flex;
          width: 100%;
          img {
            width: 100%;
            height: auto;
          }
          .inner-left {
            width: 152/75rem;
            height: 94/75rem;
            overflow: hidden;
          }
          .inner-right {
            .margin-l(16/75rem);
            .font-dpr(24px);
            color: #666;
            .price {
              margin-top: 16/75rem;
            }
          }
        }
      }
      .list-bot-price-item-total {
        padding: 0.32rem;
      }
      .list-bot {
        .list-bot-btn {
          .txt-r();
          .padding-l(0);
          button:first-child {
            .margin-l(0);
          }
        }
      }
      .order-list-countdown .pay-down-tip-arrow {
        position: relative;
        display: block;
        width: 0.3rem;
        height: 0.3rem;
        border-right: 0.3rem solid transparent;
        border-left: 0.3rem solid transparent;
        border-top: none;
        margin: 0 auto;
        .zindex-translatez(8,translate3d(0,0,8px));
        border-bottom: 0.3rem solid @sui_color_welfare;
      }
    }
    .repurchase-btn {
      overflow: initial;
    }
  }
  .list-title {
    padding: 0.2rem 0.4rem;
    .border-dpr(border-bottom,2px,#e5e5e5);
    > span {
      user-select: text;
      .font-dpr(24px);
    }
    > em {
      display: block;
      > i {
        width: auto;
        height: auto;
        .padding-l(8/75rem);
        display: inline-block;
        border-radius: 100%;
        &.order-status-processing,
        &.order-status-returned,
        &.order-status-verified,
        &.order-status-refunded,
        &.order-status-shipped,
        &.order-status-paid,
        &.order-status-green {
          background: @sui_color_safety;
        }
        &.order-status-canceled,
        &.order-status-expired,
        &.order-status-revoked,
        &.order-status-red {
          background: @sui_color_unusual;
          // background: #FAAD14;
          // & when (@IS_RW) {
          // 	background: #FACB5A;
          // }
        }
        &.order-status-waitting,
        &.order-status-verifty,
        &.order-status-blue {
          background: @sui_color_link;
        }
        &.order-status-delivered,
        &.order-status-grey {
          background: @sui_color_gray_light2;
        }
        &:first-child {
          vertical-align: middle;
          padding: 0.08rem;
        }
      }
      &.combine_return_icon {
        display: inline-flex;
        align-items: center;
        color: #666;
        .font-dpr(24px);
        i {
          .font-dpr(24px);
        }
      }
    }
  }

  .list-bot {
    padding: 0.2rem 0.4rem;
    .align-center();

    .text-uppercase {
      .discout-price {
        display: block;
        color: #ff596e;
        white-space: nowrap;
        line-height: 1.5;
      }
    }
  }
  .list-con-more {
    text-align: center;
    padding: 0.2rem 0.4rem;
    .border-dpr(border-bottom,2px,#e5e5e5);
    > i {
      .margin-l(0.2rem);
      display: inline-block;
      &.up {
        transform: rotateX(180deg);
        transition: all 0.3s ease;
      }
    }
  }
}
.delete-popover {
  position: unset !important; /* stylelint-disable-line declaration-no-important */
  .margin-l(0.4rem);
}
.order-ticketnum {
  position: relative;
  > i {
    .font-dpr(32px);
  }
  > span {
    position: absolute;
    top: -0.12rem;
    min-width: 0.22rem;
    height: 0.36rem;
    line-height: 0.36rem;
    margin: 0 0.2rem;
    text-align: center;
    padding: 0 0.07rem;
    box-sizing: content-box;
    .font-dpr(20px);
    .border-dpr(border,2px,#e5e5e5);
    border-radius: 0.58rem;
    color: #999;
    right: -0.55rem;
  }
}

.orders-list__empty {
  padding: 2rem 0;
  > i {
    color: @sui_color_main;
  }
  > img {
    width: 4rem;
  }
}
.apple-pay-c {
  width: 2.04rem;
  margin: 0.48rem auto 0.52rem;
  height: 1.27rem;
}
.applepaybtn {
  background: #000;
  color: #fff;
  background-image: url(/pwa_dist/images/applepaybtn-70df1f479a.png);
  background-repeat: no-repeat;
  width: 5.1rem;
  height: 0.96rem;
  border: 0;
  background-position: center;
  background-size: 1.4rem;
  margin: 0 auto;
  display: block;
}
.exchange-tips {
  text-align: center;
}
</style>
<style lang="less" scoped>
.wait-send-return {
  position: sticky;
  top: 1.1733rem;
  z-index: @zindex-header;
}
.network-error {
  position: sticky;
  top: 1.1733rem;
  z-index: @zindex-header;
}
</style>
