/* globals ApplePaySession, Klarna */
// import '../detail/google_pay'
import choocePaymentsPanel from '../detail/chooce_payments_panel.vue'
import chooceBankPanel from '../detail/chooce_bank_panel.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { abtservice } from 'public/src/services/abt'
import usePaymentAbt from 'public/src/pages/checkout/hooks/usePaymentAbt'
import { asyncLoadFile, getQueryString, parseQueryString, setSessionStorage } from '@shein/common-function'
import apiReport from '../../../../common/apiReport'
import GooglePay from 'public/src/pages/checkout/components/googlePay'
import schttp from 'public/src/services/schttp'
import { checkSchttp } from 'public/src/services/schttp/tools'
import { PPGA_CHECK_TYPE } from 'public/src/pages/common/constants.js'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import schttpSync from 'public/src/services/schttp/sync'
import applePayConfig from 'public/src/pages/common/apple_pay'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
import { isSupportDistributionCountrySer } from 'public/src/pages/user/child_pages/orders_bff/list/service.js'
import { PRE_ROUTING_PAYMENT_METHODS } from 'public/src/pages/checkout/components/config'
import { payCardPrefetchFn } from 'public/src/pages/checkout/page_tpls/payment_options_item_prefetch'

daEventCenter.addSubscriber({ modulecode: '1-11-1' })
const { langPath, NODE_SERVER_ENV, APPLE_PAY_CONFIG, PAYMENT_CARD_LOGO_CONFIG } = gbCommonInfo
const applePayMethod = APPLE_PAY_CONFIG?.applePayChannel || applePayConfig.applePayChannel
const apple_pay_map = PAYMENT_CARD_LOGO_CONFIG?.apple_pay_map ?? {}

// 支付方式映射银行列表
// const methodMapToBank = {
//   'worldpay-ideal': 'idealBanks',
//   'adyen-ideal': 'adyenIdealBanks',
//   'adyen-eps': 'adyenEpsBanks',
//   'Paytm-netbank': 'paytmNetBank'
// }

export default {
  data() {
    return {
      googlePay: {
        show: false
      },
      googleValid: false, // 浏览器是否兼容google pay,

      copyOrderNoShow: false, // 展示复制成功弹窗
      qiwiPayPhone: '', // qiwi相关
      qiwi: {
        phoneShow: false,
        phoneError: 0
      },
      // boleto邮箱检验
      emailTips: {
        val: '',
        show: false,
        err: ''
      },
      bank: {
        channels: [],
        paymentCode: '',
        show: false,
        err: true,
        'apacpay-vnbanktransfer-bank-code': -1,
        'worldpay-ideal-bank-code': -1,
        'adyen-ideal-bank-code': -1
      },
      applePay: {
        applepaycb: ''
      },
      expiredModalConfig: {
        show: false,
        order: null
      },
      payInlineAbtInfo: {}, // klarna等分期直连支付方式abt
      payInlineSessionInfoCache: {}, // klarna等分期直连支付方式session缓存
      payInlineSessionInfo: {}, // klarna等分期直连支付方式session
      needPayInlinePaymentMethods: [], // klarna等分期直连支付方式
      showWidgetForm: 1, // 0：Widget加载失败，不展示直接隐藏 1:初始态 2： Widget加载成功
      applePayTwiceFlag: false // 重复点击标识
    }
  },
  setup () {
    const { paymentCustomizeFlow, codFreeShow, applePayCashShow } =
      usePaymentAbt()
    return {
      paymentCustomizeFlow,
      applePayCashShow,
      codFreeShow
    }
  },
  components: {
    'chooce-payments-panel': choocePaymentsPanel,
    'chooce-bank-panel': chooceBankPanel,
    expiredModal: () => import(/* webpackChunkName: "ExpiredModal_Comp" */'./expiredModal.vue')
  },
  computed: {
    moduleCheckoutScene () {
      if (this.nowCheckoutScene === 'XtraDetail' || this.nowCheckoutScene === 'BuyPrime') {
        return 'XtraDetail'
      }
      if (this.nowCheckoutScene === 'OrderDetail') {
        return this.nowCheckoutScene
      }
      return this.module_name === 'OrderDetail' ? 'OrderDetail' : 'OrderList'
    }
  },
  created() {
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
      document.addEventListener('ftr:tokenReady', function (evt) {
        window.forterDeviceId = evt.detail || window.forterDeviceId || ''
      })
      this.initCybersource()
      this.forterLoad()
    }
  },
  destroyed() {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  mounted () {
    this.initEmailTipsValEvt()
    appEventCenter.$on('clear-and-pay-now', isDirectPay => {
      this.assignState({ payments: this.payments.map(v => ({ ...v, checked: false })) })
      this.handleEditPayment({ isDirectPay })
    })
    window.klarnaAsyncCallback = this.klarnaAsyncCallback
  },
  methods: {
    async initEmailTipsValEvt (argVal = '') {
      let userEmailValue = await UserInfoManager.get({ key: 'pwa_user_email', actionType: 'paymentMixin' })
      this.emailTips.val = argVal || userEmailValue || ''
    },
    toggleOrderExpiredModal({ type = '', order = null } = {}) {
      this.expiredModalConfig.show = !this.expiredModalConfig.show
      if (order && this.module_name !== 'OrderDetail') {
        this.expiredModalConfig.order = order
      }
      if (type === 'cancel') {
        daEventCenter.triggerNotice({
          daId: this.module_name === 'OrderDetail' ? '1-19-1-5' : '1-20-1-5'
        })
        if (this.module_name === 'OrderDetail') {
          this.handleEditPayment()
        } else {
          this.getPayments(this.expiredModalConfig.order, true)
        }
      } else if (type === 'ok') {
        daEventCenter.triggerNotice({
          daId: this.module_name === 'OrderDetail' ? '1-19-1-6' : '1-20-1-6'
        })
      }
      if (this.expiredModalConfig.show) {
        daEventCenter.triggerNotice({
          daId: this.module_name === 'OrderDetail' ? '1-19-1-4' : '1-20-1-4'
        })
      }
    },
    beforeunloadFn() {
      this.changeRootStatus({
        loading: false
      })
    },
    // 初始化支付方式对应银行列表
    initBankLists(paymentMethods = []) {
      paymentMethods.forEach(payment => {
        if (payment.bank_list && payment.bank_list.length) {
          const banks = payment.bank_list.map(item => {
            const { code = '', name = '' } = item || {}
            return {
              ...item,
              id: code,
              value: name
            }
          })
          const backupConfig = this.banksConfig[payment.code] || {}
          const matchSelect = banks.find(item => item.code == backupConfig.bankCode) || {}
          this.updateBanksConfig({
            [payment.code]: {
              list: banks,
              bankCode: matchSelect?.code || -1,
              bankCodeText: matchSelect.name || '',
              bankLogo: matchSelect?.logo || '',
              error: false
            }
          })
        }
      })
    },
    /*
     * 如果order，isOrderList，表明是订单列表
     *
     * */
    async getPayments(orderData, isOrderList) {
      const self = this
      let order = {}
      if (isOrderList) {
        this.changeRootStatus({
          loading: true
        })
        const orderInfo = await this.fetchOrderData(orderData.billno)
        order = orderInfo.order
        const notSupportCodTipsCode =
          (order.notSupportCodTips && order.notSupportCodTips.code) || ''
        if (notSupportCodTipsCode !== '') {
          daEventCenter.triggerNotice({
            daId: '1-20-1-3',
            extraData: {
              order_id: orderData.billno || '',
              fail_reason: notSupportCodTipsCode
            }
          })
        }
        this.setOriginPaymentMethod({ paymentMethod: order.payment_method })
        this.handleChangePayment({
          paymentLogo: order.paymentLogo,
          paymentTitle: order.paymentTitle ? order.paymentTitle : order.payment_method,
          paymentMethod: order.payment_method
        })
        order.orderExtend = Object.assign(
          {},
          { insurancePrice: {}, codPrice: {} },
          order.orderExtend || {}
        )
        this.orderInfo = order
        this.assignState({ selectedIdealCode: order.payment_method })
      } else {
        order = this.orderInfo
      }
      const orderGoodsList = order?.oldOrderGoodsList || order?.orderGoodsList
      const membership_order_scene =
        order.order_type == 5
          ? 1
          : orderGoodsList.filter(item => item.is_prime_goods)?.length
            ? 2
            : ''

      const params = {
        country: order.shipping_country_code,
        billno: order.billno,
        membership_order_scene: membership_order_scene,
        order_currency: order.currency_code,
        xtra_order_scene: order.xtra_order_scene || ''
      }

      if (this.getPaymentsOrderDetails?.length) {
        params.order_details = this.getPaymentsOrderDetails
          .map(cur => `${cur.businessModel}:${cur.companyId}`)
          .join(',')
      }

      // 判断是否为店铺商品属性
      const checkVirtualGoodsStroeType = orderDetails => !!orderDetails?.some?.(item => item.businessModel == 1 || (item.businessModel == 0 && item.companyId == 75) || item.businessModel == 4)
      payCardPrefetchFn(order.payment_type)
      const [ json, renewalLimitList ] = await Promise.all([
        schttp({
          url: '/api/orders/base/getPayments/query',
          params
        }),
        this.queryAutoRenewalLimitPayType(order)

      ])
      if (json.code == 0 && json.info) {
        
        const {
          PaymentABT,
          PayInline = {},
          PayPalVaultingnew = {},
          businessModeNewS = {},
          Platformitemsvaultingoption = {},
          PaymentUnsignExp = {},
          PaymentsignExp = {},
          afterpaySavedword = {},
          ApplepayDropoffOptimization = {},
          OzowBanklistExperiment = {},
        // eslint-disable-next-line @shein-aidc/abt/abt
        } = await abtservice.getUserAbtResult({
          posKeys: 'PaymentABT,PayInline,PayPalVaultingnew,Businessmodepay',
          newPosKeys: 'Platformitemsvaultingoption,businessModeNewS,PaymentUnsignExp,PaymentsignExp,afterpaySavedword,ApplepayDropoffOptimization,OzowBanklistExperiment',
        })

        // 订单非卡token支付方式批量处理
        if (Array.isArray(json?.info?.payments)) {
          json.info.payments = json.info.payments.map(item => {
            if (item.sign_support == 1 && PaymentUnsignExp?.p?.PaymentSignupShow === 'Show') {
              item.needToSign = true
              self?.initNoCardToKenSignInfo(item)
            } else {
              // 不支持签约
              item.needToSign = false
            }
            if (PaymentsignExp?.p?.PaymentSignOnetime?.includes?.(item?.code) && item.paymentSignUp?.[0]?.id) {
              item.supportOneTimePay = true
              self?.initNoCardToKenSignInfo(item)
            } else {
              item.supportOneTimePay = false
            }
            return item
          })
        }

        // 是否给ebanx-ozow的银行列表赋值Abt
        const ozowBanklistShow = OzowBanklistExperiment?.p?.OzowBanklistShow === 'Show'
        if(!ozowBanklistShow){
          // 不展示银行
          json.info.payments = json?.info?.payments?.map(item => {
            if(item.code === 'ebanx-ozow'){
              item.bank_list = []
            }
            return item
          }) || []
        }

        // 付费会员随单购
        const isChoosePrime = (orderGoodsList || []).some(item => !!item.is_prime_goods)
        // 超省卡
        const isXtraGoods = (orderGoodsList || []).some(item => !!item.is_xtra_goods)
        // 是自动续费
        const isRenewal = order.vip_order_source == 1
        
        // 如果afterpay的token快捷支付的abt关闭，则在
        if((PaymentUnsignExp?.p?.PaymentSignupShow !== 'Show') && (isChoosePrime || isXtraGoods) && isRenewal) {
          // 在list中的支付方式不受限制，否则置灰
          json.info.payments.map(item => {
            if(item.code === 'afterpay-card'){
              item.disabled_reason = 'auto_renewal_limit'
              item.enabled = 0
            }
            return item
          })
        }

        if(renewalLimitList.length > 0){
          // 在list中的支付方式不受限制，否则置灰
          json.info.payments.map(item => {
            if(!renewalLimitList.includes(item.code)){
            // if(!renewalLimitList.some(item.code)){
            // if(['routepay-cardinstallment', 'routepay-card'].indexOf(item.code) === -1){
              if (item.enabled === 1){
                item.disabled_reason = 'auto_renewal_limit'
              } 
              item.enabled = 0
            }
            // 当PayPal-GApaypal为自动续费限制支付方式时，如果不为快捷支付场景则需置灰
            if (
              renewalLimitList?.includes?.(item.code) && 
              item.code === 'PayPal-GApaypal' &&
              !this.checkPaypalGAExpress({
                hasStore: checkVirtualGoodsStroeType(this.getPaymentsOrderDetails),
                paypalSignAbtValue: PayPalVaultingnew?.param || '',
                ppgvAccountInfo: this.getPpgvAccountInfo(item)
              })
            ) {
              if (item.enabled === 1){
                item.disabled_reason = 'auto_renewal_limit'
              } 
              item.enabled = 0
            }

            return item
          })
        }

        self.assignState({
          payments: json?.info?.payments || [],
          binDiscountInfo: json?.info?.bin_discount_info || {},
          binDiscountList: json?.info?.bin_discount_list || []
        })
        self.initPaypalSignAbt({
          paymentMethods: json?.info?.payments || [],
          PayPalVaultingAbtInfo: PayPalVaultingnew?.param || '',
          Platformitemsvaultingoption: Platformitemsvaultingoption?.param || ''
        })

        
        self.initPayInlineAbtInfo(PayInline)
        self.initKlarnaPayInlineSDK()
        
        self.initAfterpaySignAbt(
          json?.info?.payments || [],
          {
            PaymentUnsignExp,
            afterpaySavedword
          },
          isChoosePrime || isXtraGoods
        )
        // 超省卡随单购
        const isChooseXtra = order.xtra_order_scene == 2
        json.info.payments = self.filterPrimePayments({
          paymentMethods: json.info.payments,
          isChoosePrime,
          isChooseXtra
        })

        if (PaymentABT) {
          json.info.payments = self.filterPaymentByAbt({
            paymentMethods: json.info.payments,
            PaymentABT
          })
        }
        json.info.payments = self.filterPaymentsByBusinessModePayAbt({
          paymentMethods: json.info.payments,
          abtInfo: businessModeNewS
        })

        var _paymentCbs = self.paymentCbs(order)
        let payments = json.info.payments.filter(item => {
          if (item.code == 'cod' && order.isOrderSupportCod == 0) {
            item.enabled = 0
          }

          /* 币种限制不在这里处理 */
          if (['ebanx-oxxo', 'ebanx-card', 'ebanx-spei'].indexOf(item.code) > -1) {
            if (['ebanx-card', 'ebanx-spei'].indexOf(item.code) > -1 && !order.isSupportEbanx) {
              item.is_display = 0
            }
          }
          try {
            if (
              applePayMethod.includes(item.code) &&
              !(window.ApplePaySession && ApplePaySession.canMakePayments())
            ) {
              item.is_display = 0
            } else {
              let version = 3
              if (window.ApplePaySession) {
                version = 14
                while (!window.ApplePaySession.supportsVersion?.(version)) {
                  version--
                }
              }
              if (applePayMethod.includes(item.code)) {
                let supportedNetworks = []
                item.card_logo_list = item.card_logo_list?.filter?.(logo => {
                  const cardInfo = apple_pay_map[logo]
                  // 如果版本高于当前applepay支持的版本，或者apple_pay_map中无对应的版本信息，则不展示
                  if (cardInfo && cardInfo.sdk_version && Number(cardInfo.sdk_version) <= version) {
                    supportedNetworks.push(cardInfo.name)
                    return true
                  }
                  return false
                }) || []
                if (ApplepayDropoffOptimization?.param?.ApplepayCardlogoShow != 'Show') {
                  item.card_logo_list = []
                }
                item.supportedNetworks = supportedNetworks
                item.version = version
              }
            }
          } catch (e) {
            item.is_display = 0
          }

          if (item.is_display == 1) {
            if (
              ((order && item.code == order.payment_method) ||
                item.code == order.paymentSuggestion) &&
              item.enabled == 1
            ) {
              item.checked = true
            }
            for (var prop in _paymentCbs) {
              if (item.code == prop) {
                item.cb = _paymentCbs[prop].cb
                return item
              }
            }
          }
        })

        // 有金额限制的支付方式，若不满足则置灰
        var newPayList = payments.slice(0)
        if (order.paymentMethodLimitInfo) {
          newPayList.forEach(function (item) {
            if (+order.paymentMethodLimitInfo[item.code] == 0 && item.enabled == 1) {
              item.enabled = 0
            }
          })
        }

        payments = newPayList

        self.assignState({ payments })
        self.initBankLists(payments)
        self.inAddressFilter()
        self.initPayInlineSession({ paymentMethods: json.info.payments || [] })
        ;(async () => {
          await asyncLoadFile({
            label: 'script',
            attrs: {
              src: 'https://pay.google.com/gp/p/js/pay.js',
              async: 'async'
            }
          }).then(() => {
            self.googlePayInit()
          })
        })()

        if (isOrderList) {
          self.changeRootStatus({
            loading: false
          })
          self.handleEditPayment()
        }
      }
    },
    pickParamsForAutoRenewal(order){
      // --ordertype=1代表普通订单或者随单购订单，继续判断
      // 商品行ordergoodslist下面is_xtra_goods 是否超省卡商品
      // 商品行ordergoodslist下面is_prime_goods 是否付费会员商品
      // --ordertype=5代表单独购付费会员
      // --ordertype=6代表单独购超省卡
      const params = {
        limit_scene: 1,
        uvip_type: 1
      }
      let list = []
      switch (order.order_type) {
        case 1:
          params.limit_scene = 1
          list = order?.oldOrderGoodsList || order?.orderGoodsList
          if (list.filter(item => item.is_prime_goods)?.length) {
            params.uvip_type = 0
          }
          break
        case 5:
          params.limit_scene = 2
          params.uvip_type = 0
          break
        case 6:
          params.limit_scene = 2
          params.uvip_type = 1
          break
      }
      return params
    },
    async queryAutoRenewalLimitPayType(order){
      if(order?.vip_order_source !== 1){
        return []
      }
      const params = this.pickParamsForAutoRenewal(order)
      const res = await schttp({
        method: 'GET',
        url: '/api/checkout/autoRenewalLimitPayType/get',
        params
      })
      if (res.code === '0') {
        return res?.info?.pay_type || []
      }
      return []
    },
    filterPaymentByAbt({ paymentMethods, PaymentABT }) {
      let newPayments = paymentMethods.concat([])
      if (PaymentABT && PaymentABT.param && PaymentABT.param.indexOf('paymentoff=') > -1) {
        let abtPayments = PaymentABT.param.split('=')[1]
        if (abtPayments) {
          abtPayments = abtPayments.split(',')
          if (abtPayments.length) {
            const filterPayment = newPayments.filter(item => !abtPayments.includes(item.code))
            // 如果过滤出来的都不可用，则取消过滤，所以至少有一个支付可用
            if (filterPayment.some(item => item.enabled == 1)) {
              newPayments = filterPayment

              if (
                SaPageInfo.page_param.abtest &&
                SaPageInfo.page_param.abtest.indexOf(PaymentABT.posKey) > -1
              // eslint-disable-next-line no-empty
              ) {
              } else {
                // eslint-disable-next-line @shein-aidc/abt/abt
                const abtestVal = abtservice.getUserAbtResultForAnalysis({
                  posKeys: 'PaymentABT'
                }).sa
                if (SaPageInfo.page_param.abtest) {
                  SaPageInfo.page_param.abtest += ',' + abtestVal
                } else {
                  SaPageInfo.page_param.abtest = abtestVal
                }
              }
            }
          }
        }
      }
      return newPayments
    },

    // in地址支付方式筛选
    inAddressFilter: function () {
      if (this.orderInfo.shipping_country_id != 100) {
        var _arr = []
        $.each(this.payments, function (index, item) {
          if (item.code != 'PayU' && item.code != 'Paytm') {
            _arr.push(item)
          }
        })
        // this.payments = _arr
        this.assignState({ payments: _arr })
      }
    },
    handleShowBanksDrawer: function () {
      const self = this
      // self.pageStatus.bankDirection = 'bottom'

      // setTimeout(function () {
      //   self.pageStatus.showBanksDrawer = true
      //   setTimeout(function () {
      //     self.pageStatus.cachePaymentsCode = ''
      //     self.pageStatus.showPaymentsDrawer = true
      //     self.pageStatus.bankDirection = 'right'
      //   }, 1000)
      // }, 500)

      //先展示支付选择框
      self.pageStatus.cachePaymentsCode = ''
      self.pageStatus.showPaymentsDrawer = true
      //self.pageStatus.bankDirection = 'right'

      //再展示银行选择框
      self.pageStatus.showBanksDrawer = true

      self.changeRootStatus({
        loading: false
      })
    },

    isRelationBillno(billno = '') {
      if (!billno) return false
      return typeof billno?.startsWith === 'function' ? billno?.startsWith('U') : billno?.indexOf('U') === 0
    },

    async getPayPreCardRouting (order) {
      const params = {
        routeCode: order?.payment_method
      }
    
      if (this.isRelationBillno(order?.billno)) {
        params.relationBillno = order?.billno
      } else {
        params.billno = order?.billno
      }
    
      if ('bin' in order) {
        params.bin = order?.bin
      }
    
      if ('tokenId' in order) {
        params.tokenId = order?.tokenId
      }
    
      let result = {}
      await schttpSync({
        url: '/api/checkout/payPreCardRouting/get',
        params: params,
        success: res => {
          if (res?.code == 0 && res?.info?.payMethod) {
            // eslint-disable-next-line require-atomic-updates
            order.payment_method = res.info.payMethod
            result = res
          }
        }
      })

      return result
    },

    async handlePaynow(opt) {

      if (this.orderInfo?.paymentMethodLimitInfo?.[this.orderInfo?.payment_method] === false) {
        this.pageStatus.showPaymentsDrawer = true
        return
      }
      const selectedPayCode = this.pageStatus?.cachePaymentsCode?.code || ''
      const selectedPayment = this.payments.filter(item => {
        //如果切换了支付，pageStatus.cachePaymentsCode会有值
        if (selectedPayCode) {
          if (item.code == selectedPayCode) return item
        } else {
          //如果没切换支付，pageStatus.cachePaymentsCode不会有值，所以走原来逻辑
          if (item.checked) return item
        }
      })[0]
      // 详情页 - 用户选择切换支付方式后点击pay now
      if (opt?.isChoosePayNow) {
        // ppga非签约支付需用户主动触发paypal按钮，因此不能直接发起订单生成，否则会有问题
        if (
          selectedPayment.code === 'PayPal-GApaypal' &&
          (!this.paypalGaVault?.valid || !this.isContinueSign)
        ) {
          return
        }
      }

      // paytm-upi 检测 payerAccount 填写
      if (selectedPayment && ['cashfree-upi', 'Paytm-UPI'].indexOf(selectedPayment.code) > -1) {
        const { payerAccount } = this.paymentOption[selectedPayment.code]
        if (payerAccount == '' || payerAccount.length > 32) {
          this.handleEditPayment()
          return
        }
      }
      const self = this
      const orderInfo = this.orderInfo
      const { shipping_country_name, billno, shipping_country_id } = orderInfo

      // 逆向操作限制主题站点
      if (selectedPayment?.code != 'PayPal-Venmo') {
        const resInfo = await isSupportDistributionCountrySer(orderInfo.billno)
        if (resInfo?.info?.changeSite) {
          return this.jumpMask({
            url: `/user/orders/detail/${billno}`,
            result: 0,
            shipping_country_name,
            shipping_country_id
          })
        }
      }
      this.changeUserRootSLoadingStatus?.({
        maskTransparent: true
      })
      this.changeRootStatus({
        loading: true
      })
      const payment_id = selectedPayment && selectedPayment.id
      const shipping_method_id = ''
      const coupon = orderInfo.couponPrice && orderInfo.couponPrice.amount
      const points = orderInfo.point

      if (!selectedPayment) {
        this.changeRootStatus({
          loading: false
        })
        this.handleEditPayment()
        self.sendBi(payment_id, shipping_method_id, coupon, points, '0', '3', billno, {
          payment_code: selectedPayment?.code
        })
        return
      }

      this.payment_code = selectedPayment.code

      // if (['apacpay-thbanktransfer', 'apacpay-vnbanktransfer'].indexOf(selectedPayment.code) > -1) {
      //   if (!this.selectBankCode[selectedPayment.code].id) {
      //     if (!this.bankOptions[selectedPayment.code]) {
      //       const apacpayBankTimer = setInterval(
      //         () => {
      //           if (this.bankOptions[selectedPayment.code]) {
      //             window.clearInterval(apacpayBankTimer)
      //             this.handleShowBanksDrawer()
      //           }
      //         }, 500
      //       )
      //     } else {
      //       this.handleShowBanksDrawer()
      //     }

      //     return
      //   }
      // }

      // if (['worldpay-ideal', 'adyen-ideal', 'adyen-eps', 'Paytm-netbank'].indexOf(selectedPayment.code) > -1) {
      //   if (!this.selectBankCode[selectedPayment.code].id) {
      //     this.handleShowBanksDrawer()
      //     return
      //   }
      // }

      // 需要选择银行
      // const needChoseBank = this.banksConfig?.[selectedPayment.code]?.list?.length &&
      //   this.banksConfig?.[selectedPayment.code]?.bankCode == -1
      // // 需要收集tel/email信息
      // const needUserInfo = (selectedPayment?.collect_email == 1 && !this.capitecDialog?.email) ||
      //   (selectedPayment?.collect_phone_number == 1 && !this.capitecDialog?.phone)
      const isDlocalEft = this.payment_code === 'dlocal-eft' && 
      this.banksConfig?.[selectedPayment.code]?.bankCode === 'Capitec bank' && 
        (!this.capitecDialog?.email ||
        !this.capitecDialog?.phone)
      // dlocal-eft+非Capitec bank，不收集tel/email
      const isDlocalEftBankOthers = this.payment_code === 'dlocal-eft' && 
        this.banksConfig?.[selectedPayment.code]?.bankCode != 'Capitec bank'  

      // 不是'ebanx-boleto', 'dlocal-boleto', 'adyen-mbway'(下面都有自己的判断逻辑)
      const isNotSpecial = selectedPayment.code != 'adyen-mbway' && selectedPayment.code != 'dlocal-boleto' && selectedPayment.code != 'ebanx-boleto'


      const isDlocalPse = this.payment_code === 'dlocal-pse' && !this.capitecDialog?.email
      // 弹起选择银行/收集tel/email信息弹框
      if (!isDlocalEftBankOthers && isNotSpecial && (isDlocalEft || isDlocalPse)) {

        // 先展示支付选择框
        // this.pageStatus.cachePaymentsCode = ''
        // this.pageStatus.showPaymentsDrawer = true

        // 先展示银行选择框
        this.handleStartSelectBank(selectedPayment, true)
        this.changeRootStatus({
          loading: false
        })
        return
      }

      if (selectedPayment.code == 'worldpay-qiwi') {
        // eslint-disable-next-line no-empty
        if (opt && opt.type == 'qiwi-phone') {
        } else {
          this.changeRootStatus({
            loading: false
          })
          this.qiwi.phoneShow = true
          return
        }
      }
      // fix 仅订单详情需要做这个提示判断
      if (['OrderDetail', 'XtraDetail'].includes(this.moduleCheckoutScene) && this.checkShowFpxTips(selectedPayment?.code)) {
        this.changeRootStatus({ loading: false })
        const res = await this.showFpxTips(selectedPayment.code)
        // 点击弹窗的关闭按钮
        if (res === 'cancel') {
          return true
        }
        this.changeUserRootSLoadingStatus?.({
          maskTransparent: true
        })
        this.changeRootStatus({ loading: true })
      }
      //adyen-mbway支付填写电话
      const needTel = selectedPayment.collect_phone_number == 1
      if (selectedPayment.code == 'adyen-mbway' && !opt?.mbwayConfirValid && needTel) {
        this.changeUserRootSLoadingStatus?.({
          maskTransparent: true
        })
        this.changeRootStatus({ loading: true })
        await this.showMbwayDialog()
        this.changeRootStatus({ loading: false })
        return
      }

      // br巴西站boleto邮箱
      const needEmail = selectedPayment.collect_email == 1
      if (
        ['ebanx-boleto', 'dlocal-boleto'].indexOf(selectedPayment.code) >= 0 &&
        !this.orderInfo.pay_url &&
        needEmail
      ) {
        // eslint-disable-next-line no-empty
        if (opt && opt.checkEmail) {
        } else {
          this.changeRootStatus({
            loading: false
          })
          this.initEmailTipsValEvt(orderInfo.payment_method == selectedPayment.code ? orderInfo.payEmail : '')
          this.emailTips.err = ''
          this.emailTips.show = true
          return
        }
      }

      let cbValue = ''
      switch (selectedPayment.code) {
        case 'Paytm-UPI':
        case 'cashfree-upi':
          cbValue = this.paymentOption[selectedPayment.code].payerAccount
          break
        // case 'worldpay-ideal':
        // case 'adyen-ideal':
        // case 'adyen-eps':
        // case 'apacpay-thbanktransfer':
        // case 'apacpay-vnbanktransfer':
        // case 'Paytm-netbank':
        //   cbValue = this.selectBankCode[selectedPayment.code]['id']; break
        case 'worldpay-qiwi':
          cbValue = this.qiwiPayPhone
          break
        case 'ebanx-boleto':
        case 'dlocal-boleto':
          cbValue = this.emailTips.val
          break
        default:
          cbValue = ''
          break
      }
      if (
        this.banksConfig?.[selectedPayment.code]?.list?.length &&
        this.banksConfig?.[selectedPayment.code]?.bankCode &&
        !this.banksConfig?.[selectedPayment.code]?.bankCode !== -1
      ) {
        cbValue = this.banksConfig?.[selectedPayment.code]?.bankCode
      }
      let isAsync = true
      if (applePayMethod.includes(selectedPayment.code)) {
        isAsync = false
        cbValue = orderInfo
      }

      //paypal-ga支付
      if (this.judgeNeedPayInlineChannel(selectedPayment.code) == 'paypal') {
        isAsync = false
      }

      // 现金支付
      const curTime = Date.parse(new Date()) / 1000
      const paymentType = +selectedPayment.payment_type || 0
      const expireTime = orderInfo.order_expire_time
        ? orderInfo.order_expire_time
        : orderInfo.expireCountdown
      if (paymentType === 2 && curTime >= expireTime) {
        this.changeRootStatus({
          loading: false
        })
        this.toggleOrderExpiredModal({ order: orderInfo })
        return
      }

      let orderRes = null

      let _order_type = ''
      if (['XtraDetail', 'BuyPrime'].includes(this.nowCheckoutScene)) {
        _order_type = this.nowCheckoutScene == 'XtraDetail' ? 
          'xtra_again' :
          this.nowCheckoutScene == 'BuyPrime' ? 'buyprime_again' : ''
      }

      checkSchttp({
        isAsync,
        opt: {
          method: 'POST',
          url: '/api/checkout/updateOrder/update',
          schttp: {
            needLogin: true
          },
          params: {
            billno: this.orderInfo.billno
          },
          data: {
            payment_id: selectedPayment.id,
            payment_code_unique: selectedPayment.code,
            editType: 'editOrderPaymentMethod',
            _order_type
          }
        },
        successCb: async res => {
          orderRes = { res }
          const flag = res.code == 0 && !res.info.order.isPaid
          if (flag) {
            var order = res.info.order

            // 前置路由接口返回数据
            let preRoutingRes = {}
            // 请求前置路由接口的【支付方式】
            const preRoutingPaymentList = gbCommonInfo?.PRE_ROUTING_PAYMENT_METHODS?.length ? gbCommonInfo?.PRE_ROUTING_PAYMENT_METHODS : PRE_ROUTING_PAYMENT_METHODS
            // applepay不走这里
            if (order?.payment_method !== 'routepay-applepay' && preRoutingPaymentList.includes(order?.payment_method)) {
              const payPreCardRoutingRes = await this.getPayPreCardRouting(order)
              if(payPreCardRoutingRes.code == 0) {
                preRoutingRes = payPreCardRoutingRes.info
                if(typeof window !== 'undefined' && window.googlePayment) {
                  window.googlePayment.routeId = preRoutingRes.routeId
                }
                selectedPayment.code = order.payment_method
              }
            } else {
              if(typeof window !== 'undefined' && window.googlePayment) {
                window.googlePayment.routeId = ''
              }
            }

            console.log('getPayPreCardRouting end >>>>', order, preRoutingRes)

            self.sendBi(payment_id, shipping_method_id, coupon, points, '1', '', billno, {
              payment_code: selectedPayment?.code
            })
            
            order.countryCode = orderInfo.countryCode
            if (applePayMethod.includes(selectedPayment.code)) {
              // self.changeRootStatus({
              //     loading: false
              // })
              self.applePay.applepaycb = self.paymentCbs(order, preRoutingRes)[selectedPayment.code].cb
              // self.applePay.show = true;
            }

            if (
              ['afterpay-card', 'afterpay-clearpay', 'afterpay-cashapp'].includes(
                selectedPayment.code
              )
            ) {
              let src = ''
              const isSandBox = ['debug', 'localhost'].includes(NODE_SERVER_ENV)
              if (
                selectedPayment.code == 'afterpay-card' ||
                selectedPayment.code == 'afterpay-cashapp'
              ) {
                src = isSandBox
                  ? 'https://portal.sandbox.afterpay.com/afterpay.js'
                  : 'https://portal.afterpay.com/afterpay.js'
              } else if (selectedPayment.code == 'afterpay-clearpay') {
                src = isSandBox
                  ? 'https://portal.sandbox.clearpay.co.uk/afterpay.js'
                  : 'https://portal.clearpay.co.uk/afterpay.js'
              }

              await asyncLoadFile({
                label: 'script',
                attrs: {
                  src
                }
              })
                .then(() => {})
                .catch(err => {
                  console.log('fail afterpay.js: ', err)
                })
            }

            if (
              this.chosenChannel == 'paypal' &&
              order.payment_method == 'PayPal-GApaypal' &&
              this.paypalGaBtn &&
              !this.isContinueSign
            ) {
              return
            }

            if (
              this.chosenChannel == 'paypal' &&
              order.payment_method == 'PayPal-bnpl' &&
              this.paypalBnplBtnShow
            ) {
              return
            }

            if (
              this.chosenChannel == 'paypal' &&
              order.payment_method == 'PayPal-Venmo' &&
              this.paypalVenmoBtnShow
            ) {
              this.venmoPay(order)
              return
            }

            if (['adyen-blikdirect', 'alipay-blik', 'routepay-blik'].includes(selectedPayment.code)) {
              this.$router.push(
                `${langPath}/checkout/blik_code_way?billno=${order?.billno}&scene=${this.moduleCheckoutScene}&paymentCode=${selectedPayment.code}`
              )

              this.changeRootStatus({
                loading: false
              })
              return
            }

            if (selectedPayment.code === 'paidy-paylater') {
              location.href = `${langPath}/checkout/paidy?billno=${order?.billno}&scene=${this.moduleCheckoutScene}`
              return
            }

            self.paymentCbs(order, preRoutingRes)[selectedPayment.code].cb(cbValue, () => {
              this.changeRootStatus({
                loading: !GooglePay.googlePayMethods.includes(selectedPayment.code)
              })
            })
          } else {
            if (res.code != 0 && res.tips) {
              this.showMaskTips(res.tips)
              this.changeRootStatus({
                loading: false
              })
            }
            self.sendBi(payment_id, shipping_method_id, coupon, points, '0', '3', billno, {
              payment_code: selectedPayment?.code
            })
          }
        },
        errorCb: err => {
          console.log(err)
        }
      })

      return orderRes
    },
    fetchOrderData(billno) {
      return new Promise(resolve => {
        schttp({
          method: 'GET',
          url: '/api/orders/base/get_order_info/get',
          params: { billno }
        }).then(data => {
          resolve(data.info)
        })
      })
    },
    // boleto支付邮箱校验
    checkEmail: function () {
      var val = $.trim(this.emailTips.val)
      if (val) {
        var reg = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,6}|[0-9]{1,3})(\]?)$/
        if (reg.test(val)) {
          this.emailTips.err = ''
          this.emailTips.val = val
          this.emailTips.show = false
          this.handlePaynow({ checkEmail: true })
        } else {
          this.emailTips.err = this.language.SHEIN_KEY_PWA_16237
        }
      } else {
        this.emailTips.err = this.language.SHEIN_KEY_PWA_15741
      }
    },
    // qiwi phone check
    qiwiPhoneCheck: function () {
      var _reg_a = new RegExp('[0-9]{5}$')
      var _reg_b = new RegExp(/^[\d\s+-]*$/g)
      var _val = this.qiwiPayPhone.trim()

      if (!_val || !_reg_b.test(_val) || !_reg_a.test(_val.replace(/[^\d]/g, ''))) {
        this.qiwi.phoneError = 1
        return true
      } else {
        this.qiwi.phoneError = 0
        return false
      }
    },

    clickQiwiPay: function () {
      if (this.qiwiPhoneCheck()) return
      this.handlePaynow({ type: 'qiwi-phone' })
    },
    closeQiwiModal: function () {
      this.qiwi.phoneShow = false
      this.qiwi.phoneError = 0
      this.qiwiPayPhone = ''
    },
    googlePayInit: function () {
      // 谷歌pay初始化-检测浏览器是否支持谷歌pay
      var _this = this

      window.googlePayment = new GooglePay({
        scene: this.nowCheckoutScene,
        orderBuyType: this.orderBuyTpInfo?.(this.orderInfo)
      })

      if (this.payments && this.payments.length) {
        appEventCenter.$on('isGooglePayLoading', state => {
          _this.changeRootStatus({
            loading: !!state
          })
        })

        appEventCenter.$on('showGooglePay', ({ code } = {}) => {
          googlePayment.getConfig({
            code,
            cb: () => {
              _this.googlePay.show = true
            }
          })
        })

        appEventCenter.$on('cancelGooglePay', () => {
          _this.cancelGoogle(true)
        })

        appEventCenter.$on('errorGooglePay', (msg, extraInfo = {}) => {
          const { is_guide, billno, errorCode } = extraInfo
          _this.updateHasGuide(Number(is_guide) === 1)
          _this.$nextTick(() => {
            _this.changeRootStatus({
              loading: false
            })
            _this.showMaskTips({ text: msg, billno, errorCode })
          })
        })

        //判断是否可用和初始化Googlepay参数分开
        $.each(this.payments, function (index, item) {
          if (GooglePay.googlePayMethods.includes(item.code)) {
            if (item.enabled == 1) {
              googlePayment.onGooglePayLoaded(
                { createDom: true, code: item.code },
                function (valid) {
                  _this.googleValid = valid
                  if (valid && getQueryString({ key: 'googlepay' }) && item.checked) {
                    googlePayment.setOrderInfo({
                      ..._this.orderInfo,
                      totalPrice: _this.orderInfo.totalPrice.amount,
                      totalPriceWithGovTax: _this.orderInfo.including_gov_tax_price.amount
                    })
                    //_this.googlePay.show = true
                    appEventCenter.$emit('showGooglePay', { code: item.code })

                    sa('send', {
                      page_name: 'page_checkout_again',
                      activity_name: 'expose_google_pay_popup',
                      activity_param: ''
                    })
                  } else if (!valid) {
                    if (getQueryString({ key: 'googlepay' }) && item.checked) {
                      _this.showMaskTips(_this.language.google_pay_invalid)
                    }

                    Vue.set(_this.payments[index], 'enabled', 0)
                    if (item.checked) {
                      item.checked = false
                    }
                  }
                }
              )
            }
            //return false
          }
        })
        googlePayment.setPaymentMethods([...this.payments])
      }
    },
    cancelGoogle: function () {
      this.googlePay.show = false
      // // this.status.checkout = 0;
      // if (!autoDisplay) {
      //   sa('send', {
      //     page_name: 'page_checkout_again',
      //     activity_name: 'expose_google_pay_popup_disappear',
      //     activity_param: ''
      //   })
      // }
    },
    toApplePay: function () {
      // 防止重复点击
      if (this.applePayTwiceFlag) return
      this.applePayTwiceFlag = true
      setTimeout(() => {
        this.applePayTwiceFlag = false
      }, 800)
      this.assignState({
        applePayShow: false
      })
      this.applePay.applepaycb && this.applePay.applepaycb(this.orderInfo)
    },
    closeApplePayModel: function () {
      this.assignState({
        applePayShow: false
      })
      apiReport.report({
        apiPath: 'pay/applepay-cancel-by-user',
        billno: this.orderInfo?.billno,
        code: 'orderDetail',
        paymentMethod: this.orderInfo?.payment_method
      })
    },
    sendBi: function (
      payment_method_id,
      shipping_method_id,
      coupon,
      points,
      result,
      result_reason,
      order_id,
      extraData = {}
    ) {
      if (payment_method_id == undefined) payment_method_id = ''
      if (shipping_method_id == undefined) shipping_method_id = ''
      coupon == undefined || coupon == '' ? (coupon = '0') : (coupon = '1')
      points == undefined || points == '0' ? (points = '0') : (points = '1')
      let storeInfo = [] // 店铺类型`店铺ID`商品数量
      let mallCodeSet = new Set()
      let carts =
        this.module_name === 'OrderDetail'
          ? this.orderInfo?.oldOrderGoodsList
          : this.orderInfo?.orderGoodsList
      carts?.forEach(cartItem => {
        let store = storeInfo.find(item => item.store_code == cartItem.store_code)
        if (store) {
          store.productNum += +cartItem.quantity
        } else {
          let store_type =
            cartItem?.business_model == 0 && cartItem?.store_code == 1 && !cartItem?.store_type
              ? 2
              : cartItem.store_type
          storeInfo.push({
            store_code: cartItem.store_code,
            store_type: store_type || '',
            productNum: +cartItem.quantity
          })
        }
        mallCodeSet.add(cartItem.mall_code)
      })
      let label_id = 0
      if (typeof window != 'undefined') {
        label_id = sessionStorage.getItem('filterType')
        if (label_id == 'none' || !label_id) label_id = 0
      }
      sa('send', {
        activity_name: 'click_place_order',
        activity_param: {
          payment_method_id: payment_method_id + '',
          shipping_method_id: shipping_method_id + '',
          coupon: coupon, // coupon != '' 使用
          points: points, // points > 0   使用
          result: result,
          result_reason: result_reason,
          order_id: order_id,
          store_info: storeInfo
            .map(item => `${item.store_type}\`${item.store_code}\`${item.productNum}`)
            .join(','),
          mall_code: [...mallCodeSet].join(','),
          label_id,
          // is_vaulting
          ...(extraData?.payment_code === 'PayPal-GApaypal'
            ? {
              is_vaulting: this.paypalGaVault.type === PPGA_CHECK_TYPE.CHECKED ? '1' : '0'
            }
            : {})
        }
      })
    },
    initPayInlineAbtInfo: function (abtType) {
      let payInlineInfo = {}
      if (abtType.param) {
        let channel = abtType.param.split('&')
        channel.forEach(item => {
          let inlineInfoItem = item.split('=')
          if (inlineInfoItem[1]) {
            payInlineInfo[inlineInfoItem[0]] = inlineInfoItem[1].split(',')
          }
        })
      }
      this.payInlineAbtInfo = payInlineInfo
      this.getInlineAbt()
    },

    klarna: function (paymentMethod) {
      if (this.showWidgetForm != 1) {
        return
      }
      let _this = this
      if (window.KlarnaSDKIsLoad && this.payInlineSessionInfo[paymentMethod]) {
        try {
          SILogger.logInfo({
            tag: 'Klarna_sdk_log',
            message: 'klarna start load',
            params: {
              scene: 'load',
              client_token: this.payInlineSessionInfo[paymentMethod]?.clientToken,
              url: typeof window != 'undefined' ? window.location.href : ''
            }
          })
          Klarna.Payments.init({
            client_token: this.payInlineSessionInfo[paymentMethod].clientToken
          })
          Klarna.Payments.load(
            {
              container: `#${paymentMethod}-payments-container`,
              payment_method_category:
                this.payInlineSessionInfo[paymentMethod].paymentMethodCategory
            },
            res => {
              this.showWidgetForm = res.show_form ? 2 : 0
              if (!res.show_form) {
                this.recordPayLineErr('inline/klarna/start', res)
              }
            }
          )
          Klarna.Payments.on('fullscreenOverlayShown', function (category) {
            // Convert "pay_over_time" to "pay-over-time"
            let formattedCategory = category.replace(/_/g, '-')
            // Get fullscreen iframe element from category
            let fullscreen = document.getElementById('klarna-' + formattedCategory + '-fullscreen')
            // Check if fullscreen iframe exists here and handle it somehow if it does not?
            // Apply CSS transformation
            if (fullscreen) {
              let fullscreenZIndex = fullscreen.style['z-index']
              fullscreen.style.transform = `translate3d(0, 0, ${fullscreenZIndex}px)`
              fullscreen.style.WebkitTransform = `translate3d(0, 0, ${fullscreenZIndex}px)`
            }
          })
          setSessionStorage({ key: 'klarnaClientCompareKey', value: this.payInlineSessionInfo[paymentMethod].clientToken || '' })
        } catch (e) {
          _this.showWidgetForm = 0
          this.recordPayLineErr('inline/klarna/start', { err: e })
        }
      }
    },
    klarnaAsyncCallback: function () {
      window.KlarnaSDKIsLoad = true
      const selectedPayment = this.payments.filter(item => {
        if (item.checked) return item
      })[0]
      if (selectedPayment?.code) {
        this.klarna(selectedPayment.code)
      }
    },
    loadWidget: function (code) {
      this.showWidgetForm = 1
      let payInlineChannel = this.judgeNeedPayInlineChannel(code)
      if (code !== 'PayPal-Venmo') {
        this.paypalVenmoBtnShow = false
      }
      if (payInlineChannel) {
        this[payInlineChannel] && this[payInlineChannel](code)
      }
    },
    initCybersource: function () {
      if (window.GB_cybersource_uuid) return
      window.TPM?.run({
        marketing: 'Cybersource',
        method: '_loadCybersource'
      })
    },
    getPpgvAccountInfo (ppgaMethodItem) {
      return ppgaMethodItem?.paymentSignUp?.[0] ?? {}
    },
    checkPaypalGAExpress: function ({
      hasStore,
      paypalSignAbtValue,
      ppgvAccountInfo
    } = {}) {
      const signAbtParams = parseQueryString(paypalSignAbtValue || '')
      let isExpress = true

      if (
        signAbtParams?.unsigned === 'close' &&
        !ppgvAccountInfo?.signUpEmail
      ) {
        isExpress = false
      }

      if (
        signAbtParams?.signed === 'close' &&
        ppgvAccountInfo?.signUpEmail
      ) {
        isExpress = false
      }

      if (hasStore) {
        isExpress = true
      }

      return isExpress
    }
  }
}
