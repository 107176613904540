<template>
  <div 
    class="repurchase-product-list"
    :class="{
      'show-border': showBorder
    }"
  >
    <div class="repurchase-product-list__content">
      <div
        v-for="(child, childIndex) in goodsList"
        :key="childIndex"
        class="repurchase-goods-item"
        :class="{ 'is-disabled': disabled }"
      >
        <RepurchaseProductItem :item="child" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { cloneDeep } from 'lodash'
import RepurchaseProductItem from './RepurchaseProductItem'

const props = defineProps({
  repurchaseGoods: {
    type: Array,
    default: () => []
  },
  language: {
    type: Object,
    default: () => {}
  },
  disabled: {
    type: Boolean,
    default: false
  },
  showBorder: {
    type: Boolean,
    default: false
  },
})

const goodsList = computed(() => {
  const listMap = {}
  cloneDeep(props.repurchaseGoods).forEach(item => {
    item.quantity = item.quantity || 1
    if (listMap[item.sku_code]) {
      listMap[item.sku_code].quantity += item.quantity
    } else {
      listMap[item.sku_code] = { ...item }
    }
  })
  return Object.values(listMap).sort((a, b) => b.quantity - a.quantity)
})
</script>

<style lang="less">
.repurchase-product-list {
  position: relative;
  padding: 24/75rem 0;

  &.show-border {
    border-bottom: 1px solid @sui_color_gray_weak1;
  }

  &__content {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    text-align: center;
    height: 128/75rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.repurchase-goods-item {
  display: inline-flex;

  &.is-disabled {
    opacity: 0.5;
  }

  & + & {
    margin-left: 16/75rem;
  }
}
</style>
