export default {
  data() {
    return {
      module_name: 'OrderDetail', // OrderDetail or OrderList
      isShowReturnUnavailableModal: false,
      unavailableModal: false,
    }
  },
  methods: {
    toggleReturnUnavailableModal() {
      this.isShowReturnUnavailableModal = !this.isShowReturnUnavailableModal
    },
    toggleUnavailableModal() {
      this.unavailableModal = !this.unavailableModal
    }
  }
}
