<template>
  <div>
    <s-drawer
      :visible.sync="pageStatus.showPaymentsDrawer"
      :direction="'btt'"
      :append-to-body="true"
      :lock-scroll="true"
      class="payment-drawer order-payment-list"
      @close="handleDrawerClose"
      @open="handleDrawerOpen"
    >
      <template slot="top">
        {{ language.SHEIN_KEY_PWA_16132 }}
      </template>
      <s-alert type="info">
        {{ language.SHEIN_KEY_PWA_18148 }}
      </s-alert>
      <template v-if="!payments.length">
        <s-loading
          :mask-transparent="true"
          :show="true"
        />
      </template>
      <template v-else>
        <div
          class="chooce-payment-trans-contain-footer shipping-drawer-scroll-box"
          style="overflow-y: hidden; padding-bottom: 0"
          :da-expose-code="paymentPanelExposeCode"
        >
          <div class="mcheo-card">
            <ul class="payments-list">
              <li
                v-for="(payment, index) in payments"
                :key="index"
                v-expose="paypalExposeData(payment)"
                :class="[
                  { disabled: !paymentDisplay(payment.code) },
                  { 'more-padding-right': showOneTimeSignArrow(payment)}
                ]"
                :data-code="payment.code"
                @click.stop="choosePayment(index, payment)"
              >
                <template v-if="payment.code=='cod'">
                  <ChoocePaymentsItemCod
                    :payment="payment"
                    :pageStatus="pageStatus"
                    :banksConfig="banksConfig"
                    :orderInfo="orderInfo"
                    :language="language"
                    :paymentDisplay="paymentDisplay"
                    :getBankLogo="getBankLogo"
                    :getPaymentTitle="getPaymentTitle"
                    :paymentDes="paymentDes"
                    :clickDes="clickDes"
                    :handleStartSelectBank="handleStartSelectBank"
                    :onlinePayDiscountInfoValue="onlinePayDiscountInfoValue"
                    @clickItemCod="choosePayment(index, payment)"
                    @codTipExpose="codTipExpose"
                    @clickCodTips="clickCodTips"
                  />
                </template>
                <template v-else>
                  <div class="chooce-payment-flexbetween">
                    <div
                      class="card-left"
                      :class="{
                        'flex-auto':
                          banksConfig[payment.code] &&
                          banksConfig[payment.code].list &&
                          banksConfig[payment.code].list.length
                      }"
                    >
                      <div class="card-left-select">
                        <s-radio
                          v-if="payment.enabled == 1"
                          :value="checkSelect(payment)"
                          :label="true"
                          :disabled="
                            payment.enabled == 0 || (!paymentDisplay(payment.code) ? true : false) || isDisabledPay(payment)
                          "
                          margin-r="0"
                        />
                        <a
                          v-show="payment.enabled == 0"
                          href="javascript:;"
                          class="iconfont icon-icon-test"
                        ></a>
                      </div>
                      <img
                        v-if="payment.enabled == 1 && paymentDisplay(payment.code) && !isDisabledPay(payment)"
                        class="payment-src"
                        :src="transformImg({ img: getBankLogo(payment) || payment.logo_url })"
                      />
                      <img
                        v-else
                        class="payment-src"
                        :src="transformImg({ img: payment.gray_logo_url })"
                      />

                      <!-- <span v-if="paypalGaVault && paypalGaVault.valid && paypalGaVault.type == '1' && payment.code == 'PayPal-GApaypal'">{{ ppgvAccountInfo && ppgvAccountInfo.signUpEmail || language.SHEIN_KEY_PWA_19684 }}</span> -->

                      <span
                        :class="[
                          payment.enabled == 1 && paymentDisplay(payment.code) && !isDisabledPay(payment)
                            ? ''
                            : 'payment-disabled'
                        ]"
                      >
                        
                        <!-- <span html=""></span> -->
                        <span
                          v-if="payment.code === 'afterpay-card' && 
                            afterPayVault.title && 
                            afterPayVault.title === 'A' &&
                            !signupPayVault?.[payment?.code]?.useOneTimePay"
                          v-html="language.SHEIN_KEY_PWA_30872"
                        ></span>
                        <span
                          v-else
                        >{{ getPaymentTitle(payment) }}</span>

                        <i
                          v-if="paymentDes(payment)"
                          class="iconfont icon-pro icon-pro-help"
                          :test="paymentDes(payment)"
                          @click.stop="clickDes(payment)"
                        ></i>
                      </span>
                      <template
                        v-if="
                          (lang == 'ar' || lang == 'ar-en') &&
                            payment.code == 'cod' &&
                            orderInfo.orderExtend &&
                            orderInfo.orderExtend.codPrice &&
                            Number(orderInfo.orderExtend.codPrice.amount) > 0
                        "
                      >
                        <span class="off-tips cod-services-cost-tips">
                          {{ language.SHEIN_KEY_PWA_15143 }}
                          {{ orderInfo.orderExtend.codPrice.amountWithSymbol }}
                        </span>
                      </template>

                      <!-- 0元门槛优惠 -->
                      <s-label
                        v-if="
                          payment.enabled &&
                            onlinePayDiscountInfoValue(payment) &&
                            onlinePayDiscountInfoMinAmount(payment) == 0
                        "
                        type="promo"
                        class="payment-discount-zero"
                      >
                        <template v-if="onlinePayDiscountInfoValue(payment).discountType == 0">
                          <template v-if="GB_cssRight">
                            {{
                              language.SHEIN_KEY_PWA_14988 +
                                ' ' +
                                onlinePayDiscountInfoValue(payment).amount.amountWithSymbol
                            }}
                          </template>
                          <template v-else>
                            {{
                              onlinePayDiscountInfoValue(payment).amount.amountWithSymbol +
                                ' ' +
                                language.SHEIN_KEY_PWA_14988
                            }}
                          </template>
                        </template>
                        <template v-if="onlinePayDiscountInfoValue(payment).discountType == 1">
                          <template v-if="GB_cssRight">
                            {{
                              language.SHEIN_KEY_PWA_14988 +
                                ' ' +
                                onlinePayDiscountInfoValue(payment).percent +
                                '%'
                            }}
                          </template>
                          <template v-else>
                            {{
                              onlinePayDiscountInfoValue(payment).percent +
                                '% ' +
                                language.SHEIN_KEY_PWA_14988
                            }}
                          </template>
                        </template>
                      </s-label>
                    </div>
                    <template
                      v-if="
                        payment.enabled == 1 &&
                          paymentDisplay(payment.code) &&
                          banksConfig[payment.code] &&
                          banksConfig[payment.code].list &&
                          banksConfig[payment.code].list.length
                      "
                    >
                      <div class="order-bank-container">
                        <div
                          class="selected-bank-area"
                        >
                          <div
                            class="selected-bank-text"
                            :class="{ 'txt-error': banksConfig[payment.code].error }"
                          >
                            {{
                              banksConfig[payment.code].bankCode &&
                                banksConfig[payment.code].bankCode !== -1
                                ? ''
                                : language.SHEIN_KEY_PWA_15965
                            }}
                          </div>
                          <i 
                            :class="{ 'txt-error': banksConfig[payment.code].error }"
                            class="suiiconfont sui_icon_more_down_16px icon2"
                          >
                          </i>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <!-- 支付信息前置展示: 6+1 -->
                <!-- ['Paytm-UPI', 'cashfree-upi'] input输入 -->
                <div
                  v-if="
                    ['cashfree-upi', 'Paytm-UPI'].indexOf(payment.code) > -1 &&
                      payment.enabled == 1
                  "
                  class="paytm-upi-tips"
                  :class="{ 'error-input-item': paymentOption[payment.code].showError }"
                >
                  <p
                    v-show="
                      !(
                        (pageStatus.cachePaymentsCode &&
                          payment.code == pageStatus.cachePaymentsCode.code) ||
                        (!pageStatus.cachePaymentsCode && payment.checked)
                      ) && panlePaymentOption[payment.code].payerAccount !== ''
                    "
                  >
                    {{ panlePaymentOption[payment.code].payerAccount }}
                  </p>
                  <div
                    v-show="
                      (pageStatus.cachePaymentsCode &&
                        pageStatus.cachePaymentsCode.code == payment.code) ||
                        (!pageStatus.cachePaymentsCode && payment.checked)
                    "
                  >
                    <p>{{ language.SHEIN_KEY_PWA_16988 }}</p>
                    <p class="input-item">
                      <input
                        v-model.trim="panlePaymentOption[payment.code].payerAccount"
                        type="text"
                        name="paytm-upi-tips"
                        :placeholder="
                          payment.code == 'cashfree-upi'
                            ? language.SHEIN_KEY_PWA_17404
                            : language.SHEIN_KEY_PWA_16985
                        "
                        maxlength="32"
                        @input="checkValidatePaytmUPI(payment.code)"
                        @blur="checkValidatePaytmUPI(payment.code)"
                      />
                      <i class="iconfont icon-warn-out"></i>
                    </p>
                    <p class="error-tips">
                      {{ language.SHEIN_KEY_PWA_16986 }}
                    </p>
                  </div>
                </div>
                <!-- paypal记住卡号逻辑 -->
                <CheckPpgaType
                  v-if="
                    payment.code === 'PayPal-GApaypal' &&
                      payment.enabled == 1 &&
                      paymentDisplay(payment.code) &&
                      paypalGaVault &&
                      paypalGaVault.valid &&
                      paypalGaVault.express &&
                      selectedPaypal(payment)
                  "
                  :language="language"
                  :paypal-ga-vault="paypalGaVault"
                  :checked-label="PPGA_CHECK_TYPE.CHECKED"
                  class="ml-34"
                  @toggle="handleTogglePpgaType"
                />
                <!-- <CheckAfterPayType
                  v-if="
                    payment.code === 'afterpay-card' &&
                      payment.enabled &&
                      afterPayVault &&
                      afterPayVault.valid &&
                      selectedAfterpay(payment)
                  "
                  :language="language"
                  :afterPayVault="afterPayVault"
                  :checked-label="AFTERPAY_CHECK_TYPE.CHECKED"
                  class="ml-34"
                  @toggle="handleToggleAfterPayType"
                /> -->
                <CheckSignupPayType
                  v-if="
                    !['PayPal-GApaypal'].includes(payment.code) &&
                      payment.enabled &&
                      signupPayVault?.[payment.code]?.valid &&
                      checkSelect(payment)
                  "
                  :language="language"
                  :payment-code="payment.code"
                  :vault="signupPayVault?.[payment.code]"
                  :checked-label="SIGNUP_CHECK_TYPE.CHECKED"
                  class="ml-34"
                  @toggle="handleToggleSignupPayType"
                />
                <!-- 区域:分期支付信息展示 -->
             
                <template v-if="showInstallmentInfo(payment)">
                  <ItemInfoBnpl
                    scene="checkout"
                    :is-select="checkSelect(payment)"
                    :frontListItemsValue="frontListItemsValue(payment)"
                    :options="installmentInfo.bnpl_info"
                    :item="payment"
                    :language="language"
                  />
                </template>

                <!-- bnpl message -->
                <div
                  v-if="payment.code === 'PayPal-bnpl' && !showInstallmentInfo(payment)"
                  id="paypal-bnpl-message"
                  data-pp-message
                  data-pp-layout="text"
                  data-pp-text-color="black"
                  data-pp-logo-type="inline"
                  :data-pp-amount="totalPriceAmount"
                >
                </div>
               
                <!-- 区域:折扣优惠 -->
                <div
                  v-if="
                    isDislable(payment) &&
                      (isShowOnlinePayDiscount(payment) || isShowCardBinDiscount(payment) || isNowHadBinCoupons(payment))
                  "
                  class="discount-percent_tips ml-34"
                >
                  <!-- 卡bin优惠券 -->
                  <pay-bin-coupons 
                    v-if="['routepay-card', 'routepay-cardinstallment'].includes(payment.code) && isHadBinCoupons"
                    class="payment-options-item__coupons_txt"
                    scene="payment-options-item"
                    :is-pay-bin-coupons-add="isShowOnlinePayDiscount(payment) || isShowCardBinDiscount(payment)" 
                    :language="language"
                    :pay-code="payment.code"
                    :coupon-list="couponList"
                    :order-info="orderInfo"
                  />
                  <div
                    v-if="
                      isShowOnlinePayDiscount(payment) &&
                        onlinePayDiscountInfoValue(payment).discountType != 2
                    "
                    class="discount-tips-item"
                  >
                    <!-- 非0元门槛支付方式优惠 -->
                    <div
                      v-if="onlinePayDiscountInfoMinAmount(payment) != 0"
                      class="payment-discount-tip"
                      :style="{ color: IS_RW && '#FC4070' }"
                    >
                      <!-- 满减 -->
                      <span
                        v-if="onlinePayDiscountInfoValue(payment).discountType == 0"
                        class="off-tips"
                      >
                        {{
                          template(
                            onlinePayDiscountInfoValue(payment).min.amountWithSymbol,
                            onlinePayDiscountInfoValue(payment).amount.amountWithSymbol,
                            language.SHEIN_KEY_PWA_16207
                          )
                        }}
                      </span>

                      <!-- 折扣 -->
                      <span
                        v-if="onlinePayDiscountInfoValue(payment).discountType == 1"
                        class="off-tips"
                      >
                        {{
                          template(
                            onlinePayDiscountInfoValue(payment).min.amountWithSymbol,
                            onlinePayDiscountInfoValue(payment).percent + '%OFF',
                            language.SHEIN_KEY_PWA_16207
                          )
                        }}
                      </span>
                      <span
                        v-if="
                          onlinePayDiscountInfoValue(payment).discountType == 1 &&
                            onlinePayDiscountInfoValue(payment).limit_value &&
                            +onlinePayDiscountInfoValue(payment).limit_value.amount != 0
                        "
                        class="off-tips"
                      >
                        {{
                          language.SHEIN_KEY_PWA_19681.replace(
                            '{limitValue}',
                            onlinePayDiscountInfoValue(payment).limit_value.amountWithSymbol
                          )
                        }}
                      </span>
                    </div>
                    <div v-else>
                      <span
                        v-if="
                          onlinePayDiscountInfoValue(payment).discountType == 1 &&
                            onlinePayDiscountInfoValue(payment).limit_value &&
                            +onlinePayDiscountInfoValue(payment).limit_value.amount != 0
                        "
                        class="off-tips"
                      >
                        {{
                          language.SHEIN_KEY_PWA_19681.replace(
                            '{limitValue}',
                            onlinePayDiscountInfoValue(payment).limit_value.amountWithSymbol
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <!-- 随机立减 -->
                  <div
                    v-if="
                      onlinePayDiscountInfoValue(payment).discountType == 2 &&
                        randomDiscountAbt &&
                        payment.enabled
                    "
                    class="discount-tips-item"
                  >
                    <div
                      class="payment-discount-tip"
                      :style="{ color: IS_RW && '#FC4070' }"
                    >
                      {{ randomDiscountDes(payment) }}
                    </div>
                  </div>
                  <!-- 卡bin优惠信息展示 -->
                  <span
                    v-if="
                      isShowOnlinePayDiscount(payment) &&
                        isShowCardBinDiscount(payment) &&
                        cardBinDiscountDesc
                    "
                  >
                    +
                  </span>
                  <span
                    v-if="isShowCardBinDiscount(payment) && cardBinDiscountDesc"
                    class="discount-tips-item"
                  >
                    {{ cardBinDiscountDesc }}
                    <i
                      class="suiiconfont sui_icon_doubt_16px_2 icon"
                      @click.stop="handlePaymentOptionsOper('bin-discount-article')"
                    ></i>
                  </span>
                </div>

                <!-- 签约支付term信息展示 -->
                <SignupTermInfo
                  v-if="
                    payment.enabled &&
                      checkSelect(payment)"
                  :signup-account-info="signupAccountInfo"
                  :paymentCode="payment.code"
                  :language="language"
                />

                <!-- 区域:卡列表展示 -->
                <div
                  v-if="payment.card_logo_list && payment.card_logo_list.length"
                  class="card-logo"
                >
                  <ul>
                    <li
                      v-for="(img, i) in payment.card_logo_list"
                      :key="i"
                      class="card-logo-item"
                    >
                      <img :src="transformImg({ img })" />
                    </li>
                  </ul>
                </div>
                <!-- 区域:PayInline -->
                <template
                  v-if="
                    needPayInlinePaymentMethods &&
                      needPayInlinePaymentMethods.indexOf(payment.code) > -1
                  "
                >
                  <div
                    v-show="
                      ((pageStatus.cachePaymentsCode &&
                        pageStatus.cachePaymentsCode.code == payment.code) ||
                        (!pageStatus.cachePaymentsCode && payment.checked)) &&
                        showWidgetForm && payment.code !== 'PayPal-bnpl'
                    "
                    :id="payment.code + '-payments-container'"
                    style="margin-top: 0.32rem"
                    @click.stop
                  ></div>
                </template>
                <!-- paypal ga 签约提示按钮 -->
                <i
                  v-if="showOneTimeSignArrow(payment)"
                  class="iconfont icon-forward-down32px ppgv-arrow"
                  @click.stop="clickPaypalGaType(payment)"
                ></i>
              </li>
            </ul>
          </div>
        </div>
        <template slot="footer">
          <div
            class="ok-wrap"
            @click="handlePaymentOk"
          >
            <div v-show="paypalBtnShow && !applePayBtnShow">
              <div
                id="paypal-vault-button"
                data-scene="order"
              ></div>
            </div>
            <div v-show="paypalBnplBtnShow && !applePayBtnShow">
              <div
                id="paypal-bnpl-button"
                data-scene="order"
              ></div>
            </div>
            <ApplePayBtn
              v-if="!paypalBtnShow && !paypalBnplBtnShow && applePayBtnShow"
              @handleBtnClick="handlePaymentOk"
              :propsStyle="{ width: '100%', height: '1.06rem' }"
            ></ApplePayBtn>
            <button
              v-if="isDirectPay && paypalVenmoBtnShow && !applePayBtnShow"
              class="chooce-payment-btn-black chooce-payment-btn-block venmo-btn"
            >
              <img
                :src="IMG_LINK['blue-venmo-button']"
                class="venmo-btn-img"
              />
            </button>
            <s-button
              v-else-if="!paypalBtnShow && !paypalBnplBtnShow && !applePayBtnShow"
              class="chooce-payment-btn-block"
              :width="'100%'"
              :type="['primary']"
            >
              {{
                isDirectPay
                  ? language.SHEIN_KEY_PWA_15655 && language.SHEIN_KEY_PWA_15655.toUpperCase()
                  : language.SHEIN_KEY_PWA_15312 && language.SHEIN_KEY_PWA_15312.toUpperCase()
              }}
            </s-button>
          </div>
        </template>
      </template>
    </s-drawer>
    <LazyMount>
      <s-dialog
        :visible.sync="isShowMaskTips.show"
        :show-close="false"
        :append-to-body="true"
        @close="isShowMaskTips.show = false"
      >
        <template slot="title">
          <div tabindex="0">
            {{ isShowMaskTips.des }}
          </div>
        </template>
        <template slot="footer">
          <s-button
            :width="'100%'"
            :type="['primary', 'H80PX']"
            @click="isShowMaskTips.show = false"
          >
            {{ language.SHEIN_KEY_PWA_15312 }}
          </s-button>
        </template>
      </s-dialog>
    </LazyMount>

    <!-- adyen-mbway 手机弹窗 -->
    <LazyMount>
      <s-drawer
        class="mbway-phone-dialog"
        :type="'multi'"
        :visible.sync="mbwayInfo.dialogShow"
      >
        <p class="tip">
          {{ language.SHEIN_KEY_PWA_20119 }}
        </p>
        <div
          :class="[
            'input-row',
            mbwayInfo.isFocus ? 'focus-input' : '',
            mbwayInfo.errTip ? 'err-input' : ''
          ]"
        >
          <div class="input-code">
            <s-field
              v-model="mbwayInfo.code"
              :label="''"
              @focus="mbwayInputFocus"
              @blur="mbwayInfo.isFocus = false"
            />
          </div>
          <div class="input-phone">
            <s-field
              v-model="mbwayInfo.phone"
              :label="''"
              :clearable="true"
              @focus="mbwayInputFocus"
              @blur="mbwayInfo.isFocus = false"
            />
          </div>
        </div>
        <p
          v-if="mbwayInfo.errTip"
          class="err-tip"
        >
          {{ mbwayInfo.errTip }}
        </p>
        <template slot="footer">
          <s-button
            class="confirm-btn"
            :width="'100%'"
            :type="['primary']"
            @click="confirmMbwayPhone"
          >
            {{ language.SHEIN_KEY_PWA_14896 }}
          </s-button>
        </template>
      </s-drawer>
    </LazyMount>


    <!-- paypal ga vaulting 签约弹窗 -->
    <LazyMount>
      <s-drawer
        class="ppgv-type-drawer"
        :visible.sync="paypalGaVault.tipShow"
        :append-to-body="true"
      >
        <template slot="top">
          {{ language.SHEIN_KEY_PWA_19686 }}
        </template>
        <div>
          <p class="type-des">
            {{ language.SHEIN_KEY_PWA_19687 }}
          </p>
          <div class="type-radio">
            <s-radio
              v-model="paypalGaVault.radioType"
              class="type-item"
              :label="PPGA_CHECK_TYPE.CHECKED"
            >
              {{
                ppgvAccountInfo && ppgvAccountInfo.signUpEmail
                  ? template(ppgvAccountInfo.signUpEmail, language.SHEIN_KEY_PWA_19685)
                  : language.SHEIN_KEY_PWA_19684
              }}
            </s-radio>
            <div @click="clickPaypalSelect">
              <s-radio
                :value="paypalGaVault.radioType"
                class="type-item type-item-last"
                :label="PPGA_CHECK_TYPE.UNCHECKED"
              >
                {{ language.SHEIN_KEY_PWA_19683 }}
              </s-radio>
            </div>
          </div>

          <div class="confirm-btn">
            <s-button
              width="100%"
              :type="['primary']"
              @click="confirmPPGVType"
            >
              {{ language.SHEIN_KEY_PWA_19692 }}
            </s-button>
          </div>
        </div>
      </s-drawer>
    </LazyMount>

    <!-- 非卡token一次支付弹窗 -->
    <SignupPayOneTimePayDrawer
      :language="language"
      :vault="signupPayVault[signupDrawerConfig?.paymentCode]"
      :account-info="signupAccountInfo[signupDrawerConfig?.paymentCode]"
      :paymentCode="signupDrawerConfig?.paymentCode"
      :show="signupDrawerConfig?.show"
      @confirm="handleToggleOneTimePay"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import schttp from 'public/src/services/schttp'
import { mapState, mapMutations, mapActions } from 'vuex'
import { template, transformImg, timeTransformer } from '@shein/common-function'
import ItemInfoBnpl from 'public/src/pages/checkout/vue_tpls/payment_list/item_info_bnpl.vue'
import { handleSwitchCurrency } from 'public/src/pages/checkout/utils'
import { daEventCenter } from '../../../../../services/eventCenter'
import { Alert, Button, Dialog, Drawer, Field, Loading, Radio, LazyMount } from '@shein/sui-mobile'
import { abtservice } from 'public/src/services/abt'
import { PPGA_CHECK_TYPE, AFTERPAY_CHECK_TYPE, SIGNUP_CHECK_TYPE } from 'public/src/pages/common/constants'
import CheckPpgaType from 'public/src/pages/checkout/vue_tpls/CheckPpgaType.vue'
// import CheckAfterPayType from 'public/src/pages/checkout/vue_tpls/CheckAfterPayType.vue'
import CheckSignupPayType from 'public/src/pages/checkout/vue_tpls/CheckSignupPayType.vue'
import SignupTermInfo from 'public/src/pages/checkout/vue_tpls/SignupTermInfo.vue'
import hackLockScrollMixin from '@/public/src/pages/user/child_pages/orders/common/hackLockScrollMixin.js'
import ChoocePaymentsItemCod from './chooce_payments_item_cod.vue'
import { Toast as $toast } from '@shein/sui-mobile'
import ApplePayBtn from 'public/src/pages/components/ApplePayBtn.vue'
import applePayConfig from 'public/src/pages/common/apple_pay/index.js'
import PayBinCoupons from 'public/src/pages/user/child_pages/orders/detail/pay_bin_coupons.vue'

;[Alert, Button, Dialog, Drawer, Field, Loading, Radio].forEach(u => { Vue.use(u) })

const { lang, GB_cssRight, SiteUID, IMG_LINK, IS_RW } = gbCommonInfo
const MXN_METHOD = ['ebanx-oxxo', 'ebanx-spei']

export default {
  name: 'ChoocePaymentsPanel',
  components: {
    CheckPpgaType,
    ChoocePaymentsItemCod,
    ItemInfoBnpl,
    CheckSignupPayType,
    SignupTermInfo,
    ApplePayBtn,
    LazyMount,
    SignupPayOneTimePayDrawer: () =>
      import(
        /* webpackChunkName: "checkout-signup-one-time-pay-drawer" */ 'public/src/pages/checkout/vue_tpls/SignupPayOneTimePayDrawer.vue'
      ),
    PayBinCoupons
  },
  mixins: [hackLockScrollMixin],
  props: {
    orderInfo: {
      type: Object,
      default: () => {}
    },
    mbwayInfo: {
      type: Object,
      default: () => {}
    },
    // eslint-disable-next-line vue/require-default-prop
    payments: [Object, Array],
    moduleName: {
      type: String,
      default: ''
    },
    showWidgetForm: {
      type: Number,
      default: 1
    },
    isRenderHtml: {
      type: Boolean,
      default: false
    },
    needPayInlinePaymentMethods: {
      type: Array,
      default: () => []
    },
    paypalBtnShow: Boolean,
    paypalGaVault: {
      type: Object,
      default: () => {}
    },
    afterPayVault: {
      type: Object,
      default: () => {}
    },
    ppgvAccountInfo: {
      type: Object,
      default: () => {}
    },
    paypalVenmoBtnShow: {
      type: Boolean,
      default: false
    },
    matchedBinDiscountInfo: {
      type: Array,
      default: () => []
    },
    banksConfig: {
      type: Object,
      default: () => {}
    },
    handleStartSelectBank: {
      type: Function,
      default() {
        return () => {}
      }
    },
    hackLockScroll: {
      type: Boolean,
      default: false
    },
    isShowBinRandomDiscountAbt: { // 是否开启卡bin随机立减优惠
      type: Boolean,
      default: false
    },
    showApplepayDropoffOptimization: {
      type: Boolean,
      default: false
    },
    applePayCodeListApollo: {
      type: Array,
      default: () => []
    },
    signupDrawerConfig: {
      type: Object,
      default: () => {}
    },
    signupAccountInfo: {
      type: Object,
      default: () => {}
    },
    signupPayVault: {
      type: Object,
      default: () => {}
    },
    totalPriceAmount: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      IMG_LINK,
      lang,
      GB_cssRight,
      IS_RW,
      // 下单货币是指定币种时才能显示的支付方式
      forceCurrency: {
        INR: {
          methods: [
            'PayU',
            'PayU-card',
            'PayU-netbank',
            'PayU-payu',
            'PayU-wallet',
            'Paytm-card',
            'PayU-wallets',
            'PayU-UPI',
            'PayU-googlepay',
            'Paytm-UPI',
            'Paytm-netbank',
            'Paytm-walletredirect',
            'cashfree-upi'
          ]
        },
        BRL: { methods: ['ebanx-boleto', 'ebanx-cardinstallment', 'dlocal-boleto'] },
        MXN: { methods: MXN_METHOD },
        EUR: { methods: ['adyen-ideal', 'adyen-bancontact', 'adyen-eps'] },
        // 'SEK': {methods: ['klarna-paylater']},
        HKD: { methods: ['adyen-alipayhk'] },
        RUB: { methods: ['ingenico-yandex'] },

        GBP: { methods: ['afterpay-clearpay'] },
        PLN: { methods: ['adyen-blik'] }

        // USD: { methods: [''] }
      },
      klarnaSofortCurrency: {
        EUR: { country: ['82', '14', '150'] }, // 德国、奥地利、荷兰、芬兰
        SEK: { country: ['206'] } // 瑞典
        // 'NOK': { country: ['161'] },
        // 'DKK': { country: ['58'] },
        // 'GBP': { country: ['225'] },
        // 'USD': { country: ['226'] },
      },
      klarnaPaylaterCurrency: {
        EUR: { country: ['82', '14', '150', '73'] }, // 德国、奥地利、荷兰、芬兰
        SEK: { country: ['206'] }, // 瑞典
        NOK: { country: ['161'] },
        DKK: { country: ['58'] },
        GBP: { country: ['225'] }
        // 'USD': { country: ['226'] },
      },
      klarnaSliceitCurrency: {
        EUR: { country: ['82', '150', '73'] }, // 德国、荷兰、芬兰
        SEK: { country: ['206'] }, // 瑞典
        NOK: { country: ['161'] },
        DKK: { country: ['58'] },
        GBP: { country: ['225'] },
        USD: { country: ['226'] }
      },
      dlocalCardCurrency: {
        BRL: { country: ['30'] }, // Brazil
        ARS: { country: ['10'] }, // Argentina
        CLP: { country: ['43'] }, // Chile
        MXN: { country: ['137'] }, // Mexico
        USD: { country: ['63', '47', '168', '169', '228', '26'] }, // Ecuador、Colombia、Paraguay、Peru、Uruguay、Bolivia
        ZAR: { country: ['196'] } // South Africa
      },
      panlePaymentOption: {
        'Paytm-UPI': {
          payerAccount: ''
        },
        'cashfree-upi': {
          payerAccount: ''
        }
      },
      payerAccount: '',
      paytmUPIDTImer: null,
      payment_code: '',
      installmentInfo: {}, // 分期信息
      installmentInfoCache: {}, // 分期信息缓存
      requesting: false,

      isShowMaskTips: {
        show: false,
        des: ''
      },
      exposeEvtInstance: null,
      paymentPanelExposeCode: 'paymentPanelExposeCode',
      randomDiscountAbt: false,
      anglesTriggerLock: false,
      PPGA_CHECK_TYPE,
      AFTERPAY_CHECK_TYPE,
      SIGNUP_CHECK_TYPE,
      couponList: [],
      currentPaymentCode: '',
    }
  },
  computed: {
    ...mapState('checkout', ['notSupportCodReason']),
    ...mapState({
      currencySupport: state => state.orderDetail.currencySupport,
      selectedIdealCode: state => state.orderDetail.selectedIdealCode,
      language: state => {
        return Object.keys(state.orderDetail.language).length
          ? state.orderDetail.language
          : state.orderList
            ? state.orderList.language
            : {}
      },
      pageStatus: state => state.orderDetail.pageStatus,
      paymentOption: state => state.orderDetail.paymentOption,
      unPaidOrderLoadPayment: state => state.orderDetail.unPaidOrderLoadPayment,
      payLimitTxt: () => {
        // const language = state.orderDetail.language
        // return {
        //   'klarna-sofort': language.SHEIN_KEY_PWA_16104,
        //   'klarna-paylater': language.SHEIN_KEY_PWA_16337,
        //   'ebanx-mxcardinstallment': language.SHEIN_KEY_PWA_16139,
        //   'adyen-alipayhk': language.SHEIN_KEY_PWA_16116,
        //   'ebanx-oxxo': language.SHEIN_KEY_PWA_16119,
        //   'ebanx-boleto': language.SHEIN_KEY_PWA_16120,
        //   'ebanx-clcardinstallment': language.SHEIN_KEY_PWA_16280,
        //   'afterpay-card': {
        //     AUD: language.SHEIN_KEY_PWA_16551
        //     // 'NZD': language.SHEIN_KEY_PWA_16628,
        //   },
        //   'adyen-ideal': language.SHEIN_KEY_PWA_16582,
        //   'klarna-sliceit': language.SHEIN_KEY_PWA_16622,
        //   'mercado-card': language.RW_M_KEY_3456
        // }
      },

      klarnaSofrotPayLimitTxt: state => {
        const language = state.orderDetail.language
        return {
          DE: language.SHEIN_KEY_PWA_16330,
          AT: language.SHEIN_KEY_PWA_16330,
          NL: language.SHEIN_KEY_PWA_16330,
          SE: language.SHEIN_KEY_PWA_16337
          // 'NO': language.SHEIN_KEY_PWA_16416,
          // 'FI': language.SHEIN_KEY_PWA_16417,
          // 'DK': language.SHEIN_KEY_PWA_16418,
          // 'GB': language.SHEIN_KEY_PWA_16419,
          // 'US': language.SHEIN_KEY_PWA_16420,
        }
      },

      klarnaPaylaterLimitTxt: state => {
        const language = state.orderDetail.language
        return {
          DE: language.SHEIN_KEY_PWA_16330,
          AT: language.SHEIN_KEY_PWA_16330,
          NL: language.SHEIN_KEY_PWA_16330,
          SE: language.SHEIN_KEY_PWA_16337,
          NO: language.SHEIN_KEY_PWA_16416,
          FI: language.SHEIN_KEY_PWA_16417,
          DK: language.SHEIN_KEY_PWA_16418,
          GB: language.SHEIN_KEY_PWA_16419
          // 'US': language.SHEIN_KEY_PWA_16420,
        }
      }
    }),
    isDirectPay() {
      return (
        this.moduleName == 'orderList' || this.unPaidOrderLoadPayment || this.pageStatus.isDirectPay
      )
    },
    paymentMethodLimitInfo: function () {
      return this.orderInfo.paymentMethodLimitInfo
    },
    // 卡bin优惠信息
    cardBinDiscountDesc() {
      // 当命中多个卡bin优惠，不区分命中的类型
      if (this.matchedBinDiscountInfo.length > 1) {
        const lengthObj = {
          2: this.language?.SHEIN_KEY_PWA_26144,
          3: this.language?.SHEIN_KEY_PWA_26145
        }

        return template(
          ...this.matchedBinDiscountInfo.map(item => item?.bank_name),
          lengthObj[this.matchedBinDiscountInfo.length]
        )
      }
      const {
        bank_name = '',
        discount_amount = {},
        discount_percentage = 0,
        discount_type = 0,
        threshold_amount = {},
        max_discount_amount = {},
        max_random_amount = {},
        min_random_amount = {}
      } = this.matchedBinDiscountInfo[0] || {}
      if (discount_type === 1) {
        return template(
          discount_amount?.amountWithSymbol,
          threshold_amount?.amountWithSymbol,
          bank_name || '',
          this.language?.SHEIN_KEY_PWA_24447
        )
      }
      if (discount_type === 2) {
        const maxDiscountAmount = max_discount_amount?.amount
        const thresholdAmount = threshold_amount?.amount
        // 折扣--无门槛
        if (thresholdAmount == 0) {
          return template(
            maxDiscountAmount > 0
              ? max_discount_amount.amountWithSymbol
              : discount_percentage || '',
            bank_name || '',
            maxDiscountAmount > 0
              ? this.language?.SHEIN_KEY_PWA_24525
              : this.language?.SHEIN_KEY_PWA_24526
          )
        }
        return template(
          maxDiscountAmount > 0 ? max_discount_amount.amountWithSymbol : discount_percentage || '',
          bank_name || '',
          threshold_amount?.amountWithSymbol,
          maxDiscountAmount > 0
            ? this.language?.SHEIN_KEY_PWA_24453
            : this.language?.SHEIN_KEY_PWA_24448
        )
      }
      // 随机立减
      if (discount_type == 3 && this.isShowBinRandomDiscountAbt) {
        const thresholdAmount = threshold_amount?.amount
        // 折扣--无门槛
        if (thresholdAmount == 0) {
          return template(
            min_random_amount?.amountWithSymbol,
            max_random_amount?.amountWithSymbol,
            bank_name || '',
            bank_name ? this.language?.SHEIN_KEY_PWA_28692 : this.language?.SHEIN_KEY_PWA_24866
          )
        }
        return template(
          min_random_amount?.amountWithSymbol,
          max_random_amount?.amountWithSymbol,
          threshold_amount?.amountWithSymbol,
          bank_name || '',
          bank_name ? this.language?.SHEIN_KEY_PWA_28691 : this.language?.SHEIN_KEY_PWA_24937
        )
      }
      return ''
    },

    // 是否有卡 bin 优惠券
    isHadBinCoupons() {
      return this.couponList?.some(item => item?.card?.card_codes?.length > 0)
    },

    applePayBtnShow() {
      const apolloInfo = this.applePayCodeListApollo?.length ? this.applePayCodeListApollo : applePayConfig?.applePayChannel || []
      const applepayDropoffOptimizationAbt = this.showApplepayDropoffOptimization
      if(apolloInfo?.includes(this.currentPaymentCode) && applepayDropoffOptimizationAbt){
        return true
      }
      return false
    },
    paypalBnplBtnShow() {
      return this.currentPaymentCode === 'PayPal-bnpl' && this.isDirectPay && this.showWidgetForm == 2
    },
  },
  watch: {
    payments(v) {
      this.handleEachPayment()
      if (v) {
        // 优惠券限定的支付方式
        const couponLimitPayment = this.orderInfo.coupon_support_payment || []
        const newArr = [...v]
        if (couponLimitPayment.length) {
          newArr.forEach(item => {
            if (
              !couponLimitPayment.includes(item.code) &&
              item.enabled != 0 &&
              !item.gray_description
            ) {
              item.enabled = 0
              item.show_description = this.language.SHEIN_KEY_PWA_17177
              // item.gray_description = this.language.SHEIN_KEY_PWA_17177
            }
          })
        }
        return newArr
      }
    },
    'orderInfo.totalPrice': function () {
      this.handleEachPayment()
      this.handleNotSupportCodTip(this.orderInfo)
    },
    matchedBinDiscountInfo: {
      immediate: true,
      deep: true,
      handler() {
        const daId = this.moduleName == 'orderDetail' ? '1-19-1-96' : '1-20-1-64'
        let discountTypeList = []
        let discount_type_key_list = []
        this.matchedBinDiscountInfo?.forEach(item => {
          if (item?.discount_type != 0 && !discount_type_key_list.includes(item.discount_type)) {
            discount_type_key_list.push(item.discount_type)
            let temp_payment_list = this.payments
              .filter(now_item => item?.match_payments.includes(now_item.code))
              .map(item => item.code)
              .join('`')
            discountTypeList.push(`${item.discount_type}:${temp_payment_list}`)
          }
        })

        daEventCenter.triggerNotice({
          daId,
          extraData: {
            discountType: discountTypeList.join(',')
          }
        })
      }
    },
    'pageStatus.showPaymentsDrawer': function (val) {
      if (!this.anglesTriggerLock && val) {
        this.anglesTrigger()
      }
    },

    'orderInfo.coupon_list': {
      handler() {
        this.getData()
      },
      immediate: true
    }
  },

  created() {
    const afterpayCardLimitSite = {
      pwus: { currency: 'USD' },
      mnz: { currency: 'NZD' }
    }
    if (afterpayCardLimitSite[SiteUID]) {
      const currency = afterpayCardLimitSite[SiteUID].currency
      const tip = afterpayCardLimitSite[SiteUID].tip
      this.forceCurrency[currency]
        ? this.forceCurrency[currency].methods.push('afterpay-card')
        : (this.forceCurrency[currency] = { methods: ['afterpay-card'], tip })
    }

    if (this.payments && this.payments.length) {
      this.getInstallmentInfo()
      if (this.moduleName === 'orderList') {
        this.initExposeEvent()
      }
    }

    this.randomGetAbt()

  },
  beforeDestroy() {
    if (this.hackLockScroll) {
      this.restoreBodyScroll?.()
    }
  },
  deactivated () {
    if (this.hackLockScroll) {
      this.restoreBodyScroll?.()
    }
  },
  mounted() {
    const IS_SSR = typeof window == 'undefined'
    !IS_SSR && window?.appEventCenter?.$on('capitecBankSaved', () => {
      if(this.moduleName === 'orderList') {
        this.handlePaymentOk()
      } else if (this.moduleName === 'orderDetail') {
        // 关闭弹窗
        this.pageStatus.showPaymentsDrawer = false
      }
    })
  },
  beforeUnmount() {
    const IS_SSR = typeof window == 'undefined'
    // 注销事件监听器
    if (!IS_SSR && window?.appEventCenter && this.capitecBankSavedHandler) {
      window.appEventCenter.$off('capitecBankSaved')
    }
  },
  methods: {
    ...mapActions('checkout', ['handleNotSupportCodTip']),
    ...mapActions('orderDetail', ['updateCachePayment']),
    ...mapMutations('root', ['showMaskTips', 'changeRootStatus', 'changeUserRootSLoadingStatus']),
    ...mapMutations('orderDetail', ['assignState', 'handleChangePayment']),
    transformImg,
    template,
    clickPaypalSelect(event){
      const primeActivityType =  this.orderInfo?.vip_order_source
      if (primeActivityType == 1) {
        event.preventDefault()
        return this.$toast( this.language?.SHEIN_KEY_PWA_31535 )
      } else {
        this.paypalGaVault.radioType = PPGA_CHECK_TYPE.UNCHECKED
      }
    },
    handleDrawerClose() {
      // 关闭时重置曝光事件
      this.daEventExpose?.remove?.(this.paymentPanelExposeCode)
      this.daEventExpose = null
      this.assignState({ unPaidOrderLoadPayment: false })
      if (this.hackLockScroll) {
        this.restoreBodyScroll?.()
      }
    },
    handleDrawerOpen() {
      // 重置曝光事件
      if (this.daEventExpose) {
        this.daEventExpose.remove(this.paymentPanelExposeCode)
        this.daEventExpose = null
      }
      this.$nextTick(() => {
        if (this.moduleName === 'orderDetail') {
          this.initExposeEvent()
        }
        if (this.hackLockScroll) {
          this.setBodyScroll?.()
        }
      })
    },
    initExposeEvent() {
      this.$nextTick(() => {
        if (this.daEventExpose) {
          return this.daEventExpose.reset(this.paymentPanelExposeCode)
        }
        this.daEventExpose = daEventCenter.getExposeInstance()
        this.daEventExpose.subscribe({
          keycode: `${this.paymentPanelExposeCode}\`1-20-1-57,${this.paymentPanelExposeCode}\`1-19-1-81`
        })
      })
    },
    choosePayment(index, payment) {
      if (this.isDisabledPay(payment)) {
        this.showMaskTips({
          text: this.language.SHEIN_KEY_PWA_31467,
          type:
            this.moduleName == 'orderDetail'
              ? 'forceCurrenctyOrderDetail'
              : 'forceCurrenctyOrderList'
        })
        return
      }
      this.currentPaymentCode = payment.code
      if (payment.isAllBusiness) return this.showMaskTips(payment.gray_description)
      // 币种限制
      if (!this.paymentDisplay(payment.code)) {
        // this.showMaskTips(this.language.SHEIN_KEY_PWA_17728)
        this.showMaskTips({
          text: this.language.SHEIN_KEY_PWA_17728,
          type:
            this.moduleName == 'orderDetail'
              ? 'forceCurrenctyOrderDetail'
              : 'forceCurrenctyOrderList'
        })
        if (this.moduleName == 'orderDetail') {
          daEventCenter.triggerNotice({ daId: '1-19-1-7' })
        } else {
          daEventCenter.triggerNotice({ daId: '1-20-1-7' })
        }
        return
      }
      if (payment && payment.enabled != 1) {
        this.clickDes(payment)
        return
      }

      const extraData = Object.assign(
        { payment_code: payment.code },
        payment.code == 'PayPal-GApaypal'
          ? { is_vaulting: !!this.ppgvAccountInfo?.signUpEmail ? '1' : '0' }
          : {}
      )
      daEventCenter.triggerNotice({
        daId: this.moduleName == 'orderDetail' ? '1-19-1-62' : '1-20-1-39',
        extraData
      })

      this.$emit('loadWidget', payment.code)
      this.cacheHandlePayment(index, payment.code)
      this.$nextTick(()=>{
        // 支付方式能正常显示
        const paymentDisplayFine = payment.enabled == 1 && this.paymentDisplay(payment.code)
        // 支付方式里包含银行列表，需要弹起银行选择弹框
        const needBankDialog = this.banksConfig[payment.code] && this.banksConfig[payment.code]?.list?.length
        if(paymentDisplayFine && needBankDialog){
          this.handleStartSelectBank(payment)
        }
      })
    },
    cacheHandlePayment: function (index, code) {
      const selectedPayment = this.payments[index]
      if (!selectedPayment.enabled) return

      //this.pageStatus.cachePaymentsCode = {
      //  index: index,
      //  code: code
      //}

      this.updateCachePayment({
        index: index,
        code: code
      })

      // if (
      //   this.banksConfig?.[code]?.list?.length &&
      //   (!this.banksConfig?.[code]?.bankCode ||
      //   this.banksConfig?.[code]?.bankCode === -1)
      // ) {
      //   this.handleStartSelectBank(code)
      // }

      // if (['adyen-ideal', 'worldpay-ideal', 'adyen-eps', 'apacpay-vnbanktransfer', 'apacpay-thbanktransfer', 'Paytm-netbank'].indexOf(code) >= 0) {
      //   this.pageStatus.bankDirection = 'right'
      //   this.pageStatus.showBanksDrawer = true
      //   this.assignState({ selectedIdealCode: code })
      // }
    },
    handlePaymentOk: function () {
      const payment_code =
        (this.pageStatus.cachePaymentsCode && this.pageStatus.cachePaymentsCode.code) ||
        this.selectedIdealCode ||
        ''
      if (['Paytm-UPI', 'cashfree-upi'].indexOf(payment_code) > -1) {
        // 检测 paytm upi/cashfree-upi payeraccount是否填写
        if (
          this.panlePaymentOption[payment_code].payerAccount == '' ||
          this.panlePaymentOption[payment_code].payerAccount.length > 32
        ) {
          this.paymentOption[payment_code].payerAccount = ''
          this.paymentOption[payment_code].showError = true
          this.scrollTopPaytmUPI(payment_code)
          return
        }
      }
      if (!this.pageStatus.cachePaymentsCode) {
        this.changeRootStatus({ loading: false })
        this.pageStatus.showPaymentsDrawer = false
        if (this.isDirectPay) {
          this.$emit('paynow')
        }
      } else {
        this.handlePaymentClick(
          this.pageStatus.cachePaymentsCode.index,
          this.pageStatus.cachePaymentsCode.code
        )
      }
    },
    async handlePaymentClick(index) {
      const selectedPayment = this.payments[index]
      if (!selectedPayment.enabled) return
      var self = this

      if (this.clickTwice) return
      this.clickTwice = 1
      setTimeout(function () {
        self.clickTwice = 0
      }, 300)
      this.changeUserRootSLoadingStatus?.({
        maskTransparent: true
      })
      this.changeRootStatus({
        loading: true
      })
      let json = await schttp({
        url: '/api/checkout/switchPayment/update',
        params: {
          payment_id: selectedPayment.id,
          payment_code_unique: selectedPayment.code,
          editType: 'editOrderPaymentMethod',
          billno: this.orderInfo.billno,
          appCurrency: this.orderInfo.orderCurrency ? this.orderInfo.orderCurrency.code : ''
        },
        schttp: { needLogin: true }
      })
      if (json.code == 0 && json.info) {
        // 订单详情返回onlinePayDiscountInfo，切换支付方式返回onlinePayDiscount等，针对后端返回同个意义但不同字段处理
        let orderInfo = Object.assign({}, self.orderInfo)
        if (json.info.onlinePayDiscount) {
          orderInfo.onlinePayDiscountInfo = json.info.onlinePayDiscount
          orderInfo.onlinePayDiscountInfo.discount_price = json.info.onlinePayDiscount.discountPrice
        } else {
          orderInfo.onlinePayDiscountInfo = null
        }
        json.info.addTime = json.info.addTime
          ? timeTransformer({
            time: json.info.addTime * 1000,
            defaultCode: 'A-3',
            multiLang: false
          })
          : orderInfo.addTime
        orderInfo = $.extend({}, orderInfo, json.info)

        if (json.info.codPrice) {
          orderInfo.orderExtend.codPrice = json.info.codPrice
        } else {
          orderInfo.orderExtend.codPrice = {}
        }

        self.assignState({ orderInfo: orderInfo })
        self.assignState({ selectedIdealCode: selectedPayment.code })
        this.handleChangePayment({
          paymentLogo: selectedPayment.logo_url,
          paymentTitle: selectedPayment.title ? selectedPayment.title : selectedPayment.code,
          paymentMethod: selectedPayment.code,
          paymentType: selectedPayment?.payment_type,
          orderInfo
        })

        self.pageStatus.showPaymentsDrawer = false
        if (self.isDirectPay) {
          self.$emit('paynow')
        }

        this.changeRootStatus({
          loading: false
        })
      }

      this.payments.forEach((item, i) => {
        if (i == index) {
          self.$set(item, 'checked', true)
        } else {
          self.$set(item, 'checked', false)
        }
      })
    },

    paymentDisplay: function (code) {
      /**
       * 获取强制切换币种
       */
      if (this.orderInfo && this.orderInfo.orderCurrency) {
        const { isChangeCurrency } = handleSwitchCurrency({
          currencySupport: this.currencySupport,
          currency: this.orderInfo.orderCurrency.code,
          countryCode: this.orderInfo.countryCode,
          paymentCode: code
        })
        return !isChangeCurrency
      } else {
        return false
      }

      // var orderCurr = this.orderInfo.currency_code
      // for (var curr in this.forceCurrency) {
      //   if (this.forceCurrency[curr].methods.indexOf(code) > -1) {
      //     if (orderCurr == curr) {
      //       return true
      //     } else {
      //       return false
      //     }
      //   }
      // }
      // // 特殊的多币种限制支付方式
      // if (code == 'ebanx-mxcardinstallment' && !['USD', 'MXN'].includes(orderCurr)) {
      //   return false
      // } else if (IS_RW && code == 'ebanx-card' && !['USD', 'MXN'].includes(orderCurr)) {
      //   return false
      // } else if (code == 'ebanx-brcardinstallment' && !['USD', 'BRL'].includes(orderCurr)) {
      //   return false
      // } else if (lang == 'au' && code == 'afterpay-card' && !['AUD'].includes(orderCurr)) {
      //   return false
      // } else if (code == 'ebanx-clcardinstallment' && !['CLP', 'USD', 'EUR'].includes(orderCurr)) {
      //   return false
      // } else if (['klarna-sofort', 'klarna-paylater', 'klarna-sliceit', 'klarna-sofortjs', 'dlocal-card'].includes(code)) {
      //   const country = this.orderInfo.country_id + ''
      //   // const forceCurr = code == 'klarna-sofort' ? this.klarnaSofortCurrency : this.klarnaPaylaterCurrency
      //   const gettersList = {
      //     'klarna-sofort': 'klarnaSofortCurrency',
      //     'klarna-paylater': 'klarnaPaylaterCurrency',
      //     'klarna-sliceit': 'klarnaSliceitCurrency',
      // 'klarna-sofortjs': 'klarnaSofortCurrency',		// 和klarna-sofort币种规则一样
      //     'dlocal-card': 'dlocalCardCurrency'
      //   }
      //   const forceCurr = this[gettersList[code]]
      //   for (const curr in forceCurr) {
      //     if (forceCurr[curr].country.includes(country)) {
      //       if (orderCurr != curr) {
      //         return false
      //       }
      //       break
      //     }
      //   }
      // } else if (lang === 'za' && code == 'PayPal-paypal' && ['ZAR'].includes(orderCurr)) {
      //   return false
      // }
      // return true
    },
    showPriceLimit(code, isMask = true) {
      // var txt = this.payLimitTxt[code]
      // if (code == 'klarna-sofort') {
      //   txt = this.klarnaSofrotPayLimitTxt[this.orderInfo.countryCode]
      // } else if (code == 'klarna-paylater') {
      //   txt = this.klarnaPaylaterLimitTxt[this.orderInfo.countryCode]
      // } else if (code == 'afterpay-card') {
      //   const orderCurr = this.orderInfo.currency_code
      //   txt = this.payLimitTxt[code][orderCurr]
      // }

      let txt = ''
      if (
        this.orderInfo &&
        this.orderInfo.paymentMethodAmountLimitInfo &&
        this.orderInfo.paymentMethodAmountLimitInfo[code]
      ) {
        const item = this.orderInfo.paymentMethodAmountLimitInfo[code]
        const { maxAmount, minAmount } = item
        if (minAmount && maxAmount) {
          txt = this.template(minAmount, maxAmount, this.language.SHEIN_KEY_PWA_16733)
        } else if (!minAmount && maxAmount) {
          txt = this.template(maxAmount, this.language.SHEIN_KEY_PWA_16753)
        } else if (minAmount && !maxAmount) {
          txt = this.template(minAmount, this.language.SHEIN_KEY_PWA_16734)
        }
      }

      txt = txt.replace(/\s/g, ' ')
      if (!isMask) {
        return txt
      }

      this.showMaskTips(txt)
    },

    handleEachPayment() {
      if (this.payments && this.payments.length) {
        this.getInstallmentInfo()
      }
    },

    async getInstallmentInfo() {
      const _this = this
      if (this.requesting) return
      if (!this.orderInfo || !this.orderInfo.totalPrice || !this.orderInfo.totalPrice.amount) return
      const data = {
        country: this.orderInfo.shipping_country_code,
        amount: this.orderInfo.totalPrice.amount,
        currency: this.orderInfo.currency_code
      }
      const cacheKey = data.currency + '_' + data.amount
      if (this.installmentInfoCache[cacheKey]) {
        Vue.set(this, 'installmentInfo', this.installmentInfoCache[cacheKey])
        if (_this.moduleName === 'orderList') {
          _this.initExposeEvent()
        }
      } else {
        this.requesting = true
        let res = await schttp({
          method: 'POST',
          url: '/api/checkout/installmentDetail/get',
          data: data
        })
        _this.requesting = false
        if (res && res.code == 0) {
          if (res.info && res.info.front_show_list && res.info.front_show_list.length) {
            const front_list_items = {}
            res.info.front_show_list.forEach(item => {
              front_list_items[item.payment_code] = item
            })
            res.info.front_list_items = front_list_items
          }

          Vue.set(_this, 'installmentInfo', res.info || {})
          if (_this.moduleName === 'orderList') {
            _this.initExposeEvent()
          }
          _this.installmentInfoCache[cacheKey] = res.info || {}
        } else {
          Vue.set(_this, 'installmentInfo', {})
        }
      }
    },
    checkValidatePaytmUPI(payment_code) {
      const payerAccount = this.panlePaymentOption[payment_code].payerAccount
      if (this.paytmUPIDTImer) clearTimeout(this.paytmUPIDTImer)
      this.paytmUPIDTImer = setTimeout(() => {
        if (payerAccount == '' || payerAccount.length > 32) {
          this.paymentOption[payment_code].payerAccount = payerAccount
          this.paymentOption[payment_code].showError = true
          return
        }
        this.paymentOption[payment_code].payerAccount = payerAccount
        this.paymentOption[payment_code].showError = false
      }, 200)
    },
    scrollTopPaytmUPI(payment_code) {
      const $con = $('.chooce-payment-trans-contain-footer')
      const $el = $('li[data-code="' + payment_code + '"]')
      if (!$el.length) return
      $con.scrollTop($el.position().top)
      window.requestAnimationFrame(() => {
        $el.find('input[name="paytm-upi-tips"]').focus()
      })
    },

    clickDes(item) {
      const des = this.paymentDes(item)
      if (des) {
        this.isShowMaskTips.show = true
        this.isShowMaskTips.des = des
      }
      // des && this.showMaskTips(des)
    },
    clickPaypalGaType(payment) {
      if (payment.code === 'PayPal-GApaypal') {
        this.$emit('show-paypalga-type', true)
      } else {
        this.$emit('click-signup-one-time-arrow', payment.code)
      }
    },
    confirmPPGVType() {
      this.$emit('confirm-ppgv-type', true)
    },
    mbwayInputFocus() {
      this.$emit('mbwayInputFocus')
    },
    showMbwayDialog() {
      this.$emit('showMbwayDialog')
    },
    confirmMbwayPhone() {
      this.$emit('confirmMbwayPhone')
    },

    // 置灰文案
    paymentDes(item) {
      let des = ''
      if (['klarna-klarna', 'dlocal-nupay'].includes(item.code) && this.signupPayVault?.[item.code]?.valid && item.enabled == 1) {
        return ''
      }
      if (item.show_description && item.enabled == 1 && item.description) {
        des = item.description

        // 如果支付不可用，或者cod不可用时
      } else if (item.enabled != 1) {
        // 按优先级展示置灰原因，越往下，优先级越高

        // 优惠券限定的支付方式
        const couponLimitPayment = this.orderInfo.coupon_support_payment || []
        if (couponLimitPayment.length && !couponLimitPayment.includes(item.code)) {
          des = this.language.SHEIN_KEY_PWA_17177
        }

        // 业务配置的置灰文案
        des = item.gray_description || des

        // 价格限制
        if (
          this.orderInfo.paymentMethodLimitInfo &&
          +this.orderInfo.paymentMethodLimitInfo[item.code] == 0
        ) {
          des = this.showPriceLimit(item.code, false) || des
        }
        // cod不可用原因
        if (item.code == 'cod') {
          des = this.notSupportCodReason || des
        }
      }

      //如果有店铺限制置灰，优先展示置灰文案
      // if( item.isStoreDisabled ){
      // 	des = this.language.SHEIN_KEY_PWA_20331
      // }

      if (item.isAllBusiness) des = item.gray_description

      if (item.gray_reason == 1) {
        //业务配置置灰文案
        des = this.language.SHEIN_KEY_PWA_24508 || ''
      }
      // 03 本地自营置灰 => wiki.pageId=1176265937
      if (item.gray_reason == 3) {
        //业务配置置灰文案
        des = this.language.SHEIN_KEY_PWA_26043 || 'error 03'
      }
      // 自动续费限制 优先级最低, 若无才有
      if (item.disabled_reason === 'auto_renewal_limit' && !des) {
        des = this.language.SHEIN_KEY_PWA_29741
      }
      return des
    },
    onlinePayDiscountInfoValue(item = {}) {
      return this.orderInfo?.onlinePayDiscountValue?.[item?.code] || {}
    },
    currentPaymentDisble(item = {}) {
      return this.orderInfo?.paymentMethodLimitInfo?.[item?.code] === false ? false : true
    },
    onlinePayDiscountInfoMinAmount(item = {}) {
      return +this.orderInfo?.onlinePayDiscountValue?.[item?.code]?.min?.amount
    },
    isShowCardBinDiscount(item) {
      if (this.matchedBinDiscountInfo.length > 1) {
        let is_discount_type = false
        let is_match_payments = this.matchedBinDiscountInfo
          .map(binDiscountItem => {
            const { discount_type = 0, match_payments = [] } = binDiscountItem || {}
            const matchPayment = (match_payments || []).some(v => v === item.code)
            if (discount_type != 0) {
              is_discount_type = true
            }
            return matchPayment
          })
          .some(isMatchPayment => isMatchPayment)

        return item.enabled && is_discount_type && is_match_payments
      }
      const { discount_type = 0, match_payments = [] } = this.matchedBinDiscountInfo[0] || {}
      const matchPayment = (match_payments || []).some(v => v === item.code)
      return item.enabled && 
        discount_type && 
        matchPayment &&
        // 如果同时存在在线支付优惠随机立减和卡bin随机立减，只展示在线支付优惠随机立减
        (discount_type == 3 ? !this.isShowOnlineRandomDiscount(item) && !!this.cardBinDiscountDesc : true)
    },
    handlePaymentOptionsOper(type = '', data = {}) {
      if (!type) return
      this.$emit('payment-options-oper', { type, data })
    },
    isDislable(item) {
      const currentPaymentDisble = this.currentPaymentDisble(item)
      // const currentSupport = this.currentSupport(item)
      const currentSupport = this.paymentDisplay(item.code)
      return currentPaymentDisble && currentSupport
    },
    // 当前币种判断
    currentSupport(item) {
      const currency = this.orderInfo?.orderCurrency?.code || gbCommonInfo.currency
      const currencySupportObj = this?.currencySupport?.find(f => f.payMethod == item.code) || {}
      return currencySupportObj.defaultCurrency === currency
    },
    // 有门槛/最高折扣金额展示
    isShowOnlinePayDiscount(item = {}) {
      const onlinePayDiscountValue = this.onlinePayDiscountInfoValue(item) || {}
      const isRandomDiscount = this.isShowOnlineRandomDiscount(item)
      return (
        item.enabled &&
        !!Object.keys(onlinePayDiscountValue)?.length &&
        ((onlinePayDiscountValue.discountType != 2 &&
          onlinePayDiscountValue?.min &&
          onlinePayDiscountValue?.min?.amount != 0) ||
          (onlinePayDiscountValue.discountType == 1 &&
            onlinePayDiscountValue?.limit_value &&
            onlinePayDiscountValue?.limit_value?.amount != 0) ||
          isRandomDiscount)
      )
    },
    // 获取随机立减abt
    async randomGetAbt() {
      // eslint-disable-next-line @shein-aidc/abt/abt
      const { PaymentRandomDiscount } = await abtservice.getUserAbtResult({
        newPosKeys: 'PaymentRandomDiscount'
      })
      this.randomDiscountAbt = PaymentRandomDiscount?.param?.PaymentRandomShow == 'Show'
    },
    // 随机立减文案
    randomDiscountDes(item) {
      const onlinePayDiscountValue = this.onlinePayDiscountInfoValue(item) || {}

      if (!+onlinePayDiscountValue?.min?.amount) {
        return this.template(
          onlinePayDiscountValue?.randomMin?.amountWithSymbol,
          onlinePayDiscountValue?.randomMax?.amountWithSymbol,
          this.language.SHEIN_KEY_PWA_24866
        )
      }
      return this.template(
        onlinePayDiscountValue?.randomMin?.amountWithSymbol,
        onlinePayDiscountValue?.randomMax?.amountWithSymbol,
        onlinePayDiscountValue?.min?.amountWithSymbol,
        this.language.SHEIN_KEY_PWA_24937
      )
    },
    // 当前支付方式是否展示在线支付随机立减优惠
    isShowOnlineRandomDiscount (item = {}) {
      const onlinePayDiscountValue = this.onlinePayDiscountInfoValue(item) || {}
      return onlinePayDiscountValue.discountType == 2 && +onlinePayDiscountValue?.random_max?.amount != 0 && this.randomDiscountAbt
    },
    // 支付方式全量上报
    anglesTrigger(val) {
      const pament_onLinePay =
        val ||
        Object.entries(this.orderInfo?.onlinePayDiscountValue || {}).map(r => {
          return {
            ...r[1],
            paymentCode: r[0]
          }
        })
      // 符合随机立减 不受abt影响 expose_randomdiscount_abt
      const qualificationsPay = () => {
        // 一个符合随机立减 即为1，否则为0
        const full_result = pament_onLinePay.some(s => s.discountType == 2) ? 1 : 0
        const payment_list_result = pament_onLinePay
          .filter(f => f.discountType == 2)
          .reduce((pre, next) => String(pre) + String(next.paymentCode) + '`', '')
        daEventCenter.triggerNotice({
          daId: '1-11-1-144',
          extraData: {
            is_full: full_result,
            payment_list: payment_list_result
          }
        })
      }
      // 随机立减文案，受abt影响 expose_randomdiscount
      const discountText = () => {
        const payment_list_result = pament_onLinePay
          .filter(f => f.discountType == 2)
          .reduce((pre, next) => String(pre) + String(next.paymentCode) + '`', '')
        daEventCenter.triggerNotice({
          daId: '1-11-1-145',
          extraData: {
            payment_list: payment_list_result
          }
        })
      }
      if (pament_onLinePay.length) {
        qualificationsPay()
        this.randomDiscountAbt && discountText()
        this.anglesTriggerLock = true
      }
    },
    getBankCodeText(item) {
      return this.banksConfig?.[item.code]?.bankCodeText || ''
    },
    getBankLogo(item) {
      return this.banksConfig?.[item.code]?.bankLogo || ''
    },
    getPaymentTitle(item) {
      const signupInfo = this.signupAccountInfo?.[item?.code] || {}
      const signupVault = this.signupPayVault?.[item?.code] || {}
      if (item.code == 'PayPal-GApaypal' && this.paypalGaVault?.valid) {
        if (this.paypalGaVault?.express) {
          return this.language?.SHEIN_KEY_PWA_25526
        } else if (this.paypalGaVault?.type == PPGA_CHECK_TYPE.CHECKED) {
          return this.ppgvAccountInfo?.signUpEmail || this.language.SHEIN_KEY_PWA_19684
        }
      }
      if (item?.code == 'afterpay-card' && this.afterPayVault?.title && !signupVault?.useOneTimePay) {
        if(this.afterPayVault?.title === 'A') {
          return this.language?.SHEIN_KEY_PWA_30872
        } else if (this.afterPayVault?.title === 'B') {
          return this.language?.SHEIN_KEY_PWA_30873
        }
      }
      if (item?.code == 'afterpay-cashapp' && signupInfo?.signUpEmail && !signupVault?.useOneTimePay) {
        return this.template(signupInfo.signUpEmail, this.language?.SHEIN_KEY_PWA_33907)
      }

      if (item?.code == 'dlocal-nupay' && signupInfo?.id && !signupVault?.useOneTimePay) {
        return this.language?.SHEIN_KEY_PWA_33934
      }

      if (item?.code == 'klarna-klarna' && signupInfo?.signUpPhoneNumber && !signupVault?.useOneTimePay) {
        return this.template(signupInfo.signUpPhoneNumber, this.language?.SHEIN_KEY_PWA_33937)
      }
      return (
        (item?.bank_list?.length && this.banksConfig?.[item.code]?.bankCodeText) ||
        (item.show_title && item.title) ||
        ''
      )
    },
    handleTogglePpgaType() {
      if (this.paypalGaVault?.type == 1 && this.orderInfo?.vip_order_source == 1) {
        return $toast(
          this.language?.SHEIN_KEY_PWA_31535
        )
      }
      this.paypalGaVault.radioType =
        this.paypalGaVault.radioType === PPGA_CHECK_TYPE.CHECKED
          ? PPGA_CHECK_TYPE.UNCHECKED
          : PPGA_CHECK_TYPE.CHECKED
      this.$emit('confirm-ppgv-type')
    },
    handleToggleAfterPayType() {
      if (this.paypalGaVault?.type == 1 && this.orderInfo?.vip_order_source == 1) {
        return $toast(
          this.language?.SHEIN_KEY_PWA_31535
        )
      }

      this.afterPayVault.radioType =
        this.afterPayVault.radioType === AFTERPAY_CHECK_TYPE.CHECKED
          ? AFTERPAY_CHECK_TYPE.UNCHECKED
          : AFTERPAY_CHECK_TYPE.CHECKED
      this.$emit('confirm-afterpay-type')
    },
    handleToggleSignupPayType(paymentCode) {
      this.signupPayVault[paymentCode].radioType =
        this.signupPayVault[paymentCode].radioType === SIGNUP_CHECK_TYPE.CHECKED
          ? SIGNUP_CHECK_TYPE.UNCHECKED
          : SIGNUP_CHECK_TYPE.CHECKED
      this.$emit('confirm-signup-pay-type', paymentCode)
    },
    paypalExposeData(paymentItem = {}) {
      if (paymentItem?.code !== 'PayPal-GApaypal') return {}
      return {
        id: '2-31-8',
        data: {
          payment_method: paymentItem.code,
          is_paypal: this.paypalGaVault?.valid ? '1' : '0',
          is_vaulting_account: !!this.ppgvAccountInfo?.signUpEmail ? '1' : '0',
          is_vaulting_status: this.paypalGaVault?.valid ? '1' : '0'
        }
      }
    },
    checkSelect(payment){
      let isSelect = !!(
        !this.pageStatus.cachePaymentsCode &&
        payment.checked &&
        payment.enabled == 1
      ) ||
        (this.pageStatus.cachePaymentsCode &&
        this.pageStatus.cachePaymentsCode.code == payment.code &&
        payment.enabled == 1
        ) || false
      if(!!isSelect) {
        this.currentPaymentCode = payment.code
      }
      return !!isSelect 
    },
    selectedAfterpay(payment){
      return this.checkSelect(payment) && payment.code === 'afterpay-card'
    },
    selectedPaypal(payment){
      return this.checkSelect(payment) && payment.code === 'PayPal-GApaypal'
    },
    frontListItemsValue(payment) {
      return this.installmentInfo?.front_list_items?.[payment?.code] || {}
    },
    showInstallmentInfo(payment) {
      const flag = payment.enabled == 1 && this.installmentInfo?.front_list_items?.[payment.code]?.is_display == 1
      if (payment?.code !== 'PayPal-bnpl') {
        return flag
      }
      return !(flag && this.checkSelect(payment)) 
    },

    // 查询权益优惠券列表
    async getCouponByCodes (codes) {
      const res = await schttp({
        method: 'POST',
        url: '/api/prime/postCouponByCouponCodes/query',
        data: { codes }
      })
      return res
    },

    // 获取优惠券数据
    async getData () {
      const codes = (this.orderInfo?.coupon_list || [])?.map(_ => _.coupon)
      if (codes.length == 0) {
        this.couponList = []
        return
      }
      const res = await this.getCouponByCodes(codes)
      this.couponList = res?.info?.list || []
    },

    isDisabledPay(item) {
      if (!this.isHadBinCoupons) {
        return false
      }
      return !(this.isHadBinCoupons && ['routepay-card', 'routepay-cardinstallment'].includes(item?.code))
    },
    codTipExpose() {
      daEventCenter.triggerNotice({
        daId: '2-31-10-3',
      })
    },
    clickCodTips() {
      daEventCenter.triggerNotice({
        daId: '2-31-10-4',
      })
    },
    handleToggleOneTimePay({ paymentCode, selected }) {
      this.$emit('confirm-signup-one-time-pay', {
        paymentCode,
        selected
      })
    },
    showOneTimeSignArrow(payment) {
      if (payment.code == 'PayPal-GApaypal') {
        return this.paypalGaVault &&
          this.paypalGaVault.valid &&
            payment.enabled == 1 &&
            this.paymentDisplay(payment.code) &&
            this.needPayInlinePaymentMethods &&
            this.needPayInlinePaymentMethods.indexOf(payment.code) > -1 &&
            payment.code == 'PayPal-GApaypal' &&
            this.paypalGaVault.visibleArrow
      } else {
        return payment.supportOneTimePay &&
            payment.enabled == 1 &&
            this.paymentDisplay(payment.code)
      }
    },

    isNowHadBinCoupons(payment) {
      return ['routepay-card', 'routepay-cardinstallment'].includes(payment.code) && this.isHadBinCoupons
    },
  }
}
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.chooce-payment-flexbetween {
  .flexbox();
  .space-between();
}
.chooce-payment-btn-black {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  box-sizing: border-box;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214s
  background: @color_brand;
  .border-dpr(border, 2px, transparent);
  padding: 0 0.4rem;
  color: #fff;
  white-space: nowrap;
  user-select: none;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.2s, border-color, 0.2s, color 0.2s;
  height: 1.16rem;
  line-height: 1.16rem;
  .font-dpr(32px);

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    background: fade(@color_brand, 60%);
    color: rgba(255, 255, 255, 0.8);
    cursor: not-allowed;
  }

  a& {
    text-decoration: none;
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}
.chooce-payment-btn-block {
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
}
.venmo-btn {
  background-color: #3d95ce;
  border-radius: 6/75rem;
  .venmo-btn-img {
    height: 26/75rem;
  }
}

.discount-tips-item {
  display: inline-block;
  color: @sui_color_promo_dark;
  padding: 4/75rem 8/75rem;
  margin-bottom: 6/75rem;
  .font-dpr(24px);
  line-height: 1;
  .border-dpr(border, 1px, @sui_color_prompt_unusual);
  background-color: #fff6f3;
  .icon {
    .font-dpr(24px) !important; /* stylelint-disable-line */
    line-height: 1;
    padding-left: 4/75rem;
    color: @sui_color_gray_light1;
  }
}

.order-payment-list .mcheo-card {
  > ul.payments-list {
    background: #fff;
    position: relative;
    .padding-l(24/75rem);
    > li {
      position: relative;
      padding: 24/75rem 0;
      .padding-r(24/75rem);
      // min-height: 1.25rem;
      .align-center();
      .border-dpr(border-bottom,2px,#e5e5e5);
      &:last-child {
        border: none;
      }
      &.disabled {
        color: #999;
      }
      .payment-discount-zero {
        .margin-l(6px);
      }
      .payment-discount-tip {
        color: @sui_color_highlight;
        .font-dpr(24px);
        margin-top: 0;
      }
      &.more-padding-right {
        .padding-r(60/75rem);
      }
    }

    #paypal-bnpl-message {
      .padding-l(0.9rem);
      margin-top: 12/75rem;
    }
  }

  .icon-icon-test {
    color: #999;
    .font-dpr(32px);
    float: right;
    cursor: default;
    text-decoration: none;
    font-size: 24px !important; /* stylelint-disable-line declaration-no-important */
    margin-right: -1px;
  }
  .card-left {
    height: 48/75rem;
    width: 100%;
    .flexbox();
    .align-center();
    &.flex-auto {
      width: auto;
      flex: 1;
    }
    .icon-pro-help {
      font-size: 16px;
      color: #999;
      vertical-align: middle;
    }
    .payment-src {
      .margin-r(0.2rem);
      width: 0.8rem;
      height: 0.52rem;
    }
    .payment-disabled {
      opacity: 0.3;
    }
    > span {
      display: block;
      font-size: 14px;
      > i {
        .margin-l(0.2rem);
      }
    }
    .off-tips,
    .cod-services-cost-tips {
      color: #e64545;
      .font-dpr(22px);
    }
    .cod-services-cost-tips {
      .padding-l(0.4rem);
      &.cod-tips-wid {
        width: 5.2rem;
      }
    }
    select {
      height: 0.7733333rem;
      line-height: 0.7733333rem;
      padding: 0 0.266667rem;
      min-width: 5rem;
      background: 0 0;
      .margin-l(0.266667rem);
    }
    .text-error {
      color: @sui_color_unusual;
      .font-dpr(22px);
    }
    .card-left-select{
      display: flex;
      align-items: center;
      width: 48/75rem;
      height: 48/75rem;
      .margin-r(16/75rem);
    }
  }
  .cod-tips-wid {
    .padding-l(0.2rem);
  }
  .paytm-upi-tips {
    color: #666;
    .font-dpr(24px);
    padding-left: 1rem;
    padding-bottom: 0.12rem;
    .input-item {
      position: relative;
      padding: 0.1rem 0 0 0;
      input::-webkit-input-placeholder {
        color: #ccc;
      }
      input {
        width: 100%;
        padding: 0 10px;
        height: 36px;
        line-height: 36px;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
      [class*='iconfont'] {
        font-size: 12px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        display: none;
      }
      .icon-warn-out {
        color: #d53333;
      }
      .icon-checked {
        color: #5ebd66;
      }
    }
    .error-tips {
      color: #ff411c;
      display: none;
    }
    &.error-input-item {
      input {
        border-color: #ff411c;
      }
      .error-tips {
        display: block;
      }
      [class*='iconfont'] {
        display: block;
      }
    }
    &.succ-input-item {
      [class*='iconfont'] {
        display: block;
      }
    }
  }
}

.order-bank-container {
  .txt-r();
  .selected-bank-area {
    .flexbox();
    align-items: center;
    .selected-bank-text {
      word-break: break-word;
      .margin-r(0.0533rem);
      color: @sui_color_gray_dark2;
      .font-dpr(24px);
      flex: 1;
    }
    .icon2 {
      .margin-r(0);
      .font-dpr(32px);
      color: @sui_color_gray_light1;
    }
    .txt-error {
      color: @sui_color_unusual;
    }
  }
}

.ppgv-type-drawer {
  .type-des {
    padding: 0.32rem;
    color: #666;
    background-color: #f6f6f6;
    text-align: center;
    .font-dpr(24px);
  }
  .type-radio {
    border-bottom: 1px solid #f6f6f6;
  }
  .type-item {
    width: 100%;
    height: 1.17333rem;
    .margin-l(0.32rem);
    border-bottom: 1px solid #d9d9d9;
    &:last-child {
      border-bottom: none;
    }
  }
  .confirm-btn {
    padding: 0.2rem 0.32rem;
  }
}

.mbway-phone-dialog {
  .tip {
    text-align: center;
    .font-dpr(28px);
    font-weight: bold;
    margin: 0 0.7333rem;
    margin-top: 0.32rem;
    margin-bottom: 0.42666rem;
  }
  .input-row {
    display: flex;
    margin: 0 0.32rem;
    justify-content: flex-start;
    border: 1px solid #ccc;

    &.focus-input {
      border: 1px solid #222;
      .input-code {
        .border-r(1px, #222);
      }
    }

    &.err-input {
      border: 1px solid #c44a01;
      .input-code {
        .border-r(1px, #C44A01);
      }
    }

    .S-input {
      margin-top: 0;
    }
    .input-code {
      width: 1.6rem;
      .border-r(1px, #ccc);
    }
    .input-phone {
      width: 100%;
    }

    .S-Field {
      &:after {
        display: none;
      }
    }

    .S-Field.focus {
      &:after {
        border-top: none;
      }
    }
    .S-Field__righticon .S-Field__clear {
      margin-top: 0;
      vertical-align: middle;
    }
  }
  .err-tip {
    color: #bf4123;
    margin: 0 0.32rem;
    margin-top: 0.10666rem;
    .txt-l();
  }
  .confirm-btn {
    margin: 0.48rem 0 0.32rem;
  }
}

.body-fixed__order {
  position: fixed;
  top: 0;
  left: 0;
}

.disabled-tip {
  color: #BF4123;
  font-size: 12px;
  font-family: SF Pro;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 17px;
  word-wrap: break-word;
  padding-left: 32*2/75rem;
  display: inline-block;
  height: 30*2/75rem;
  line-height: 30*2/75rem;
}
</style>
